import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-c5);

  .content {
    margin-left: 50px;
    text-align: center;

    h1 {
      font-size: 12rem;
      color: var(--gray-c2);
    }

    p {
      font-size: 1.4rem;
      color: var(--gray-c2);
      margin-bottom: 2rem;
      margin-top: -2rem;
    }
  }
`;
