import { useState, useContext } from "react";
import { db } from "../services/firebase";
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import { AuthContext } from "../contexts/Auth/AuthContext";
import { createUpdateDeleteUsersApp } from "../services/apiPortalProdutor";
import { getUserId } from "../utils/functions";
import { sessionStorageDecrypt } from "../utils/crypto";

export const useAtualizarProdutores = (produtorSelected) => {
  const cnpjArmazem = sessionStorageDecrypt("cnpjArmazem");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [inativo, setInativo] = useState(false);
  const [email, setEmail] = useState("");
  const [observacao, setObservacao] = useState("");
  const [telefone, setTelefone] = useState("");

  const { dono, userAuth, password } = useContext(AuthContext);

  const FirebaseStore = () => {
    setError(false);
    setLoading(true);
    onSnapshot(
      collection(db, "produtores"),
      (snap) =>
        setData(
          snap.docs.reduce((acc, item) => {
            if (item.data().armazens.includes(cnpjArmazem)) {
              acc.push({
                ...item.data(),
              });
            }
            return acc;
          }, [])
        ),
      (error) => setError(error)
    );
    setLoading(false);
  };

  const handleStatusProdutor = async (cpf, nome) => {
    setLoading(true);
    setError("");
    try {
      const isUserExist = await createUpdateDeleteUsersApp(
        dono,
        userAuth,
        password,
        "C",
        "0",
        cpf,
        "",
        "",
        "",
        "",
        ""
      );

      const userIdResult = getUserId(isUserExist);

      await createUpdateDeleteUsersApp(
        dono,
        userAuth,
        password,
        "A",
        userIdResult,
        cpf,
        nome,
        "",
        "",
        "",
        "N"
      );

      const docRef = doc(db, "produtores", cpf);
      await updateDoc(docRef, {
        armazens: arrayRemove(cnpjArmazem),
      });
      setInativo(true);
    } catch (error) {
      setError("Erro inesperado! Por favor tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  };

  const handleEmailProdutor = async (e) => {
    e.preventDefault();
    if (email.length === 0) {
      setError("Digite um e-mail");
      return;
    }
    setError(false);
    setLoading(true);

    try {
      const isUserExist = await createUpdateDeleteUsersApp(
        dono,
        userAuth,
        password,
        "C",
        "0",
        produtorSelected.cpf,
        "",
        "",
        "",
        "",
        ""
      );

      const userIdResult = getUserId(isUserExist);

      await createUpdateDeleteUsersApp(
        dono,
        userAuth,
        password,
        "A",
        userIdResult,
        produtorSelected.cpf,
        produtorSelected.nome,
        email.toLowerCase(),
        telefone,
        observacao,
        "S"
      );
      const docRef = doc(db, "produtores", produtorSelected.cpf);
      await updateDoc(docRef, {
        email: email.toLowerCase(),
      });
      setInativo(true);
    } catch (error) {
      setError(
        "Erro ao tentar atualizar e-mail! Por favor tente novamente mais tarde."
      );
    } finally {
      setLoading(false);
    }

    setLoading(false);
    setEmail("");
  };

  return {
    data,
    error,
    loading,
    inativo,
    email,
    setInativo,
    setEmail,
    FirebaseStore,
    handleStatusProdutor,
    handleEmailProdutor,
    observacao,
    setObservacao,
    telefone,
    setTelefone,
  };
};
