import {
  dayToday,
  lastSevenDay,
  dateToEn,
  compararDatas,
  dayTodayFormated,
  lastSevenDayFormated,
} from '../../../../../utils/dates'
import moment from 'moment/moment'

const totalPeriodo = {}

const sacas = {}

const pesagens = {}

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

const colors = [
  '#06b6d4',
  '#047857',
  '#be123c',
  '#003d73',
  '#c05640',
  '#edd170',
  `rgb(${getRandomInt(254)},${getRandomInt(254)},${getRandomInt(254)})`,
  `rgb(${getRandomInt(254)},${getRandomInt(254)},${getRandomInt(254)})`,
  `rgb(${getRandomInt(254)},${getRandomInt(254)},${getRandomInt(254)})`,
  `rgb(${getRandomInt(254)},${getRandomInt(254)},${getRandomInt(254)})`,
]

function graphics(dados, controle, totalSacas, qtdPesagens) {
  if (dados) {
    const datasets = controle.map((controls, index) => ({
      type: 'bar',
      label: controls,
      backgroundColor: `${colors[index]}`,
      data: dados[controls],
      totalSacas: totalSacas && totalSacas[controls],
      qtdPesagens: qtdPesagens && qtdPesagens[controls],
    }))
    // fazer um map no data(dados) usando o index para percorrer cada elemento e dividir pela quantidade do controls
    return datasets
  }
}

function generateLabel(
  dscControls,
  apiArray,
  dateIni,
  dateFim,
  typeDate,
  optionDate,
) {
  let dateItem
  const filterLabel = []
  let label = []
  dscControls.map((controls) => {
    if (apiArray[controls] !== undefined) {
      apiArray[controls].map((item) => {
        dateItem = new Date(item.DAT_PESO_INI)
        if (
          moment(item.DAT_PESO_INI).isSameOrAfter(dateIni) &&
          moment(item.DAT_PESO_INI).isSameOrBefore(dateFim, typeDate)
        ) {
          dateItem = dateItem.toLocaleString('default', optionDate)
          if (!filterLabel.includes(dateItem)) {
            filterLabel.push(dateItem)
          }
        }
        return null
      })
    }
    return null
  })
  label = filterLabel.sort(compararDatas)

  return label
}

function convertDate(dataAnaliseInicio, dataAnaliseFinal) {
  let date = {}
  if (dataAnaliseInicio === undefined || dataAnaliseFinal === undefined) {
    const dayFinaly = moment(dateToEn(dayTodayFormated))
    const dayOne = moment(dateToEn(lastSevenDayFormated))
    date = { dateBaseIni: dayOne, dateBaseFim: dayFinaly }
  } else {
    const dayFinaly = moment(dateToEn(dataAnaliseFinal))
    const dayOne = moment(dateToEn(dataAnaliseInicio))
    date = { dateBaseIni: dayOne, dateBaseFim: dayFinaly }
  }
  return date
}

function datasetGraphicsPesagemRetorno(retornada, naoRetornada) {
  const datasets = [
    {
      type: 'bar',
      label: 'Retornadas',
      backgroundColor: '#047857',
      data: retornada,
    },
    {
      type: 'bar',
      label: 'Não Retornadas',
      backgroundColor: '#be123c',
      data: naoRetornada,
    },
  ]
  return datasets
}

const PesagemResumoGeral = ({
  dataAnaliseInicio,
  dataAnaliseFinal,
  apiArray,
  dscControls,
}) => {
  const { dateBaseIni, dateBaseFim } = convertDate(
    dataAnaliseInicio,
    dataAnaliseFinal,
  )
  function calcDataPesagemDia(
    dscControls,
    labels,
    apiArray,
    dateBaseIni,
    dateBaseFim,
    total,
    sacas,
    pesagens,
    typeDate,
    optionDate,
  ) {
    let dateItem
    const totalGeral = {}
    const totalSacas = {}
    const qtdPesagens = {}
    dscControls.map((controls) => {
      sacas[controls] = 0
      pesagens[controls] = 0
      total[controls] = 0
      totalSacas[controls] = []
      qtdPesagens[controls] = []
      totalGeral[controls] = []

      labels.map((label) => {
        apiArray[controls].map((item) => {
          dateItem = new Date(item.DAT_PESO_INI)
          if (
            moment(item.DAT_PESO_INI).isSameOrAfter(dateBaseIni) &&
            moment(item.DAT_PESO_INI).isSameOrBefore(dateBaseFim, typeDate)
          ) {
            dateItem = dateItem.toLocaleString('default', optionDate)

            if (item.DSC_CONTROLE === controls) {
              if (label === dateItem) {
                total[controls] += item.NUM_PESO_LIQUIDO
                sacas[controls] += item.NUM_SACAS
                pesagens[controls] += item.NUM_PESAGENS
              }
            }
          }
          return null
        })
        totalSacas[controls].push(sacas[controls])
        qtdPesagens[controls].push(pesagens[controls])
        totalGeral[controls].push(total[controls])
        total[controls] = 0
        sacas[controls] = 0
        pesagens[controls] = 0

        return null
      })
      return null
    })

    return { totalGeral, totalSacas, qtdPesagens }
  }
  let totalGeral = {}
  let totalSacas = {}
  let qtdPesagens = {}
  let labels
  const optionsDayDate = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }

  if (apiArray) {
    labels = generateLabel(
      dscControls,
      apiArray,
      dateBaseIni,
      dateBaseFim,
      'date',
      optionsDayDate,
    )

    if (labels.length > 0) {
      const result = calcDataPesagemDia(
        dscControls,
        labels,
        apiArray,
        dateBaseIni,
        dateBaseFim,
        totalPeriodo,
        sacas,
        pesagens,
        'date',
        optionsDayDate,
      )
      totalGeral = result.totalGeral
      totalSacas = result.totalSacas
      qtdPesagens = result.qtdPesagens
    }
  }

  const datasets = graphics(totalGeral, dscControls, totalSacas, qtdPesagens)

  return { labels, datasets }
}

const PesagemRodoviariaPorRetorno = ({
  api,
  dataAnaliseInicio,
  dataAnaliseFinal,
}) => {
  const total = {
    retornada: [],
    naoRetornada: [],
  }

  let labels = []
  let retornadaBigBag = 0
  let semRetornoBigBag = 0
  let retornadaSacaria = 0
  let semRetornoSacaria = 0
  let retornadaGranel = 0
  let semRetornoGranel = 0
  let retornadaOutros = 0
  let semRetornoOutros = 0

  function getDataForDate(dateBaseIni, dateBaseFim = dayToday) {
    api.map((item) => {
      // esse if faz o controle das datas digitadas pelo usuário, para trazer somente
      // => os dados da api que faz referência a essas datas
      if (
        moment(item.DAT_PESO_INI).isSameOrAfter(dateBaseIni) &&
        moment(item.DAT_PESO_INI).isSameOrBefore(dateBaseFim, 'date')
      ) {
        if (item.NUM_BIGBAG === 1) {
          if (item.NUM_RETORNA_EMB === 1) {
            retornadaBigBag += 1
          } else if (item.NUM_RETORNA_EMB === 0) {
            semRetornoBigBag += 1
          }
        }
        if (item.NUM_SACARIA === 1) {
          if (item.NUM_RETORNA_EMB === 1) {
            retornadaSacaria += 1
          } else if (item.NUM_RETORNA_EMB === 0) {
            semRetornoSacaria += 1
          }
        }
        if (item.NUM_GRANEL === 1) {
          if (item.NUM_RETORNA_EMB === 1) {
            retornadaGranel += 1
          } else if (item.NUM_RETORNA_EMB === 0) {
            semRetornoGranel += 1
          }
        }
        if (item.NUM_OUTRAS === 1) {
          if (item.NUM_RETORNA_EMB === 1) {
            retornadaOutros += 1
          } else if (item.NUM_RETORNA_EMB === 0) {
            semRetornoOutros += 1
          }
        }
      }
      return null
    })
    if (
      !retornadaBigBag &&
      !semRetornoBigBag &&
      !retornadaSacaria &&
      !semRetornoSacaria &&
      !retornadaGranel &&
      !semRetornoGranel &&
      !retornadaOutros &&
      !semRetornoOutros
    ) {
      return ''
    } else {
      total.retornada.push(
        retornadaBigBag,
        retornadaSacaria,
        retornadaGranel,
        retornadaOutros,
      )
      total.naoRetornada.push(
        semRetornoBigBag,
        semRetornoSacaria,
        semRetornoGranel,
        semRetornoOutros,
      )
    }
  }
  labels = ['Big Bag', 'Sacaria', 'Granel', 'Outros']

  // funcao para converter as datas que estão vindo do calendar
  if (dataAnaliseInicio === undefined || dataAnaliseFinal === undefined) {
    getDataForDate(lastSevenDay._d)
  } else {
    const dayFinaly = moment(dateToEn(dataAnaliseFinal))
    const dayOne = moment(dateToEn(dataAnaliseInicio))
    getDataForDate(dayOne, dayFinaly)
  }

  // opcoes de estilo do gráfico
  const { retornada, naoRetornada } = total
  const datasets = datasetGraphicsPesagemRetorno(retornada, naoRetornada)

  return { labels, datasets }
}

const PesagemRodoviariaPorEmbalagem = ({
  dataAnaliseInicio,
  dataAnaliseFinal,
  apiArray,
  dscControls,
}) => {
  const { dateBaseIni, dateBaseFim } = convertDate(
    dataAnaliseInicio,
    dataAnaliseFinal,
  )
  let totalBigBag = 0
  let totalSacaria = 0
  let totalGranel = 0
  let totalOutros = 0
  const totalGeral = {}

  dscControls.map((controls) => {
    totalGeral[controls] = []

    apiArray[controls].map((item) => {
      if (
        moment(item.DAT_PESO_INI).isSameOrAfter(dateBaseIni) &&
        moment(item.DAT_PESO_INI).isSameOrBefore(dateBaseFim, 'date')
      ) {
        if (item.NUM_BIGBAG === 1) {
          if (item.DSC_CONTROLE === controls) {
            totalBigBag += 1
          }
        }
        if (item.NUM_SACARIA === 1) {
          if (item.DSC_CONTROLE === controls) {
            totalSacaria += 1
          }
        }
        if (item.NUM_GRANEL === 1) {
          if (item.DSC_CONTROLE === controls) {
            totalGranel += 1
          }
        }
        if (item.NUM_OUTRAS === 1) {
          if (item.DSC_CONTROLE === controls) {
            totalOutros += 1
          }
        }
      }
      return null
    })
    totalGeral[controls].push(
      totalBigBag,
      totalSacaria,
      totalGranel,
      totalOutros,
    )
    totalBigBag = 0
    totalSacaria = 0
    totalGranel = 0
    totalOutros = 0
    return null
  })

  const labels = ['Big Bag', 'Sacaria', 'Granel', 'Outros']

  const datasets = graphics(totalGeral, dscControls)

  return { labels, datasets }
}

const PesagemRodoviariaPorCaminhao = ({
  dataAnaliseInicio,
  dataAnaliseFinal,
  apiArray,
  dscControls,
}) => {
  const { dateBaseIni, dateBaseFim } = convertDate(
    dataAnaliseInicio,
    dataAnaliseFinal,
  )
  let totalCacamba = 0
  let totalGraneleiro = 0
  let totalOutros = 0
  const totalGeral = {}

  dscControls.map((controls) => {
    totalGeral[controls] = []

    apiArray[controls].map((item) => {
      if (
        moment(item.DAT_PESO_INI).isSameOrAfter(dateBaseIni) &&
        moment(item.DAT_PESO_INI).isSameOrBefore(dateBaseFim, 'date')
      ) {
        if (item.NUM_CACAMBA_CAM === 1) {
          if (item.DSC_CONTROLE === controls) {
            totalCacamba += 1
          }
        }
        if (item.NUM_GRANELEIRO_CAM === 1) {
          if (item.DSC_CONTROLE === controls) {
            totalGraneleiro += 1
          }
        }
        if (item.NUM_OUTROS_CAM === 1) {
          if (item.DSC_CONTROLE === controls) {
            totalOutros += 1
          }
        }
      }
      return null
    })
    totalGeral[controls].push(totalCacamba, totalGraneleiro, totalOutros)
    totalCacamba = 0
    totalGraneleiro = 0
    totalOutros = 0
    return null
  })

  const labels = ['Caçamba', 'Graneleiro', 'Outros']

  const datasets = graphics(totalGeral, dscControls)

  return { labels, datasets }
}

const pesagemRodoviariaCards = ({
  api,
  dataAnaliseInicio,
  dataAnaliseFinal,
}) => {
  let qtdSacasCarga = 0
  let qtdSacasDescarga = 0
  let qtdSacasOutros = 0
  let qtdPesagemCarga = 0
  let qtdPesagemDescarga = 0
  let qtdPesagemOutros = 0
  let percentCarga = 0
  let percentDescarga = 0
  let percentOutros = 0
  let totalSacas = 0

  const { dateBaseIni, dateBaseFim } = convertDate(
    dataAnaliseInicio,
    dataAnaliseFinal,
  )

  if (api) {
    api.map((item) => {
      const string = item.DSC_CONTROLE.toUpperCase().split(' ')
      if (
        moment(item.DAT_PESO_INI).isSameOrAfter(dateBaseIni) &&
        moment(item.DAT_PESO_INI).isSameOrBefore(dateBaseFim, 'date')
      ) {
        if (string.includes('CARGA')) {
          qtdSacasCarga += Number(item.NUM_SACAS)
          qtdPesagemCarga += 1
        } else if (string.includes('DESCARGA')) {
          qtdSacasDescarga += Number(item.NUM_SACAS)
          qtdPesagemDescarga += 1
        } else {
          qtdSacasOutros += Number(item.NUM_SACAS)
          qtdPesagemOutros += 1
        }
      }
      return null
    })
  }

  totalSacas = qtdSacasCarga + qtdSacasDescarga + qtdSacasOutros
  percentCarga = totalSacas
    ? ((qtdSacasCarga * 100) / totalSacas).toFixed(2)
    : 0
  percentDescarga = totalSacas
    ? ((qtdSacasDescarga * 100) / totalSacas).toFixed(2)
    : 0
  percentOutros = totalSacas
    ? ((qtdSacasOutros * 100) / totalSacas).toFixed(2)
    : 0

  return {
    totalSacas,
    qtdSacasCarga,
    qtdSacasDescarga,
    qtdSacasOutros,
    qtdPesagemCarga,
    qtdPesagemDescarga,
    qtdPesagemOutros,
    percentCarga,
    percentDescarga,
    percentOutros,
  }
}

export const DataPesagemRodoviaria = {
  PesagemResumoGeral,
  PesagemRodoviariaPorRetorno,
  PesagemRodoviariaPorEmbalagem,
  PesagemRodoviariaPorCaminhao,
  pesagemRodoviariaCards,
}
