import axios from 'axios'
import { sessionStorageDecrypt } from './crypto'

function apiAxios(ip) {
  return axios.create({
    baseURL: `${ip}/wsemsoft`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
  })
}

export function apiDecryptURL() {
  const ip = sessionStorageDecrypt('urlApi')
  return apiAxios(ip)
}
