export function convertDate(date) {
  let firstDate;
  let lastDate;
  if (date) {
    if (date[0]) {
      firstDate = new Date(date[0]).toLocaleDateString();
    }
    if (date[1]) {
      lastDate = new Date(date[1]).toLocaleDateString();
    }
  }

  const result = {
    firstDate,
    lastDate,
  };
  return result;
}
