import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .search {
    display: flex;
    justify-content: end;
    font-size: 1.56rem;
  }

  .totalSoma {
    background: var(--blue-a18);
    color: #fff;
    padding: 8px 16px;
    border-radius: 8px;
  }

  .buttonsPrintCSV {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
  }

  .button {
    border: none;
    padding: 5px 0px;
    margin: 0;
  }

  input {
    position: relative;
    float: right;
    border: none;
    font-size: 1rem;
    width: 17.82rem;
    height: 2.5rem;
    padding: 0.375rem;
    appearance: none;
    outline: none;
    border-radius: 0.25rem;
    background-color: var(--blue-a4);
    transition: all ease 0.3s;
    :focus {
      background-color: var(--blue-a5);
    }
  }

  .icon_search {
    position: absolute;
    padding-right: 1.25rem;
    margin-top: 0.125rem;
  }

  .movimentacao {
    position: relative;
    float: left;
    font-size: 2rem;
    font-weight: 500;
    color: var(--blue-a18);
  }

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-wrap: wrap;
    .movimentacao {
      font-size: 1.7rem;
    }
  }
`
