import styled from "styled-components";

export const Container = styled.div`
  background: var(--blue-a3);
  grid-area: main;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  justify-content: center;

  .main_cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 15px 27px;
    max-width: 1300px;

    justify-items: center;
    gap: 1.1rem;

    .card_pagar {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      overflow: hidden;
      min-width: 325px;

      .button_pagar {
        background: var(--red-output);

        :hover {
          background-color: #4e070e;
        }
      }
    }

    .card_receber {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      overflow: hidden;
      min-width: 325px;

      .button_receber {
        background: var(--green-entry);

        :hover {
          background-color: #00370f;
        }
      }
    }

    .card_bancos {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      overflow: hidden;
      min-width: 325px;

      .button_bancos {
        background: var(--blue-repack);

        :hover {
          background-color: #00080c;
        }
      }
    }
  }

  /* estilo da caixa do gráfico e da listResult*/
  .main_information {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);
    max-width: 100%;
    max-height: 100%;
    margin: 0px 27px;
    background-color: var(--blue-a3);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    .information_graphics {
      width: 100%;
    }

    /* estilo da caixa do listResult */
    .information_analises {
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  @media only screen and (max-width: 1330px) {
    .main_cards {
      gap: 0.5rem;
    }
  }

  @media only screen and (max-width: 1250px) {
    .main_cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 1000px) {
    .main_information {
      display: flex;
      justify-content: center;
    }
    .information_graphics {
      display: none;
    }
  }

  @media only screen and (max-width: 500px) {
    .main_cards {
      display: grid;
      grid-template-columns: 1fr;
    }
    .main_cards .card_pagar {
      min-width: 0px;
      width: 300px;
    }
    .main_cards .card_receber {
      min-width: 0px;
      width: 300px;
    }
    .main_cards .card_bancos {
      min-width: 0px;
      width: 300px;
    }
    .main_information {
      width: 350px;
      margin: 0px 0px;
    }
  }

  @media only screen and (max-width: 350px) {
    .main_information {
      width: 315px;
      margin: 0px;
    }
  }
`;
