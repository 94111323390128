import React, { useContext, useState } from "react";
import { Container, customStylesSelect } from "./style";
import SelectGlobal from "../../../components/Layouts/Select/Select";

import { OptionsSelect } from "./DataOptions/OptionsSelect";
import CalendarPicker from "../../../components/DataRangePicker/Date";
import { TablesRelatorioFin } from "../../../components/Tables/TablesRelatorioFin/TablesRelatorioFin";
import { getContasAberto, getContasPagas } from "../../../services/apiFinanceiro";
import { AuthContext } from "../../../contexts/Auth/AuthContext";
import Warning from "../../../components/Layouts/Warning/Warning";
import Loading from "../../../components/Layouts/Loading/Loading";
import moment from "moment";

const RelatoriosFin = () => {
  const { dono, userAuth, password, codEmpresa } = useContext(AuthContext);

  const [dataAnaliseInicio, setDataAnaliseInicio] = useState();
  const [dataAnaliseFinal, setDataAnaliseFinal] = useState();

  const [api, setApi] = useState([]);
  const [relatorio, setRelatorio] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function handleDatePicker(date, type) {
    if (!date || date.length < 2) return;

    const [startDate, endDate] = date;

    const firstDate = startDate ? moment(startDate).format("DD/MM/YYYY") : null;
    const lastDate = endDate ? moment(endDate).format("DD/MM/YYYY") : null;

    setDataAnaliseInicio(firstDate);
    setDataAnaliseFinal(lastDate);
    // estrutura para setar as datas conforme o parametro type
  }

  function buttonReturnDate() {
    if (!dataAnaliseFinal || !dataAnaliseFinal) {
      alert("Digite uma data válida");
    } else {
      returnApi(dataAnaliseInicio, dataAnaliseFinal);
      setApi([]);
    }
  }

  function handleSelect(selectedOptions) {
    setApi([]);
    setError(false);
    setLoading(false);
    setRelatorio(selectedOptions.value);
  }

  async function returnApi(dataIni, dataFin) {
    setLoading(true);
    setError(false);
    if (relatorio === "Contas a Receber" || relatorio === "Contas a Pagar") {
      const res = await getContasAberto(dono, userAuth, password, codEmpresa, dataIni, dataFin);
      const { contaPagar, contaReceber } = res;
      if (relatorio === "Contas a Receber") {
        if (contaReceber.length > 0) {
          setApi(contaReceber);
        } else {
          setError(true);
        }
      } else {
        if (contaPagar.length > 0) {
          setApi(contaPagar);
        } else {
          setError(true);
        }
      }
    } else if (relatorio === "Contas Recebidas" || relatorio === "Contas Pagas") {
      const res = await getContasPagas(dono, userAuth, password, codEmpresa, dataIni, dataFin);
      const { contaPagas, contaRecebidas } = res;
      if (relatorio === "Contas Recebidas") {
        if (contaRecebidas.length > 0) {
          setApi(contaRecebidas);
        } else {
          setError(true);
        }
      } else {
        if (contaPagas.length > 0) {
          setApi(contaPagas);
        } else {
          setError(true);
        }
      }
    }

    setLoading(false);
  }
  return (
    <>
      <Container>
        <SelectGlobal
          styles={customStylesSelect}
          options={OptionsSelect[0].analise}
          onChange={handleSelect}
        />
        {relatorio && (
          <>
            <div className="dateButtonRelatorioFin">
              <CalendarPicker handleDatePicker={handleDatePicker} type={"dataAnalise"} />
              <button onClick={buttonReturnDate} className="buttonPesquisar">
                Pesquisar
              </button>
            </div>
            {api.length > 0 && (
              <div className="tableRelatorioFin">
                <TablesRelatorioFin dataTable={api} infoRelatorio={relatorio} />
              </div>
            )}
            {error && (
              <Warning label={"Sem dados no período digitado!"} className={"warningOrLoading"} />
            )}
            {loading && (
              <div className="warningOrLoading">
                <Loading />
              </div>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default RelatoriosFin;
