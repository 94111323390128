// ANCHOR Cabeçalho da tabela

export const COLUMNS = [
  {
    Header: 'TAG',
    accessor: 'N_ID',
  },
  {
    Header: 'Lote',
    accessor: 'S_LOTE',
  },
  {
    Header: 'Sacas',
    accessor: 'N_SACAS',
  },
  {
    Header: 'Peso',
    accessor: 'N_PESO',
    Cell: ({ cell: { value } }) => value.toFixed(2),
  },
  {
    Header: 'Data',
    accessor: 'D_ATUALIZACAO',
  },

  {
    Header: 'Operação',
    accessor: 'S_ROTINA_OPERACAO',
  },
  {
    Header: 'Usuário',
    accessor: 'NOM_USUARIO',
  },
]
