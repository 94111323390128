import { useState, useContext } from "react";
import * as S from "./style";
import { phoneMask, phoneClear } from "../../../../utils/regex";
import { sessionStorageDecrypt } from "../../../../utils/crypto";
import { getUserId } from "../../../../utils/functions";
import { createUpdateDeleteUsersApp } from "../../../../services/apiPortalProdutor";
import { VinculaContext } from "../../../../contexts/VincularProdutores/VinculaContext";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";
import { useRegisterPortal } from "../../../../hooks/useRegisterPortal";
import HeaderModalPortal from "../../../../components/Modal/ModalPortal/HeaderModalPortal";
import Loading from "../../../../components/Layouts/Loading/Loading";

const FormRegisterProdutor = ({ handleRequestCloseFunc, dataProdutor }) => {
  const idProdutor = sessionStorageDecrypt("idProdutor");
  const cnpjArmazem = sessionStorageDecrypt("cnpjArmazem");

  const { dono, userAuth, password } = useContext(AuthContext);
  const { setStatusVinculaModal, setUserId } = useContext(VinculaContext);

  const { registerProdutor, error: registerErr } = useRegisterPortal();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordProdutor, setPasswordProdutor] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState(dataProdutor?.nome);
  const [telefone, setTelefone] = useState("");
  const [observacao, setObservacao] = useState("");

  const [erro, setErro] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErro(false);
    setLoading(true);
    setMessage(false);
    const data = await registerProdutor(
      idProdutor,
      passwordProdutor,
      confirmPassword,
      name,
      email,
      cnpjArmazem
    );
    if (!data) setErro(true);
    if (data) {
      try {
        // Verifica se o usuário já está cadastrado no banco local
        const isUserExist = await createUpdateDeleteUsersApp(
          dono,
          userAuth,
          password,
          "C",
          "0",
          idProdutor,
          "",
          "",
          "",
          "",
          "S"
        );

        const userIdResult = parseInt(getUserId(isUserExist));

        // Snão houver registro, castrar e se houver pegar apenas o id
        if (userIdResult === 0) {
          const resId = await createUpdateDeleteUsersApp(
            dono,
            userAuth,
            password,
            "I",
            "0",
            idProdutor,
            name.toUpperCase(),
            email,
            phoneClear(telefone),
            observacao,
            "S"
          );
          let valorAposPipe = getUserId(resId);
          setUserId({ id: valorAposPipe, name });
        } else {
          const resId = await createUpdateDeleteUsersApp(
            dono,
            userAuth,
            password,
            "A",
            userIdResult,
            idProdutor,
            name.toUpperCase(),
            email.toLowerCase(),
            phoneClear(telefone),
            observacao,
            "S"
          );
          let valorAposPipe = getUserId(resId);
          setUserId({ id: valorAposPipe, name });
        }
      } catch (error) {
        console.log(erro);
        setErro(true);
      }
      setMessage(true);
    }

    setLoading(false);
  };

  const handleVinculaProdutor = () => {
    handleRequestCloseFunc();
    setStatusVinculaModal(true);
  };

  return (
    <S.Wrapper>
      <HeaderModalPortal
        handleRequestCloseFunc={handleRequestCloseFunc}
        title="Formulário de Cadastro"
      />
      <div className="content">
        {!loading && !message && (
          <S.Form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="username">Nome</label>
              <S.Input
                autoFocus
                type="text"
                id="username"
                placeholder="Digite o nome completo"
                $invalid={erro && registerErr.includes("nome")}
                value={name || ""}
                onChange={(e) => setName(e.target.value)}
              />
              {erro && registerErr.includes("nome") && (
                <small>{registerErr}</small>
              )}
            </div>

            <div id="multiFields">
              <div>
                <label htmlFor="pass_produtor">Senha</label>
                <S.Input
                  type="password"
                  id="pass_produtor"
                  placeholder="Digite uma senha de 6 digitos"
                  $invalid={erro && registerErr.includes("senha")}
                  value={passwordProdutor}
                  onChange={(e) => setPasswordProdutor(e.target.value)}
                />
                {erro && registerErr.includes("senha") && (
                  <small>{registerErr}</small>
                )}
              </div>

              <div>
                <label htmlFor="confirm_pass_produtor">Confirmar senha</label>
                <S.Input
                  type="password"
                  id="confirm_pass_produtor"
                  placeholder="Confirme sua senha"
                  $invalid={erro && registerErr.includes("iguais")}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {erro && registerErr.includes("iguais") && (
                  <small>{registerErr}</small>
                )}
              </div>
            </div>
            <div>
              <label htmlFor="email_produtor">E-mail</label>
              <S.Input
                type="email"
                id="email_produtor"
                placeholder="Digite o e-mail do produtor"
                value={email}
                $invalid={erro && registerErr.includes("e-mail")}
                onChange={(e) => setEmail(e.target.value)}
              />
              {erro && registerErr.includes("e-mail") && (
                <small>{registerErr}</small>
              )}
            </div>
            <div id="multiFields">
              <div>
                <label htmlFor="telefone_user">Telefone</label>
                <S.Input
                  type="text"
                  id="telefone_user"
                  placeholder="(xx) xxxxx-xxxx (Opcional)"
                  value={phoneMask(telefone)}
                  onChange={(e) => setTelefone(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="obs">Observação</label>
                <S.Input
                  type="text"
                  id="obs"
                  placeholder="Digite uma observação (Opcional)"
                  value={observacao}
                  onChange={(e) => setObservacao(e.target.value)}
                />
              </div>
            </div>
            <S.Button>Enviar</S.Button>
          </S.Form>
        )}
        {loading && <Loading />}
        {message && (
          <div className="success">
            <h1>Cadastro realizado com sucesso!</h1>
            <S.Button $success onClick={handleVinculaProdutor}>
              ok
            </S.Button>
          </div>
        )}
      </div>
    </S.Wrapper>
  );
};

export default FormRegisterProdutor;
