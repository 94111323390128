import React from "react";

const CargaDescarga = ({
  value,
  renderGraphic,
  datasetOneGraphic,
  loading,
  datasetRenderGraphics,
  responsive,
}) => {
  return (
    <>
      {value.label === "Sacas por Controle e Tipo de Caminhão" ? (
        <>
          {!loading && (
            <div className="chart-diario">
              <div className="diario">
                {renderGraphic(value, datasetOneGraphic, "410", "1200", true, "Sacas", responsive)}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {!loading && (
            <>
              <div className="chart-diario">
                <div className="diario">
                  {renderGraphic(
                    value,
                    datasetRenderGraphics.datasetGraphicDia,
                    "220",
                    "1250",
                    false,
                    "Diário",
                    responsive
                  )}
                </div>
              </div>
              <div className="charts-mensal-anual">
                <div className="chart-mes">
                  {renderGraphic(
                    value,
                    datasetRenderGraphics.datasetGraphicMes,
                    "185",
                    "625",
                    true,
                    "Mensal",
                    responsive
                  )}
                </div>
                <div className="chart-ano">
                  {renderGraphic(
                    value,
                    datasetRenderGraphics.datasetGraphicAno,
                    "185",
                    "625",
                    true,
                    "Anual",
                    responsive
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CargaDescarga;
