import { formatNumberBR, formatarValorReal } from "../functions";

export const ExtratoCafeFixo = [
  {
    accessorKey: "ANO_SAFRA",
    header: "Safra",
    showHeader: true,
  },
  {
    accessorKey: "NF_SERIE",
    header: "Nota Fiscal",
    showHeader: true,
  },
  {
    accessorKey: "COD_LOTE",
    header: "Lote",
    showHeader: true,
  },
  {
    accessorKey: "NUM_CONTROLE_EMPRESA",
    header: "CTR",
    showHeader: true,
  },
  {
    accessorKey: "VAL_SALDO_SACAS",
    header: "Sacas",
    showHeader: true,
    formatter: (value) => formatNumberBR(value),
  },
  {
    accessorKey: "VAL_SALDO_PESO",
    header: "Peso",
    showHeader: true,
    formatter: (value) => formatNumberBR(value),
  },
  {
    accessorKey: "VAL_PRECO_LOTE",
    header: "Custo",
    showHeader: true,
    formatter: (value) => formatarValorReal(value),
  },
  {
    accessorKey: "DATA_ENTRADA",
    header: "Entrada",
    showHeader: true,
  },
  {
    accessorKey: "NOM_BEBIDA",
    header: "Bebida",
    showHeader: true,
  },
  {
    accessorKey: "NOM_CERTIFICADO",
    header: "Certificação",
    showHeader: true,
  },
  {
    accessorKey: "NOM_EMBALAGEM",
    header: "Emb.",
    showHeader: true,
  },
  {
    accessorKey: "COD_CADASTRO",
    header: "Código",
    showHeader: true,
  },
];

export const ExtratoEstoque = [
  {
    accessorKey: "ANO_SAFRA",
    header: "Safra",
    showHeader: true,
  },
  {
    accessorKey: "NF_SERIE",
    header: "Nota Fiscal",
    showHeader: true,
  },
  {
    accessorKey: "COD_LOTE",
    header: "Lote",
    showHeader: true,
  },
  {
    accessorKey: "NUM_SACAS",
    header: "Sacas",
    showHeader: true,
    formatter: (value) => formatNumberBR(value),
  },
  {
    accessorKey: "NUM_PESO_FISICO",
    header: "Peso",
    showHeader: true,
    formatter: (value) => formatNumberBR(value),
  },
  {
    accessorKey: "DATA_ENTRADA",
    header: "Entrada",
    showHeader: true,
  },
  {
    accessorKey: "NOM_BEBIDA",
    header: "Bebida",
    showHeader: true,
  },
  {
    accessorKey: "NOM_CERTIFICADO",
    header: "Certificação",
    showHeader: true,
  },
  {
    accessorKey: "NUM_QUANT_BAGS",
    header: "Bags",
    showHeader: true,
  },
  {
    accessorKey: "COD_CADASTRO",
    header: "Código",
    showHeader: true,
  },
];

export const MovimEstoque = [
  {
    Header: "Lote",
    accessor: "COD_LOTE",
  },
  {
    Header: "Data",
    accessor: "DATA",
  },
  {
    Header: "Doc",
    accessor: "DOC",
  },
  {
    Header: "Entrada",
    columns: [
      {
        Header: "Sacas",
        accessor: "SACAS_ENTRADA",
      },
      {
        Header: "Peso",
        accessor: "PESO_ENTRADA",
      },
    ],
  },
  {
    Header: "Despejo",
    columns: [
      {
        Header: "Sacas",
        accessor: "SACAS_DESPEJO",
      },
      {
        Header: "Peso",
        accessor: "PESO_DESPEJO",
      },
    ],
  },
  {
    Header: "Resultado",
    columns: [
      {
        Header: "Sacas",
        accessor: "SACAS_RESULTADO",
      },
      {
        Header: "Peso",
        accessor: "PESO_RESULTADO",
      },
    ],
  },
  {
    Header: "Saída",
    columns: [
      {
        Header: "Sacas",
        accessor: "SACAS_SAIDA",
      },
      {
        Header: "Peso",
        accessor: "PESO_SAIDA",
      },
    ],
  },
  {
    Header: "Saldo",
    columns: [
      {
        Header: "Sacas",
        accessor: "SACAS_SALDO",
      },
      {
        Header: "Peso",
        accessor: "PESO_SALDO",
      },
    ],
  },
];
