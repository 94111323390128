import { convertBRL } from '../../../../utils/convertBRL'
import { limitCaracter } from '../../../../utils/style'

export const columnsRecebidas = [
  {
    Header: 'Cliente',
    accessor: 'NOM_CADASTRO',
    Cell: ({ cell: { value } }) => <p style={limitCaracter()}>{value}</p>,
  },
  {
    Header: 'Cód.',
    accessor: 'TIPO_CLIENTE',
    Cell: ({ cell: { value } }) => value,
  },
  {
    Header: 'Tipo',
    accessor: 'COD_TIPODOC',
    Cell: ({ cell: { value } }) => value,
  },
  {
    Header: 'Doc',
    accessor: 'NUM_DOC',
    Cell: ({ cell: { value } }) => value,
  },
  {
    Header: 'Parc',
    accessor: 'NUM_SEQ',
    Cell: ({ cell: { value } }) => (
      <div style={{ textAlign: 'center' }}>{value}</div>
    ),
  },
  {
    Header: 'Venc',
    accessor: 'DAT_VENC',
    Cell: ({ cell: { value } }) => new Date(value).toLocaleDateString(),
  },
  {
    Header: 'Vlr Doc',
    accessor: 'VAL_DOC',
    Cell: ({ cell: { value } }) => convertBRL(value),
  },
  {
    Header: 'Vlr Pago',
    accessor: 'VAL_PAGO',
    Cell: ({ cell: { value } }) => convertBRL(value),
  },
  {
    Header: 'Desc',
    accessor: 'VAL_DESCONTO',
    Cell: ({ cell: { value } }) => convertBRL(value),
  },
  {
    Header: 'Juros',
    accessor: 'VAL_JURO',
    Cell: ({ cell: { value } }) => convertBRL(value),
  },
  {
    Header: 'Aberto',
    accessor: 'VAL_ABERTO',
    Cell: ({ cell: { value } }) => convertBRL(value),
  },
]
