import { Container } from './style'

// Cards usados no Modal (Dashboard / Armazém)
// => Usado no modal dos cards (Entrada / Saída / Preparo / Reensaque)

export default function CardModal({
  titleCard,
  icon,
  value,
  className,
  children,
}) {
  return (
    <Container className={className}>
      <div className="title">
        <p>{titleCard}</p>
      </div>
      <div className="content">
        <img src={icon} alt="Imagem de uma saca de café" />
        <p>
          {value}
          {children}
        </p>
      </div>
    </Container>
  )
}
