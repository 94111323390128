import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import ptBR from "date-fns/locale/pt-BR";
import { Container } from "./styles";
import "react-datepicker/dist/react-datepicker.css";
import { IMaskInput } from "react-imask";

// DataRanger(Calendário) usado no módulo Armazém
// => Usado no Dashboard para renderizar os Cards, Table e Graphic(Modal)
// => Usado no Análises para renderizar os graphics, Cards e Table

export default function DatePickerCalendar({ handleDateChange }) {
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    return handleDateChange(selectedDate);
  });

  registerLocale("pt-BR", ptBR);

  return (
    <>
      <Container>
        <DatePicker
          selected={selectedDate}
          onChange={setSelectedDate}
          dateFormat="dd/MM/yyyy"
          placeholderText="dd/mm/aaaa"
          locale="pt-BR"
          className="custom-datepicker-input"
          customInput={
            <IMaskInput mask={Date} pattern="d{/}`m{/}`Y*" unmask={true} />
          }
        />
      </Container>
    </>
  );
}
