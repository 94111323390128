import ReactModal from 'react-modal'
import iconClosed from '../../../assets/Icons-Modal/icon-closed.svg'
import { TableDuplicatas } from '../../Tables/TablesFin/TableDuplicatas'
import { Container, Header } from './style'
import Warning from '../../Layouts/Warning/Warning'

// Modal usado no Dashboard / Financeiro
// => É o modal usado para renderizar as tables do Contas a Pagar, Contas a Receber e Bancos

export function responsiveModal(valueWidth, valueReturnTrue, valueReturnFalse) {
  if (window.innerWidth < valueWidth) {
    return valueReturnTrue
  }
  return valueReturnFalse
}

export default function Modal({
  statusModalActual,
  handleRequestCloseFunc,
  infoModal,
}) {
  return (
    <>
      <ReactModal
        isOpen={statusModalActual}
        onRequestClose={handleRequestCloseFunc}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
          content: {
            position: 'absolute',
            top: '5%',
            left: responsiveModal(650, '2%', '10%'),
            right: responsiveModal(650, '2%', '10%'),
            bottom: '5%',
            border: 'none',
            background: 'var(--blue-a1)',
            borderRadius: '8px',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: '0',
            maxWidth: '100%',
          },
        }}
        ariaHideApp={false}
      >
        <Header color={infoModal.color}>
          <h2>{infoModal.title}</h2>
          <button onClick={handleRequestCloseFunc}>
            <img src={iconClosed} alt="Imagem de fechar" />
          </button>
        </Header>
        <Container>
          {infoModal.infoApi && infoModal.infoApi.length > 0 ? (
            <div className="tableScreen">
              <TableDuplicatas saldo={infoModal} />
            </div>
          ) : (
            <Warning label="Sem dados para este período!" />
          )}
        </Container>
      </ReactModal>
    </>
  )
}
