import { useState } from "react";
import * as S from "./style";
import AtualizaStatus from "./AtualizaStatus/AtualizaStatus";
import AtualizaEmail from "./AtualizaEmail/AtualizaEmail";
import ModalPortal from "../../../components/Modal/ModalPortal/ModalPortal";

const AtualizarProdutores = ({ actions, children, ...props }) => {
  const [statusModal, setStatusModal] = useState(false);
  const [renderModal, setRenderModal] = useState("");

  // Função que altera o status do modal para True ou  False
  const handleShowModal = () => {
    setStatusModal(!statusModal);
  };

  // Função que fecha o modal ao apertar ESC ou clicando em sobreposição
  const handleRequestCloseFunc = () => {
    setStatusModal(false);
  };

  const handleAtualizaProdutores = async () => {
    const { action } = actions;
    setRenderModal(action);
    handleShowModal();
  };

  return (
    <>
      <ModalPortal
        statusModalActual={statusModal}
        handleRequestCloseFunc={handleRequestCloseFunc}
      >
        {renderModal === "edit" ? (
          <AtualizaEmail
            data={actions.data}
            handleRequestCloseFunc={handleRequestCloseFunc}
          />
        ) : (
          <AtualizaStatus
            data={actions.data}
            handleRequestCloseFunc={handleRequestCloseFunc}
          />
        )}
      </ModalPortal>
      <S.Button onClick={handleAtualizaProdutores} {...props}>
        {children}
      </S.Button>
    </>
  );
};

export default AtualizarProdutores;
