import { Container } from './style'

/**
 * Button
 * @param {object} props
 * @param {function} props.onClick
 * @param {ReactNode} props.children
 */

// Button usado de forma geral durante todo o projeto Login / Armazém / Financeiro
// Usado no Login / Cards

export default function Button({ onClick, children, ...props }) {
  return (
    <>
      <Container onClick={onClick} {...props}>
        {children}
      </Container>
    </>
  )
}
