import React, { useState, useEffect } from "react";
import { db } from "../../../services/firebase";
import { updateDoc, doc, onSnapshot } from "firebase/firestore";

import { sessionStorageDecrypt } from "../../../utils/crypto";

import { Container, Header } from "./style";

import Switch from "../Notificacoes/Components/Switch/Switch";
import ModalModulosMenu from "./ModalModulosMenu/ModalModulosMenu";
import Loading from "../../../components/Layouts/Loading/Loading";

const ModulosMenu = () => {
  const cnpjArmazem = sessionStorageDecrypt("cnpjArmazem");
  const [switchData, setSwitchData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);

  function handleSendData() {
    const docRef = doc(db, "clientes", cnpjArmazem);
    updateDoc(docRef, { modulesApp: switchData });
    setIsOpenModal(true);
  }

  const handleRequestCloseFunc = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    onSnapshot(doc(db, "clientes", cnpjArmazem), (doc) => {
      setSwitchData(doc.data().modulesApp);
      setLoading(false);
    });
  }, [cnpjArmazem]);

  return (
    <Container countColuns={2}>
      {loading && <Loading />}

      {!loading && (
        <>
          <Header>
            <h1 className="textModulo">Módulos do Menu</h1>
            <p className="description">
              Escolha quais módulos aparecerão no menu do aplicaivo
            </p>
          </Header>
          <div className="swithNotification">
            {switchData.map((item) => (
              <Switch
                key={item.id}
                label={item.label}
                checked={item.checked}
                onToggle={() => {
                  setSwitchData((prevSwitchData) =>
                    prevSwitchData.map((mod) =>
                      mod.id === item.id
                        ? { ...mod, checked: !mod.checked }
                        : mod
                    )
                  );
                }}
              />
            ))}
          </div>
          <button onClick={handleSendData} className="buttonSendNotification">
            Enviar
          </button>
        </>
      )}
      {isOpenModal && (
        <ModalModulosMenu
          statusModalActual={isOpenModal}
          handleRequestCloseFunc={handleRequestCloseFunc}
        />
      )}
    </Container>
  );
};

export default ModulosMenu;
