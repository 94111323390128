// função que pega o ExtratoCafeFixo e depois trata baseado no Label e Dados que
// vem no webService e renderiza somente as peneiras que vem com dados
export function createExtratoCafe(dataTable, ExtratoCafeFixo) {
  const ExtratoCafe = [...ExtratoCafeFixo];
  const headerMap = {};

  dataTable.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (item[key] !== null && key.startsWith("DADOS_CLASSIF_")) {
        const labelKey = key.replace("DADOS_CLASSIF_", "LABEL_CLASSIF_");
        if (item[labelKey] !== null) {
          headerMap[key] = item[labelKey];
        }
      }
    });
  });

  Object.keys(headerMap).forEach((key) => {
    ExtratoCafe.push({
      accessorKey: key,
      header: headerMap[key],
      showHeader: true,
    });
  });

  return ExtratoCafe;
}

export function createEstoqueCafe(dataTable, subHeader, nomCliente) {
  const resultado = dataTable.reduce((acc, item) => {
    const codLote = String(item[subHeader]).trim();
    const nomeCliente = String(item[nomCliente]).trim();

    if (codLote !== "") {
      const existingItem = acc.find((element) => element.id === codLote);

      if (!existingItem && nomCliente) {
        acc.push({
          id: codLote,
          subId: nomeCliente,
        });
      }
    }

    return acc;
  }, []);

  return resultado;
}

export function createEstoqueCafeLote(dataTable) {
  const resultado = dataTable.reduce((acc, item) => {
    const codLote = String(item.COD_LOTE).trim();

    if (codLote !== "") {
      const existingItem = acc.find((element) => element.id === codLote);

      if (!existingItem) {
        acc.push({
          id: codLote,
        });
      }
    }

    return acc;
  }, []);

  return resultado;
}

export function formatSafra(year) {
  if (year === null || String(year).length <= 2) {
    return "";
  }

  return String(year).slice(-2);
}

export const recalculateTotals = (sortedData, flagTotal, setTotalsByTable) => {
  const newTotals = {};

  sortedData.forEach((item) => {
    let totalSacas = 0;
    let totalPeso = 0;

    item.data.forEach((subItem) => {
      totalSacas += subItem[flagTotal.total];
      totalPeso += subItem[flagTotal.peso];
    });

    newTotals[item.NUM_PR] = {
      sacas: totalSacas,
      peso: totalPeso,
    };
  });

  setTotalsByTable(newTotals);
};

export function formatDate(date) {
  var options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  };
  return new Date(date).toLocaleDateString("pt-BR", options);
}

export function formatNumberBR(number) {
  return Number(number)
    .toFixed(2)
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function formatarValorReal(numero) {
  return numero.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

// export function createExtratoCafe2(dataTable, ExtratoCafeFixo) {
//   const ExtratoCafe = [...ExtratoCafeFixo];
//   const headerMap = {};

//   dataTable.forEach((item) => {
//     Object.keys(item).forEach((key) => {
//       if (item[key] !== null && key.startsWith("DADOS_CLASSIF_")) {
//         const labelKey = key.replace("DADOS_CLASSIF_", "LABEL_CLASSIF_");
//         if (item[labelKey] !== null) {
//           const numero = key.replace("DADOS_CLASSIF_", ""); // Extrai o número do campo
//           headerMap[numero] = {
//             label: item[labelKey],
//             valor: item[key],
//           };
//         }
//       }
//     });
//   });

//   // Cria o array de objetos resultante, ligando os campos e ocultando os nulos
//   const result = ExtratoCafe.map((item) => {
//     const newItem = { ...item };
//     for (let i = 1; i <= 22; i++) {
//       if (headerMap[i]) {
//         newItem[`LABEL_CLASSIF_${i}`] = headerMap[i].label;
//         newItem[`DADOS_CLASSIF_${i}`] = headerMap[i].valor;
//       } else {
//         newItem[`LABEL_CLASSIF_${i}`] = null;
//         newItem[`DADOS_CLASSIF_${i}`] = null;
//       }
//     }
//     return newItem;
//   });

//   return result;
// }
