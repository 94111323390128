import styled from 'styled-components'

export const Container = styled.div`
  grid-area: main;
  margin: 2rem 2rem 0 2rem;
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-template-rows: 50px 1fr;
  align-items: center;

  .tableRelatorioFin {
    grid-column: 1 / -1;
    align-self: flex-start;
    margin-top: 8px;
    overflow-y: auto;
    max-height: 75vh;
  }

  .dateButtonRelatorioFin {
    display: flex;
    align-items: center;
    gap: 28px;

    .buttonPesquisar {
      padding: 10px 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      background-color: var(--blue-a18);
      color: white;
    }
  }

  .warningOrLoading {
    grid-column: 1 / -1;
    align-self: flex-start;
    margin-top: 30px;
    max-width: 830px;
  }

  @media only screen and (max-width: 900px) {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-items: start;
    align-items: start;
    margin: 5px 0 0 5px;
    align-self: flex-start;

    .dateButtonRelatorioFin {
      flex-direction: column;
      align-items: start;
      gap: 5px;

      .buttonPesquisar {
        align-self: end;
      }
    }
  }
`

export const customStylesSelect = {
  // control => estiliza o select
  control: (styles) => ({
    ...styles,
    backgroundColor: '#fff',
    width: 320,
    minHeight: 25,
    height: 38,
    fontSize: 16,
  }),
  // option => estiliza as opções
  option: (styles) => ({
    ...styles,
    backgroundColor: '#030F14',
    color: '#F1F3F4',
    width: 300,
    fontSize: 14,
    cursor: 'Pointer',
    '&:hover': {
      color: '#030F14',
      backgroundColor: '#A2BECA',
      fontWeight: 600,
    },
  }),
  // menu => estiliza a caixa das opções
  menu: (styles) => ({
    ...styles,
    backgroundColor: '#030F14',
    width: 280,
  }),
  // Estilização do Selecione
  placeholder: (styles) => ({
    ...styles,
    color: '#000',
    fontWeight: 'bold',
  }),
  // Estilização do texto quando selecionado
  singleValue: (styles) => ({
    ...styles,
    color: '#000',
    fontWeight: 'bold',
  }),
  // estilização da flechinha do menu e do separador
  indicatorsContainer: (styles) => ({
    ...styles,
    height: 35,
  }),
}
