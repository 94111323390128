import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  justify-self: center;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
  width: 450px;

  .totalCardSacas {
    background-color: var(--blue-a18);
    color: var(--blue-a1);
  }
  .totalCardPeso {
    background-color: var(--blue-a1);
    color: var(--blue-a18);
  }

  .totalCardSacas,
  .totalCardPeso {
    display: grid;
    grid-template-columns: 75px 1fr;
    border-radius: 8px;
    height: 80px;

    .imageCardSacas {
      border-right: 2px solid var(--blue-a1);
    }

    .imageCardPeso {
      border-right: 2px solid var(--blue-a18);
    }

    .imageCardSacas,
    .imageCardPeso {
      display: grid;
      align-self: center;

      img {
        align-self: center;
        justify-self: center;
      }
    }
  }
  .cardTotalPeso {
    border-radius: 8px;
  }
  .infoCardSacas,
  .infoCardPeso {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    span {
      :nth-child(1) {
        font-weight: bold;
      }
      :nth-child(2) {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
`;
