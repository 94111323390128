import { useEffect, useState } from 'react'

import ReactModal from 'react-modal'
import iconClosed from '../../../../assets/Icons-Modal/icon-closed.svg'
import {
  ChartModalProcFin,
  ChartModalProcFinBar,
} from '../../../Charts/ChartArm/ChartAnalises/ChartModalProcFin'
import { DataTableModalGraphic } from './DataTableModalGraphic'
import { Container, Header } from './style'
import Loading from '../../../Layouts/Loading/Loading'

// Modal usado no Dashboard / Armazém
// => É o modal usado para renderizar os gráficos e cards de Entrada, Saída, Preparo e Reensaque

export function responsiveModal(valueWidth, valueReturnTrue, valueReturnFalse) {
  if (window.innerWidth < valueWidth) {
    return valueReturnTrue
  }
  return valueReturnFalse
}

const tamanhoTela = window.innerWidth

export default function TableModal({
  statusModalActual,
  onRequestClose,
  infoModal,
  loading,
  setLoading,
  setApiModalIS,
}) {
  const [returnApiModal, setReturnApiModal] = useState()
  const [graphicTotal, setGraphicTotal] = useState({})

  useEffect(() => {
    if (infoModal) {
      setReturnApiModal(DataTableModalGraphic(infoModal))
      setLoading(false)
    }
    if (returnApiModal) {
      setGraphicTotal(returnApiModal.graphicTotal)
    }

    return () => setApiModalIS(undefined)
  }, [infoModal, setLoading, returnApiModal, setApiModalIS])

  return (
    <>
      <ReactModal
        isOpen={statusModalActual}
        onRequestClose={onRequestClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
          content: {
            position: 'absolute',
            top: '5%',
            left: responsiveModal(650, '2%', '12%'),
            right: responsiveModal(650, '2%', '12%'),
            bottom: '5%',
            border: 'none',
            background: 'var(--blue-a1)',
            borderRadius: '8px',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: '0',
            maxWidth: '100%',
          },
        }}
        ariaHideApp={false}
      >
        <Header>
          <h2>Instrução de Serviço</h2>
          <button onClick={onRequestClose}>
            <img src={iconClosed} alt="Imagem de fechar" />
          </button>
        </Header>
        {loading ? (
          <Loading />
        ) : (
          <Container>
            <div className="infoTableModal">
              <h3>Despejo</h3>
              <div className="cards">
                <ChartModalProcFin
                  embalagens={''}
                  sacasModalidade={[
                    graphicTotal.autorizado || '',
                    graphicTotal.realizado || '',
                  ]}
                />
              </div>
              <div className="info">
                <span className="info-blue">
                  Autorizado: {graphicTotal.autorizado || ''}
                </span>
                <span className="info-red">
                  Realizado: {graphicTotal.realizado || ''}
                </span>
              </div>
            </div>
            <div className="infoTableModal">
              <h3>Resultado</h3>
              <div className="cards">
                <ChartModalProcFin
                  embalagens={''}
                  sacasModalidade={[
                    graphicTotal.realizado || '',
                    graphicTotal.resultado || '',
                  ]}
                />
              </div>
              <div className="info">
                <span className="info-blue">
                  Despejado: {graphicTotal.realizado || ''}
                </span>
                <span className="info-red">
                  Resultado: {graphicTotal.resultado || ''}
                </span>
              </div>
            </div>
            <div className="graphicsPeneiraTableModal">
              <ChartModalProcFinBar
                datasetGraphic={returnApiModal}
                height={tamanhoTela <= 600 ? '' : '100'}
                width={tamanhoTela <= 600 ? '' : '500'}
              />
            </div>
          </Container>
        )}
      </ReactModal>
    </>
  )
}
