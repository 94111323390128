import HeaderModalPortal from "../../../../components/Modal/ModalPortal/HeaderModalPortal";
import Warning from "../../../../components/Layouts/Warning/Warning";
import Loading from "../../../../components/Layouts/Loading/Loading";
import { Wrapper } from "./style";
import { useAtualizarProdutores } from "../../../../hooks/useAtualizarProdutores";

const AtualizaStatus = ({ handleRequestCloseFunc, data }) => {
  const { handleStatusProdutor, error, loading, inativo } = useAtualizarProdutores();

  return (
    <Wrapper>
      <HeaderModalPortal
        handleRequestCloseFunc={handleRequestCloseFunc}
        title="Desabilitar Produtor"
      />
      <div className="content">
        {error && <Warning label={error} />}
        {loading ? (
          <Loading />
        ) : (
          !error && (
            <>
              {!inativo ? (
                <>
                  <h1>Deseja Desabilitar o usuário:</h1>
                  <h2>{data.nome}</h2>
                  <p>
                    Caso confirme, isso retira todas as permissões do usuário inclusive todos os
                    produtores vinculados.
                  </p>
                  <div className="actions">
                    <button className="cancel" onClick={handleRequestCloseFunc}>
                      cancelar
                    </button>
                    <button
                      className="auth"
                      onClick={() => handleStatusProdutor(data.cpf, data.nome)}
                    >
                      confirmar
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h1>Produtor Desabilitado com sucesso</h1>
                  <div className="actions">
                    <button className="success" onClick={handleRequestCloseFunc()}>
                      OK
                    </button>
                  </div>
                </>
              )}
            </>
          )
        )}
      </div>
    </Wrapper>
  );
};

export default AtualizaStatus;
