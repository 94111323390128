import AtualizarProdutores from "../../../../../Modules/AppProdutor/AtualizarProdutores/AtualizarProdutores";
import AddPermission from "../../../../../Modules/AppProdutor/CadastroProdutor/VinculaProdutores/FormVinculaProdutores/AddPermission/AddPermission";
import { ReactComponent as PowerIcon } from "../../../../../assets/Icons-Portal/power.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/Icons-Portal/edit.svg";
import { ReactComponent as AddProdutor } from "../../../../../assets/Icons-Portal/group-add.svg";

// ANCHOR Cabeçalho da tabela

export const COLUMNS = [
  {
    Header: "Nome",
    accessor: "nome",
  },
  {
    Header: "E-mail",
    accessor: "email",
    Cell: ({ cell: { value } }) => {
      if (!value) return "não cadastrado";
      return value;
    },
  },
  {
    Header: "Ações",
    accessor: "cpf",
    Cell: ({ cell: { row } }) => {
      const { original } = row;
      return (
        <div
          style={{
            display: "flex",
            gap: "0.8rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AtualizarProdutores title="Alterar E-mail" actions={{ action: "edit", data: original }}>
            <EditIcon />
          </AtualizarProdutores>
          <AtualizarProdutores
            title="Desabilitar Produtor"
            actions={{ action: "disable", data: original }}
          >
            <PowerIcon />
          </AtualizarProdutores>
          <AddPermission id={original.cpf} name={original.nome} linking={false}>
            <AddProdutor title="Adicionar permissão" />
          </AddPermission>
        </div>
      );
    },
  },
];
