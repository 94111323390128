import styled from "styled-components";

export const Container = styled.div`
  width: 240px;
  height: 80px;
  background-color: var(--blue-a3);
  margin: 2rem 0;
  padding: 0.5rem;
  border-radius: 8px;

  .title {
    width: 125px;
    height: 26px;
    background-color: var(--blue-a7);
    display: flex;
    align-items: center;
    margin-top: -16px;
  }

  .title p {
    color: var(--blue-a18);
    font-weight: 500;
    margin-left: 0.6rem;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem;
    margin-top: 6px;

    p {
      color: var(--blue-a13);
      font-weight: 700;
      font-size: 2.4rem;
    }
    span {
      color: var(--blue-a13);
      font-size: 1.2rem;
    }
  }

  @media (max-width: 900px) {
    width: 200px;
    margin: 1rem;
    .title {
      width: 100px;
      font-size: 0.8rem;
    }
    .content p {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 600px) {
    width: 300px;
    .content {
      p {
        font-size: 1.1rem;
      }
    }
  }
`;
