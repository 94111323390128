import React from 'react'

// Componente que exibe o circulo de porcentagem do CardArmAnalises
// => Usado na Pesagem Rodoviária

const CircleAnalisePesagemRodoviaria = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="160px"
      height="160px"
      className={className}
    >
      <defs>
        <linearGradient id="GradientColor">
          <stop offset="0%" stopColor="#70AAC2" />
          <stop offset="100%" stopColor="#0093DD" />
        </linearGradient>
      </defs>
      <circle cx="45" cy="45" r="35" strokeLinecap="round" />
    </svg>
  )
}

export default CircleAnalisePesagemRodoviaria
