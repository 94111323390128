import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem;

  .form {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    flex-direction: column;
    gap: 1rem;
    border-radius: 0.25rem;

    h1,
    h2 {
      text-align: center;
    }

    form {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 0.5rem;
      max-width: 38rem;
      min-width: 16rem;
      margin: 1rem;

      label {
        font-weight: bold;
        font-size: 1.1rem;
      }

      input {
        padding: 0.5rem;
        border-radius: 0.25rem;
        font-size: 16px;
        width: 100%;
        background-color: var(--blue-a2);
        border: 1px solid var(--blue-a18);
        outline: none;

        :focus {
          border: 2px solid var(--blue-emsoft);
        }
      }
    }
  }

  .headerModal {
    font-size: 1.5rem;
  }

  @media (max-width: 700px) {
    .form {
      h1,
      h2 {
        font-size: 1.2rem;
      }
    }

    .headerModal {
      font-size: 1rem;
    }
  }
`;

export const Buttons = styled.button`
  width: 6rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin-right: ${(props) => (props.$cancel ? "1rem" : "0")};
  font-size: 16px;
  color: ${(props) => (props.$cancel ? "red" : "#F1F3F4")};
  background-color: ${(props) => (props.$cancel ? "#F1F3F4" : "#030F14")};
  font-weight: bold;
  border: 1px solid ${(props) => (props.$cancel ? "red" : "#030F14")};
  outline: none;
  appearance: none;
  transition: all ease 0.3s;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  :hover {
    background-color: ${(props) => (props.$cancel ? "#DBDBDB" : "#195067")};
  }
`;
