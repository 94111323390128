import React from "react";
import { Container, Label, CheckboxLabel } from "./style";

const Switch = ({ label, checked, onToggle }) => {
  return (
    <Container>
      <Label>
        <input type="checkbox" className="inputCheckBox" checked={checked} onChange={onToggle} />
        <span className="slider" />
      </Label>
      <CheckboxLabel>{label}</CheckboxLabel>
    </Container>
  );
};

export default Switch;
