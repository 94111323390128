import { useState, useEffect, useContext, useCallback } from "react";
import * as S from "./style";
import {
  createUpdateDeleteVincular,
  getProdutoresVinculados,
} from "../../../../../../services/apiPortalProdutor";
import { AuthContext } from "../../../../../../contexts/Auth/AuthContext";
import { VinculaContext } from "../../../../../../contexts/VincularProdutores/VinculaContext";
import { sessionStorageDecrypt } from "../../../../../../utils/crypto";
import { ReactComponent as Delete } from "../../../../../../assets/Icons-Portal/power.svg";
import ModalPortal from "../../../../../../components/Modal/ModalPortal/ModalPortal";
import HeaderModalPortal from "../../../../../../components/Modal/ModalPortal/HeaderModalPortal";
import Loading from "../../../../../../components/Layouts/Loading/Loading";
import Warning from "../../../../../../components/Layouts/Warning/Warning";

const DetailsPermissions = ({ children, dataPermission }) => {
  const { dono, userAuth, password } = useContext(AuthContext);
  const { getData } = useContext(VinculaContext);

  const cnpjArmazem = sessionStorageDecrypt("cnpjArmazem");
  const [statusModal, setStatusModal] = useState(false);
  const [dataFaz, setDataFaz] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dataUser, setDataUser] = useState(undefined);

  // Função que fecha o modal ao apertar ESC ou clicando em sobreposição
  const handleRequestCloseFunc = () => {
    setStatusModal(false);
    getData();
  };

  const handleModal = async () => {
    await getDataUser()
    setStatusModal(true);
  };

  const getDataUser = useCallback(async () => {
    try {
      const res = await getProdutoresVinculados(
        dono,
        userAuth,
        password,
        dataPermission.CNPJ_USUARIO,
        //"04492438000195"
        cnpjArmazem
      );

      const novoArray = [];

      for (const objeto of res) {
        // Verifica se já existe um objeto no novoArray com o mesmo ID_USUARIO
        const usuarioExistente = novoArray.find(
          (elemento) => elemento.ID_USUARIO === objeto.ID_USUARIO
        );

        if (usuarioExistente) {
          // Se o usuário já existe, adiciona a fazenda ao array FAZENDAS
          usuarioExistente.FAZENDAS.push({
            ID_PERMISSAO: objeto.ID_PERMISSAO,
            COD_CADASTRO: objeto.COD_CADASTRO,
            COD_FAZENDA: objeto.COD_FAZENDA,
            FAZENDA_ATIVA: objeto.FAZENDA_ATIVA,
            NOM_CADASTRO: objeto.NOM_CADASTRO,
            NUM_PR: objeto.NUM_PR,
            NOM_FAZE: objeto.NOM_FAZE,
            NOM_CIDADE: objeto.NOM_CIDADE,
            NOM_UF: objeto.NOM_UF,
          });
        } else {
          // Se o usuário não existe no novoArray, cria um novo objeto com o formato desejado
          novoArray.push({
            ID_USUARIO: objeto.ID_USUARIO,
            CNPJ_USUARIO: objeto.CNPJ_USUARIO,
            NOME_USUARIO: objeto.NOME_USUARIO,
            EMAIL_USUARIO: objeto.EMAIL_USUARIO,
            TELEFONE_USUARIO: objeto.TELEFONE_USUARIO,
            S_OBSERVACOES: objeto.S_OBSERVACOES,
            USUARIO_ATIVO: objeto.USUARIO_ATIVO,
            FLG_CADASTRO: objeto.FLG_CADASTRO,
            FAZENDAS: [
              {
                ID_PERMISSAO: objeto.ID_PERMISSAO,
                COD_CADASTRO: objeto.COD_CADASTRO,
                COD_FAZENDA: objeto.COD_FAZENDA,
                FAZENDA_ATIVA: objeto.FAZENDA_ATIVA,
                NOM_CADASTRO: objeto.NOM_CADASTRO,
                NUM_PR: objeto.NUM_PR,
                NOM_FAZE: objeto.NOM_FAZE,
                NOM_CIDADE: objeto.NOM_CIDADE,
                NOM_UF: objeto.NOM_UF,
              },
            ],
          });
        }
      }

      setDataUser(novoArray[0]);
      
    } catch (error) {
      console.log(error);
    }
  },[cnpjArmazem, dataPermission, dono, password, userAuth])

  const handleDeletePermission = async (
    idPermission,
    codProd,
    codFaz,
    status
  ) => {
    setLoading(true);
    setError(false);
    try {
      await createUpdateDeleteVincular(
        dono,
        userAuth,
        password,
        "A",
        cnpjArmazem,
        // "04492438000195",
        idPermission,
        "0",
        dataPermission.ID_USUARIO,
        "C",
        codProd,
        codFaz,
        status
      );

      await getDataUser();
      // handleRequestCloseFunc();
    } catch (error) {
      console.log(error);
      setError("Erro ao tentar excluir permissão. Por favor tente mais tarde");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Objeto para armazenar o resultado final
    const resultado = [];

    // Objeto temporário para mapear COD_CLIENTE para seus objetos
    const tempObj = {};

    if (dataUser) {
      // Iterar pelos objetos e agrupá-los por COD_CLIENTE
      dataUser.FAZENDAS.forEach((objeto) => {
        const codCliente = objeto.COD_CADASTRO;

        if (!tempObj[codCliente]) {
          // Se o COD_CLIENTE ainda não estiver mapeado, crie um novo objeto para ele
          tempObj[codCliente] = {
            COD_CADASTRO: codCliente,
            NOM_CADASTRO: objeto.NOM_CADASTRO,
            fazendas: [],
          };
        }

        // Adicione as informações da fazenda a fazendas[]
        tempObj[codCliente].fazendas.push({
          COD_FAZENDA: objeto.COD_FAZENDA,
          NOM_FAZE: objeto.NOM_FAZE,
          NUM_PR: objeto.NUM_PR,
          ID_PERMISSAO: objeto.ID_PERMISSAO,
          FAZENDA_ATIVA: objeto.FAZENDA_ATIVA,
        });
      });

      // Convertemos o objeto temporário em um array de objetos
      for (const codCliente in tempObj) {
        resultado.push(tempObj[codCliente]);
      }
      setDataFaz(resultado);
    }
  }, [dataUser]);

  return (
    <div>
      <ModalPortal
        statusModalActual={statusModal}
        handleRequestCloseFunc={handleRequestCloseFunc}
      >
        <S.Wrapper>
          <HeaderModalPortal
            handleRequestCloseFunc={handleRequestCloseFunc}
            title={`Permissões - ${dataPermission.NOME_USUARIO}`}
          />
          {loading && <Loading />}
          {error && <Warning label={error} />}
          {!loading && !error && (
            <div className="content">
              {dataFaz.map((produtor, index) => {
                return (
                  <div key={index} className="info">
                    <p className="prod">
                      {produtor.COD_CADASTRO}- {produtor.NOM_CADASTRO}
                    </p>

                    {produtor.fazendas.map((faz, index) => {
                      return (
                        <div key={index} className="fazendas">
                          <div className="info-faz">
                            <S.Tag $active={faz.FAZENDA_ATIVA === "S"}>
                              {faz.FAZENDA_ATIVA === "S" ? "ativa" : "inativa"}
                            </S.Tag>
                            <p>
                              {faz.COD_FAZENDA}- {faz.NOM_FAZE}
                            </p>
                          </div>
                          <Delete
                            style={{
                              cursor: "pointer",
                              fill: faz.FAZENDA_ATIVA === "N" ? "green" : "red",
                            }}
                            onClick={() =>
                              handleDeletePermission(
                                faz.ID_PERMISSAO,
                                produtor.COD_CADASTRO,
                                faz.COD_FAZENDA,
                                faz.FAZENDA_ATIVA === "S" ? "N" : "S"
                              )
                            }
                            title={
                              faz.FAZENDA_ATIVA === "N"
                                ? "Ativar Fazenda"
                                : "Inativar Fazenda"
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
        </S.Wrapper>
      </ModalPortal>
      <div onClick={handleModal}>{children}</div>
    </div>
  );
};

export default DetailsPermissions;
