import { useState, useEffect, useContext } from "react";
import { useFetchAnalise } from "../../../hooks/useFetchAnalise";
import { AuthContext } from "../../../contexts/Auth/AuthContext";

import { Container, customStylesAnalises, customStylesGraphs, Graphic } from "./style";

import { DataCargaDescarga } from "./DataGraphics/CargaDescarga/DataCargaDescarga";
import { OptionsSelect } from "./DataOptions/OptionsSelect";
import { renderGraphic, returnDataGraphs } from "./settingsAnalises";
import {
  dateYear,
  lastSevenDayFormated,
  dayTodayFormated,
  firstMonthDayFormated,
  lastMonthDayFormated,
} from "../../../utils/dates";
import { tables } from "./tables";

import moment from "moment/moment";

import ButtonCalendar from "../../../components/Layouts/ButtonCalendar/ButtonCalendar";
import SelectGlobal from "../../../components/Layouts/Select/Select";
import DateModalAnalise from "../../../components/Modal/ModalArm/DateModalAnalise/DateModalAnalise";
import CargaDescarga from "./DataGraphics/CargaDescarga/CargaDescarga";
import ClassificaoCafe from "./DataGraphics/ClassificacaoCafe/ClassificaoCafe";
import Movimentacao24h from "./DataGraphics/Movimentacao24h/Movimentacao24h";
import PesagemRodoviaria from "./DataGraphics/PesagemRodoviaria/PesagemRodoviaria";
import ProcessosFinalizados from "./DataGraphics/ProcessosFinalizados/ProcessosFinalizados";
import CardAnalisePesagemRodoviaria from "../../../components/Cards/CardArmAnalises/CardPesagemRodoviaria/CardAnalisePesagemRodoviaria";
import SecondLoading from "../../../components/Layouts/Loading/SecondLoading/SecondLoading";
import { DataPesagemRodoviaria } from "./DataGraphics/PesagemRodoviaria/DataPesagemRodoviaria";
import CardProcessosFinalizados from "../../../components/Cards/CardArmAnalises/CardProcessosFinalizados/CardProcessosFinalizados";
import { DataProcessosFinalizados } from "./DataGraphics/ProcessosFinalizados/DataProcessosFinalizados";
import { InstrucaoServicos } from "./DataGraphics/InstrucaoServicos/InstrucaoServicos";

import iconBag from "../../../assets/Icons Analise/icon-bag.svg";
import iconDoc from "../../../assets/Icons Analise/icon-doc.svg";
import iconAverage from "../../../assets/Icons Analise/icon-average.svg";
import calendarThirty from "../../../assets/Icons Analise/calendarThirty.svg";
import calendarSeven from "../../../assets/Icons Analise/calendarSeven.svg";
import calendarAtt from "../../../assets/Icons Analise/calendarReset.svg";

import RastreioLotes from "./DataGraphics/RastreioLotes/RastreioLotes";
import { AnaliseContext } from "../../../contexts/Analise/AnaliseContext";

export default function AnalisesArm() {
  // Date Modal
  const [statusModalCalendar, setStatusModalCalendar] = useState(false);
  const [hiddenCalendar, setHiddenCalendar] = useState();
  const [control, setControl] = useState(false);
  const [date, setDate] = useState({});
  const { dataAnaliseInicio, dataAnaliseFinal } = date; // usar na api e no periodo

  // Select
  const [analise, setAnalise] = useState();
  const [servicos, setServicos] = useState(OptionsSelect[0].selecione);
  const [value, setValue] = useState("");
  const [displaySelect, setDisplaySelect] = useState(true);

  // Graphic
  const { dscControls } = useContext(AnaliseContext);
  const [datasetRenderGraphics, setDatasetRenderGraphics] = useState({
    datasetGraphicDia: "",
    datasetGraphicMes: "",
    datasetGraphicAno: "",
  });
  const [datasetOneGraphic, setDatasetOneGraphic] = useState();
  const [controleGrid, setControleGrid] = useState(false);
  const [widthSize, setWidthSize] = useState(window.innerWidth);

  // Card
  const [card, setCard] = useState({});
  const percentArray = [];

  // Table
  const [datasetTable, setDatasetTable] = useState(null);

  // Loading
  const [loading, setLoading] = useState(true);
  const [textLoading, setTextLoading] = useState("SELECIONE");

  // api
  const [api, setApi] = useState([]);
  const [apiArray, setApiArray] = useState([]);
  const { dono, userAuth, password, codEmpresa, codFilial } = useContext(AuthContext);
  const { getDataApiAnalisePesagem, getDataApiAnaliseProcessosFin, getDataIS } = useFetchAnalise(
    dono,
    userAuth,
    password,
    codEmpresa,
    codFilial,
    dataAnaliseInicio,
    dataAnaliseFinal
  );

  const params = {
    api,
    dataAnaliseInicio,
    dataAnaliseFinal,
    apiArray,
    dscControls,
  };

  // função para mostrar o modal do calendário
  const handleShowModalCalendar = () => {
    setStatusModalCalendar(true);
    setHiddenCalendar(true);
  };

  // função para fechar o modal do calendário
  const handleRequestCloseCalendar = () => {
    setHiddenCalendar(false);
  };

  // funções para chamar API
  async function fetchApiAnual() {
    const resArray = [];
    const resPesagem = await getDataApiAnalisePesagem();
    if (resPesagem.length > 0) {
      dscControls.map((controls) => {
        resArray[controls] = [];
        resPesagem.map((item) => {
          if (item.DSC_CONTROLE === controls) {
            resArray[controls].push(item);
          }
          return item;
        });
        return controls;
      });
    }
    setApi(resPesagem);
    setApiArray(resArray);
  }

  async function fetchApiPorData() {
    if (analise === "Processos Finalizados") {
      const resProcessosFin = await getDataApiAnaliseProcessosFin("X");
      setApi(resProcessosFin);
    } else if (analise === "Instruções de Serviço") {
      const resIS = await getDataIS();
      setApi(resIS);
    }
  }

  // função para renderizar os dados da tabela
  async function renderTable() {
    if (analise === "Carga e Descarga") {
      const resTable = await DataCargaDescarga.filterTableAnalise(params);
      setDatasetTable(resTable);
    } else if (analise === "Processos Finalizados") {
      setDatasetTable(api);
    } else if (analise === "Instruções de Serviço") {
      setDatasetTable(api);
    }
  }

  // função que pega o Onchange do select
  const handleChangeAnalise = (selectedOptions) => {
    setTextLoading("SELECIONE");
    setDisplaySelect(true);
    setControl(false);
    switch (selectedOptions.value) {
      case "Carga e Descarga":
        setAnalise(selectedOptions.value);
        setServicos(OptionsSelect[0].CargaDescarga);
        setValue(servicos[0].value);
        // necessário para esvaziar a table antes de renderizar outra
        setDatasetTable(null);
        setLoading(true);
        setControleGrid(false);
        break;
      case "Classificação de Café":
        setAnalise(selectedOptions.value);
        setServicos(OptionsSelect[0].ClassificacaoCafe);
        setValue(servicos[0].value);
        setLoading(true);
        setControleGrid(false);
        break;
      case "Movimentações 24h":
        setAnalise(selectedOptions.value);
        setServicos(OptionsSelect[0].Movimentacao24h);
        setValue(servicos[0].value);
        setLoading(true);
        setControleGrid(false);
        break;
      case "Pesagem Rodoviária":
        setAnalise(selectedOptions.value);
        setServicos(OptionsSelect[0].PesagemRodoviaria);
        setValue(servicos[0].value);
        setLoading(true);
        setControleGrid(true);
        break;
      case "Processos Finalizados":
        setAnalise(selectedOptions.value);
        setServicos(OptionsSelect[0].Finalizados);
        setValue(servicos[0].value);
        // necessário para esvaziar a table antes de renderizar outra
        setDatasetTable(null);
        setLoading(true);
        setControleGrid(true);
        break;
      case "Instruções de Serviço":
        setAnalise(selectedOptions.value);
        setServicos(OptionsSelect[0].InstrucaoServicos);
        setValue(servicos[0].value);
        // necessário para esvaziar a table antes de renderizar outra
        setDatasetTable(null);
        setLoading(true);
        setControleGrid(false);
        setControl(true);
        break;
      case "Rastreio de Lotes":
        setAnalise(selectedOptions.value);
        setValue(selectedOptions.value);
        setLoading(false);
        setControleGrid(false);
        setDisplaySelect(false);
        setControl(true);
        break;
      default:
        break;
    }
  };

  // função para renderizar o gráfico quando selecionado um tipo de análise
  const handleChangeGraphs = (selectedOptions) => {
    if (selectedOptions) {
      setTextLoading("");
      setLoading(true);
      setValue(selectedOptions);
      setTimeout(() => {
        const dataGraphs = returnDataGraphs(selectedOptions, params);

        if (analise === "Carga e Descarga") {
          if (selectedOptions.value === "Sacas por Controle e Tipo de Caminhão") {
            setLoading(false);
            setDatasetOneGraphic(dataGraphs);
          } else if (
            selectedOptions.value === "Tempo Médio por Sacas" ||
            selectedOptions.value === "Tempo Médio por Tickets" ||
            selectedOptions.value === "Sacas por Controle" ||
            selectedOptions.value === "Pesagem por Embalagem" ||
            selectedOptions.value === "Sacas por Tipo de Caminhão"
          ) {
            setLoading(false);
            setDatasetRenderGraphics(dataGraphs);
          }
          renderTable();
        }
        if (analise === "Pesagem Rodoviária") {
          const resCard = DataPesagemRodoviaria.pesagemRodoviariaCards(params);
          setCard(resCard);
          if (
            selectedOptions.value === "Peso por dia" ||
            selectedOptions.value === "Retorno por Embalagem" ||
            selectedOptions.value === "Qtd. de Pesagem por Embalagem" ||
            selectedOptions.value === "Qtd. de Pesagem por Caminhão"
          ) {
            setLoading(false);
            setDatasetOneGraphic(dataGraphs);
          }
        }
        if (analise === "Processos Finalizados") {
          if (selectedOptions.value === "Resumo Geral") {
            setLoading(false);
            setDatasetOneGraphic(dataGraphs);
          }
          renderTable();
        }
        if (analise === "Instruções de Serviço") {
          if (selectedOptions.value === "Instruções de Serviço") {
            setLoading(false);
          }
          renderTable();
        }
      }, 50);
    }
    if (analise === "Carga e Descarga" || analise === "Processos Finalizados") {
      renderTable();
    }
  };

  function outputGraphic() {
    if (analise === "Carga e Descarga") {
      return (
        <CargaDescarga
          value={value}
          renderGraphic={renderGraphic}
          datasetOneGraphic={datasetOneGraphic}
          datasetRenderGraphics={datasetRenderGraphics}
          loading={loading}
          responsive={widthSize}
        />
      );
    } else if (analise === "Classificação de Café") {
      return <ClassificaoCafe />;
    } else if (analise === "Movimentações 24h") {
      return <Movimentacao24h />;
    } else if (analise === "Pesagem Rodoviária") {
      return (
        <PesagemRodoviaria
          value={value}
          renderGraphic={renderGraphic}
          datasetOneGraphic={datasetOneGraphic}
          loading={loading}
          height="410"
          width="1000"
          responsive={widthSize}
        />
      );
    } else if (analise === "Processos Finalizados") {
      return (
        <ProcessosFinalizados
          value={value}
          renderGraphic={renderGraphic}
          datasetOneGraphic={datasetOneGraphic}
          loading={loading}
          height="410"
          width="1000"
          responsive={widthSize}
        />
      );
    } else if (analise === "Instruções de Serviço") {
      return <InstrucaoServicos dataTable={api} loadingTable={loading} />;
    } else if (analise === "Rastreio de Lotes") {
      return <RastreioLotes />;
    }
  }

  function outputCard() {
    if (analise === "Pesagem Rodoviária") {
      return (
        <div className="analises-cards">
          <CardAnalisePesagemRodoviaria
            nameCard="CARGAS"
            totalSacas={card.qtdSacasCarga}
            totalPesagens={card.qtdPesagemCarga}
            percent={card.percentCarga}
            percentArray={percentArray}
            classCard={card.percentCarga !== 0 ? "cardCarga" : ""}
          />
          <CardAnalisePesagemRodoviaria
            nameCard="DESCARGAS"
            totalSacas={card.qtdSacasDescarga}
            totalPesagens={card.qtdPesagemDescarga}
            percent={card.percentDescarga}
            percentArray={percentArray}
            classCard={card.percentCarga !== 0 ? "cardDescarga" : ""}
          />
          <CardAnalisePesagemRodoviaria
            nameCard="OUTROS"
            totalSacas={card.qtdSacasOutros}
            totalPesagens={card.qtdPesagemOutros}
            percent={card.percentOutros}
            percentArray={percentArray}
            classCard={card.percentCarga !== 0 ? "cardTerceiro" : ""}
          />
        </div>
      );
    } else if (analise === "Processos Finalizados") {
      const { datacards } = DataProcessosFinalizados.CardProcessosFin(params);

      return (
        <div>
          <CardProcessosFinalizados
            nameCard="Sacas"
            total={datacards.totalSacas.toLocaleString("pt-BR")}
            icon={iconBag}
          />
          <CardProcessosFinalizados
            nameCard="Documentos"
            total={datacards.totalDocs}
            icon={iconDoc}
          />
          <CardProcessosFinalizados nameCard="Média" total={datacards.media} icon={iconAverage} />
        </div>
      );
    }
  }

  function displayDateCalendar() {
    if (dataAnaliseInicio === lastSevenDayFormated || !dataAnaliseInicio) {
      return <span>Últimos 7 dias</span>;
    } else if (dataAnaliseInicio === firstMonthDayFormated || !dataAnaliseInicio) {
      return <span>Mensal</span>;
    } else {
      return (
        <span>
          De {dataAnaliseInicio} até {dataAnaliseFinal}
        </span>
      );
    }
  }

  useEffect(() => {
    if (analise === "Carga e Descarga") {
      fetchApiAnual();
    } else if (analise === "Pesagem Rodoviária") {
      fetchApiAnual();
    } else if (analise === "Processos Finalizados") {
      fetchApiPorData();
    } else if (analise === "Instruções de Serviço") {
      fetchApiPorData();
    }
  }, [analise]);

  useEffect(() => {
    setLoading(true);
    if (analise === "Carga e Descarga" || analise === "Pesagem Rodoviária") {
      const newDateAnaliseIni = moment(new Date(dataAnaliseInicio), "DD/MM/YYYY");
      const newDateYear = moment(new Date(dateYear), "DD/MM/YYYY");
      if (moment(newDateAnaliseIni).isBefore(newDateYear)) {
        fetchApiAnual();
      } else {
        handleChangeGraphs(value);
      }
    }
    if (analise === "Processos Finalizados") {
      fetchApiPorData();
    }
  }, [date]);

  useEffect(() => {
    if (api) {
      handleChangeGraphs(value);
    }
  }, [api]);

  useEffect(() => {
    const handleWidthSize = () => {
      setWidthSize(window.innerWidth);
    };
    window.addEventListener("resize", handleWidthSize);
    return () => {
      window.removeEventListener("resize", handleWidthSize);
    };
  }, []);

  // passado para cálculo dos cards do Analise => Pesagem Rodoviária
  percentArray.push(card.percentCarga, card.percentDescarga, card.percentOutros);

  return (
    <>
      <DateModalAnalise
        handleShowModalCalendar={statusModalCalendar}
        handleRequestCloseCalendar={handleRequestCloseCalendar}
        hiddenCalendar={hiddenCalendar}
        setDate={setDate}
        setHiddenCalendar={setHiddenCalendar}
      />

      <Container>
        <div className="analises-header">
          <div className="analises-text">
            <span>Análises</span>
            <SelectGlobal
              options={OptionsSelect[0].analise}
              styles={customStylesAnalises}
              onChange={handleChangeAnalise}
            />
          </div>
          {analise && !control && (
            <div className="analises-calendar">
              <button
                onClick={() =>
                  setDate({
                    dataAnaliseInicio: firstMonthDayFormated,
                    dataAnaliseFinal: lastMonthDayFormated,
                  })
                }
                className="buttonLast30"
                title={`${firstMonthDayFormated} a ${lastMonthDayFormated}`}
              >
                <img src={calendarThirty} alt="" />
              </button>
              <button
                onClick={() =>
                  setDate({
                    dataAnaliseInicio: lastSevenDayFormated,
                    dataAnaliseFinal: dayTodayFormated,
                  })
                }
                className="buttonLast7"
                title={`${lastSevenDayFormated} a ${dayTodayFormated}`}
              >
                <img src={calendarSeven} alt="" />
              </button>
              <button
                onClick={() =>
                  analise === "Processos Finalizados" ? fetchApiPorData() : fetchApiAnual()
                }
                className="buttonAtt"
                title={"Atualizar"}
              >
                <img src={calendarAtt} alt="" />
              </button>
              <ButtonCalendar handleShowModalCalendar={handleShowModalCalendar} control={control} />
              <div className="analises-dates">
                <span>Período:</span>
                {displayDateCalendar()}
              </div>
            </div>
          )}
        </div>
        <div className="analises-charts">
          <div className="charts-text">
            {displaySelect && (
              <>
                <span className="text-analises">Tipo de Análise</span>
                <SelectGlobal
                  value={value}
                  options={servicos}
                  styles={customStylesGraphs}
                  onChange={handleChangeGraphs}
                />
              </>
            )}
          </div>
          {!value || loading ? (
            <div className="loadingSelecione">
              <SecondLoading text={textLoading} />
            </div>
          ) : (
            ""
          )}
          <Graphic grid={controleGrid ? "gridGraphicCard" : "gridOnlyGraphic"}>
            {outputGraphic()}
            {controleGrid && !loading && outputCard()}
          </Graphic>
        </div>
        <div className="analises-table">{tables(datasetTable, analise)}</div>
      </Container>
    </>
  );
}
