import styled from 'styled-components'

export const Container = styled.div`
  /*
 * Baseline styles
 */
  body {
    background: #222;
    text-align: center;
    padding: 20%;
  }
  h2 {
    color: var(--blue-a8);
    margin: 0;
    text-align: center;
    font: 0.8em verdana;
    text-transform: uppercase;
    letter-spacing: 0.3em;
  }

  /*
 * Loading Dots
 * Can we use pseudo elements here instead :after?
 */
  .loading span {
    display: inline-block;
    vertical-align: middle;
    width: 0.6em;
    height: 0.6em;
    margin: 0.19em;
    background: var(--blue-a10);
    border-radius: 0.6em;
    animation: loading 1s infinite alternate;
  }

  /*
 * Dots Colors
 * Smarter targeting vs nth-of-type?
 */
  .loading span:nth-of-type(2) {
    background: var(--blue-a9);
    animation-delay: 0.2s;
  }
  .loading span:nth-of-type(3) {
    background: var(--blue-a8);
    animation-delay: 0.4s;
  }
  .loading span:nth-of-type(4) {
    background: var(--blue-a7);
    animation-delay: 0.6s;
  }
  .loading span:nth-of-type(5) {
    background: var(--blue-a6);
    animation-delay: 0.8s;
  }
  .loading span:nth-of-type(6) {
    background: var(--blue-a5);
    animation-delay: 1s;
  }
  .loading span:nth-of-type(7) {
    background: var(--blue-a4);
    animation-delay: 1.2s;
  }

  /*
 * Animation keyframes
 * Use transition opacity instead of keyframes?
 */
  @keyframes loading {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
