import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 8rem;
  padding: 0.6rem;
  background-color: var(--blue-a4);
  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: bold;
  transition: all ease 0.3s;
  :hover {
    background-color: var(--blue-a5);
  }
`;
