import moment from "moment";
import { apiDecryptURL } from "../utils/webService";

const dateToday = moment().format("DD/MM/YYYY");

export const getContasAberto = async (
  dono,
  user,
  password,
  empresa,
  dataIni = "",
  dataFin = ""
) => {
  const api = apiDecryptURL();
  const dadosUsuarioBanco = `Dono=${dono}&Usuario=${user}&Senha=${password}&Empresa=${empresa}&DataRef=${dateToday}`;

  const dadosUsuario = `Dono=${dono}&Usuario=${user}&Senha=${password}&Empresa=${empresa}&DataIni=${dataIni}&DataFim=${dataFin}`;

  const { data: apiContaPagar } = await api.post(
    "WebService.asmx/RetTitulosPagarJSon",
    dadosUsuario
  );

  const keyContaPagar = Object.keys(apiContaPagar);
  const { [keyContaPagar]: contaPagar } = await apiContaPagar;

  const { data: apiContaReceber } = await api.post(
    "WebService.asmx/RetTitulosReceberJSon",
    dadosUsuario
  );

  const keyContaReceber = Object.keys(apiContaReceber);
  const { [keyContaReceber]: contaReceber } = await apiContaReceber;

  const { data: apiSaldoBanco } = await api.post(
    "WebService.asmx/RetSaldoBancoJSon",
    dadosUsuarioBanco
  );

  const keySaldoBanco = Object.keys(apiSaldoBanco);
  const { [keySaldoBanco]: saldoBanco } = await apiSaldoBanco;
  return { contaPagar, contaReceber, saldoBanco };
};

export const getContasPagas = async (dono, user, password, empresa, dataIni = "", dataFin = "") => {
  const api = apiDecryptURL();
  const dadosUsuario = `Dono=${dono}&Usuario=${user}&Senha=${password}&Empresa=${empresa}&DataIni=${dataIni}&DataFim=${dataFin}`;

  const { data: apiContaPagas } = await api.post(
    "WebService.asmx/RetTitulosPagosJSon",
    dadosUsuario
  );
  const keyContasPagas = Object.keys(apiContaPagas);
  const { [keyContasPagas]: contaPagas } = await apiContaPagas;

  const { data: apiContaRecebidas } = await api.post(
    "WebService.asmx/RetTitulosRecebidosJSon",
    dadosUsuario
  );
  const keyContasRecebidas = Object.keys(apiContaPagas);
  const { [keyContasRecebidas]: contaRecebidas } = await apiContaRecebidas;

  return { contaPagas, contaRecebidas };
};
