import ChartTime from '../../../components/Charts/ChartArm/ChartAnalises/ChartTime'
import ChartTotal from '../../../components/Charts/ChartArm/ChartAnalises/ChartTotal'
import { DataCargaDescarga } from './DataGraphics/CargaDescarga/DataCargaDescarga'
import { DataPesagemRodoviaria } from './DataGraphics/PesagemRodoviaria/DataPesagemRodoviaria'
import { DataProcessosFinalizados } from './DataGraphics/ProcessosFinalizados/DataProcessosFinalizados'

// dependências
// => ChartTime e ChartTotal para exibiri o gráfico corretamente de acordo com a Análise
// => Cálculos feitos dentro do DataGraphics / DataNomeAnalise para exibir os resultados

export function renderGraphic(
  value,
  dataset,
  height,
  width,
  displayDataLabel,
  titleTooltip,
  responsive,
) {
  return value.label === 'Tempo Médio por Sacas' ||
    value.label === 'Tempo Médio por Tickets' ? (
    <>
      <ChartTime
        datasetGraphic={dataset}
        height={height}
        width={width}
        titleTooltip={titleTooltip}
        responsive={responsive}
      />
    </>
  ) : (
    <>
      <ChartTotal
        datasetGraphic={dataset}
        height={height}
        width={width}
        titleTooltip={titleTooltip}
        displayDataLabel={displayDataLabel}
        responsive={responsive}
      />
    </>
  )
}

export function returnDataGraphs(selectedOptions, params) {
  switch (selectedOptions.value) {
    case 'Tempo Médio por Sacas': {
      const cargaDescargaResCarga = DataCargaDescarga.tempoMedioSacas(params)
      return cargaDescargaResCarga
    }

    case 'Tempo Médio por Tickets': {
      const cargaDescargaResTicket = DataCargaDescarga.tempoMedioTickets(params)
      return cargaDescargaResTicket
    }

    case 'Sacas por Controle': {
      const cargaDescargaResSacasControle =
        DataCargaDescarga.qtdSacasControle(params)
      return cargaDescargaResSacasControle
    }

    case 'Pesagem por Embalagem': {
      const cargaDescargaResPesoEmbalagem =
        DataCargaDescarga.pesagemEmbalagem(params)
      return cargaDescargaResPesoEmbalagem
    }

    case 'Sacas por Tipo de Caminhão': {
      const cargaDescargaResTipoCaminhao =
        DataCargaDescarga.pesagemTipoCaminhao(params)
      return cargaDescargaResTipoCaminhao
    }

    case 'Sacas por Controle e Tipo de Caminhão': {
      const cargaDescargaResControlVeiculo =
        DataCargaDescarga.qtdSacasControleCaminhao(params)
      return cargaDescargaResControlVeiculo
    }

    case 'Tipo de Peneira':
      return {}
    case 'Tipo de Café':
      return {}
    case 'Tipo de Bebida':
      return {}
    case 'Tipo de Padrão':
      return {}
    case 'Empilhadeira':
      return {}
    case 'Motoristas':
      return {}
    case 'Peso por dia': {
      const PesagemRodoviariaResResumoGeral =
        DataPesagemRodoviaria.PesagemResumoGeral(params)
      return PesagemRodoviariaResResumoGeral
    }

    case 'Retorno por Embalagem': {
      const PesagemRodoviariaResRetornoEmbalagem =
        DataPesagemRodoviaria.PesagemRodoviariaPorRetorno(params)
      return PesagemRodoviariaResRetornoEmbalagem
    }

    case 'Qtd. de Pesagem por Embalagem': {
      const PesagemRodoviariaResPesagemEmbalagens =
        DataPesagemRodoviaria.PesagemRodoviariaPorEmbalagem(params)
      return PesagemRodoviariaResPesagemEmbalagens
    }

    case 'Qtd. de Pesagem por Caminhão': {
      const PesagemRodoviariaResCaminhaoEmbalagens =
        DataPesagemRodoviaria.PesagemRodoviariaPorCaminhao(params)
      return PesagemRodoviariaResCaminhaoEmbalagens
    }

    case 'Resumo Geral': {
      const resumoGeralProcessosFin =
        DataProcessosFinalizados.AnaliseProcessosFin(params)
      return resumoGeralProcessosFin
    }

    default:
      return {
        datasetGraphicDia: '',
        datasetGraphicMes: '',
        datasetGraphicAno: '',
      }
  }
}
