import { useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../services/firebase";
import { v4 as uuidv4 } from "uuid";

export const useRegisterPortal = () => {
  const [error, setError] = useState(false);

  const registerProdutor = async (
    idProdutor,
    password,
    confirmPassword,
    name,
    email,
    cnpjArmazem
  ) => {
    if (!name) {
      setError("Digite o nome do usuário");
      return false;
    } else if (!password) {
      setError("Por favor digite uma senha");
      return false;
    } else if (!email) {
      setError("Por favor digite um e-mail");
      return false;
    } else if (password.length < 6) {
      setError("A senha precisa conter pelo menos 6 caracteres");
      return false;
    } else if (password !== confirmPassword) {
      setError("As senhas precisam ser iguais");
      return false;
    } else {
      setError("");
      const uid = uuidv4();
      const firebaseDB = {
        nome: name.toUpperCase(),
        apelido: name.toUpperCase(),
        termos: false,
        email: email.toLowerCase(),
        uid,
        cpf: idProdutor,
        armazens: [cnpjArmazem],
        password,
        passwordTemporario: true,
      };
      await setDoc(doc(db, "produtores", idProdutor), firebaseDB);
      return true;
    }
  };

  return {
    setError,
    error,
    registerProdutor,
  };
};
