import { useState } from "react";
import ModalPortal from "../../../components/Modal/ModalPortal/ModalPortal";
import * as S from "./style";
import FormRegisterAvisos from "./FormRegisterAvisos/FormRegisterAvisos";

const CadastroAvisos = ({ children, ...props }) => {
  const [statusModal, setStatusModal] = useState(false);

  // Função que altera o status do modal para True ou  False
  const handleShowModal = () => {
    setStatusModal(!statusModal);
  };

  // Função que fecha o modal ao apertar ESC ou clicando em sobreposição
  const handleRequestCloseFunc = () => {
    setStatusModal(false);
  };

  return (
    <>
      <ModalPortal
        statusModalActual={statusModal}
        handleRequestCloseFunc={handleRequestCloseFunc}
      >
        <FormRegisterAvisos
          handleRequestCloseFunc={handleRequestCloseFunc}
          title="Publicar Aviso"
        />
      </ModalPortal>
      <S.Button onClick={handleShowModal} {...props}>
        {children}
      </S.Button>
    </>
  );
};

export default CadastroAvisos;
