import React from "react";
import moment from "moment/moment";

import { Chart } from "react-chartjs-2";
import "chartjs-adapter-moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  TimeScale,
  Title,
} from "chart.js";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  TimeScale,
  ChartDataLabels,
  Title
);

// Graphic usado nas Analises / Armazém
// => Esse é o gráfico que exibe as informações em time
// => Usado no Carga e Descarga (Tempo Médio)

export default function ChartTime({ datasetGraphic, height, width, titleTooltip, responsive }) {
  const data = { labels: [], datasets: [] };

  function epochToHhMmSs(epoch) {
    return moment.utc(epoch * 1000).format("HH:mm:ss");
  }

  return (
    <>
      <Chart
        height={height}
        width={width}
        title={titleTooltip}
        data={datasetGraphic || data}
        options={{
          scales: {
            yAxes: {
              ticks: {
                callback: function (v) {
                  return epochToHhMmSs(v);
                },
                stepSize: 10,
                color: "#fff",
              },
            },
            x: {
              ticks: {
                color: "#fff",
              },
            },
          },
          responsive: !(responsive <= 1500),
          plugins: {
            title: {
              display: true,
              text: titleTooltip,
              color: "#fff",
            },
            legend: {
              position: "bottom",
              labels: {
                color: "#fff",
              },
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  const data = moment
                    .utc(tooltipItem.dataset.data[tooltipItem.dataIndex] * 1000)
                    .format("HH:mm:ss");
                  return data;
                },
              },
            },
          },
        }}
      />
    </>
  );
}
