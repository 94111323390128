import styled, { css } from "styled-components";

const changeGrid = {
  gridOnlyGraphic: () => css`
    grid-template-columns: 1fr;
  `,
  gridGraphicCard: () => css`
    grid-template-columns: 1fr 240px;
  `,
};

export const Graphic = styled.div`
  display: grid;
  ${({ grid }) => css`
    ${!!grid && changeGrid[grid]()}
  `}
  .analises-cards {
    display: flex;
    right: 0;
    gap: 5px;
    margin-right: 15px;
    margin-top: 10px;
    width: 230px;
    height: 400px;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
  }
`;

export const Container = styled.div`
  grid-area: main;
  overflow-y: auto;
  display: grid;
  align-items: start;
  align-content: start;

  // estilização parte superior da página (select e calendar)
  .analises-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 1 / -1;
  }

  .analises-text {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 2px 0px 0px 15px;
    span {
      margin-left: 3px;
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  .analises-calendar {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 20px;
  }

  .analises-dates {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    width: 130px;
    font-size: 0.9rem;
  }

  // estilização dos botões de atualizar, 30 dias e 7 dias
  .buttonAtt,
  .buttonLast30,
  .buttonLast7 {
    border: none;
    appearance: none;
    background: none;
    outline: none;
    padding-top: 0.5rem;
    margin-right: 10px;
  }

  // estilização do meio da página (select e gráficos)
  .analises-charts {
    display: grid;
    grid-template-rows: 60px 1fr;
    background-color: var(--blue-a18);
    margin: 0.3rem 0.5rem 0.5rem 1rem;
    border-radius: 8px;
    height: 98%;
    position: relative;

    .div-table,
    .div-lote {
      /* background-color: red; */
      min-height: 420px;

      .container-table {
        /* background-color: blue; */
      }
    }
  }

  .charts-text {
    grid-row: 1/-1;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 5px 30px;
    .text-analises {
      color: var(--blue-a1);
      padding: 3px 0px 5px 0px;
      font-size: 0.8rem;
    }
  }

  .chart-diario {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    margin: 3px 10px 3px 10px;

    .diario {
      width: 100%;
      height: 99%;
      border: 2px solid var(--blue-a16);
      border-radius: 8px;
    }
  }

  .charts-mensal-anual {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    margin: 0px 10px 5px 10px;

    .chart-mes {
      grid-column: 1;
      width: 100%;
      height: 98%;
      border: 2px solid var(--blue-a16);
      border-radius: 8px;
    }
    .chart-ano {
      grid-column: 2;
      width: 99%;
      height: 98%;
      border: 2px solid var(--blue-a16);
      border-radius: 8px;
      margin-left: 4px;
    }
  }

  // estilizaçãp da parte inferior da página (tabela)
  .analises-table {
    grid-template-columns: 1 / -1;
    display: flex;
    margin: 2rem 1rem 1rem 1rem;
  }

  .secondLoading,
  .loadingSelecione {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20%;
  }

  @media only screen and (max-width: 1500px) {
    .analises-charts {
      height: 485px;
    }
  }

  @media only screen and (max-width: 850px) {
    .analises-header {
      grid-template-columns: 1fr;
      .analises-calendar {
        justify-content: start;
        margin-left: 20px;
      }
      .analises-text {
        grid-row: 2;
      }
    }
  }

  @media only screen and (max-width: 400px) {
    .charts-text {
      padding: 0px 0px 5px 5px;
      font-size: 10rem;
    }
    .analises-dates {
      font-size: 0.9rem;
    }
  }
`;

// style do select
export const customStylesAnalises = {
  // control => estiliza o select
  control: (styles) => ({
    ...styles,
    backgroundColor: "#030F14",
    width: 240,
  }),
  // option => estiliza as opções
  option: (styles) => ({
    ...styles,
    backgroundColor: "#030F14",
    color: "#F1F3F4",
    width: 200,
    cursor: "Pointer",
    "&:hover": {
      color: "#030F14",
      backgroundColor: "#A2BECA",
      fontWeight: 600,
    },
  }),
  // menu => estiliza a caixa das opções
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#030F14",
    width: 200,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "white",
  }),
  container: (styles) => ({
    ...styles,
    width: 200,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "white",
  }),
};

// style do select
export const customStylesGraphs = {
  // control => estiliza o select
  control: (styles) => ({
    ...styles,
    backgroundColor: "#030F14",
    width: 290,
    minHeight: 25,
    height: 30,
    fontSize: "0.8rem",
  }),
  // option => estiliza as opções
  option: (styles) => ({
    ...styles,
    backgroundColor: "#030F14",
    color: "#F1F3F4",
    width: 300,
    fontSize: 14,
    cursor: "Pointer",
    "&:hover": {
      color: "#030F14",
      backgroundColor: "#A2BECA",
      fontWeight: 600,
    },
  }),
  // menu => estiliza a caixa das opções
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#030F14",
    width: 280,
  }),
  // Estilização do Selecione
  placeholder: (styles) => ({
    ...styles,
    color: "white",
  }),
  // Estilização do texto quando selecionado
  singleValue: (styles) => ({
    ...styles,
    color: "white",
  }),
  // estilização da flechinha do menu e do separador
  indicatorsContainer: (styles) => ({
    ...styles,
    height: 25,
  }),
};
