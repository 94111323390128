import checked from "../../../../assets/Icons Analise/icon-checked.svg";
import truck from "../../../../assets/Icons Analise/icon-truck.svg";

function returnIcon(value, img) {
  return value === 1 ? (
    <>
      <img src={img} alt="" />
    </>
  ) : (
    "-"
  );
}

export const CargaDescarga = [
  {
    Header: "Controle",
    accessor: "DSC_CONTROLE",
    // Cell: ({ cell: { value } }) => value.split(" ").shift(),
  },
  {
    Header: "Ticket",
    accessor: "NUM_CONTROLE",
  },
  {
    Header: "Inicio",
    accessor: "dateInicio",
  },
  {
    Header: "Término",
    accessor: "dateFim",
  },
  {
    Header: "Total",
    accessor: "tempoTotal",
  },
  {
    Header: "Qtd. Sacas",
    accessor: "NUM_SACAS",
  },
  {
    Header: "Tempo por Sacas",
    accessor: "tempoSacas",
  },
  {
    Header: "Peso",
    accessor: "NUM_PESO_LIQUIDO",
  },
  {
    Header: "Ret. Emb.",
    accessor: "NUM_RETORNA_EMB",
    Cell: ({ cell: { value } }) => returnIcon(value, checked),
  },
  {
    Header: "Embalagens",
    columns: [
      {
        Header: "BAG",
        accessor: "NUM_BIGBAG",
        Cell: ({ cell: { value } }) => returnIcon(value, checked),
      },
      {
        Header: "SAC",
        accessor: "NUM_SACARIA",
        Cell: ({ cell: { value } }) => returnIcon(value, checked),
      },
      {
        Header: "GRA",
        accessor: "NUM_GRANEL",
        Cell: ({ cell: { value } }) => returnIcon(value, checked),
      },
      {
        Header: "OUT",
        accessor: "NUM_OUTRAS",
        Cell: ({ cell: { value } }) => returnIcon(value, checked),
      },
    ],
  },
  {
    Header: "Carroceria",
    columns: [
      {
        Header: "CAÇ",
        accessor: "NUM_CACAMBA_CAM",
        Cell: ({ cell: { value } }) => returnIcon(value, truck),
      },
      {
        Header: "GRAN",
        accessor: "NUM_GRANELEIRO_CAM",
        Cell: ({ cell: { value } }) => returnIcon(value, truck),
      },
      {
        Header: "OUT",
        accessor: "NUM_OUTROS_CAM",
        Cell: ({ cell: { value } }) => returnIcon(value, truck),
      },
    ],
  },
];
