import { Container, Footer } from "./style";
import { useState, useContext, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { yearCopyright } from "../../utils/dates";
import { AmbienteContext } from "../../contexts/Home/AmbienteContext";
import logo from "../../assets/img/logotipoMenu.svg";
import emsoft from "../../assets/img/BRASIL INFORMÁTICA.svg";
import separador from "../../assets/Icons Sidebar/Separador.svg";
import iconClose from "../../assets/Icons-Modal/icon-closed.svg";
import iconMenu from "../../assets/Icons Sidebar/icon-menu.svg";
import iconHome from "../../assets/Icons Navbar/icon-home.svg";
import { menuRender } from "../../utils/MenuRender";

// Sidebar (lateral/esquerda) usado e reaproveitado em todos os módulos

// O NavLink é usado para aplicar estilos diretamente quando a rota esta selecionadas

export default function Sidebar() {
  const selected = {
    background: "var(--blue-emsoft)",
    borderRadius: "0.25rem",
    padding: "0.30rem 0.0rem 0.30rem 0rem",
    width: "100%",
  };

  const [showMenu, setShowMenu] = useState(false);

  const { ambiente } = useContext(AmbienteContext);

  const closeMenu = document.querySelector("#closeMenuClick");

  const closeMenuClick = useCallback(
    (e) => {
      if (closeMenu.contains(e.target)) {
        setShowMenu(true);
      } else {
        setShowMenu(false);
      }
    },
    [closeMenu]
  );

  useEffect(() => {
    if (showMenu) {
      document.addEventListener("mousedown", closeMenuClick);
      return () => document.removeEventListener("mousedown", closeMenuClick);
    }
  }, [showMenu, closeMenuClick]);

  return (
    <>
      <Container className={showMenu} id={!showMenu ? "closeMenuClick" : ""}>
        {!showMenu && (
          <div className="icon-showMenu">
            <img src={iconMenu} alt="" onClick={() => setShowMenu(true)} />
          </div>
        )}
        <div className={showMenu ? "menuActive" : "menuDisabled"}>
          <div className="button_close">
            <img src={iconClose} alt="" onClick={() => setShowMenu(false)} />
          </div>
          <div className="sidebar_logo">
            <img src={logo} alt="emsoft" className="img_logo" />
            <img src={separador} alt="" className="img_separador" />
          </div>

          <div className="sidebar_menu">
            {/* MENU PARA CADA ITEM DO MEU PRINCIPAL ESCOLHIDO, APARECER NO LUGAR DO MEU PRINCIPAL */}

            {menuRender(ambiente, setShowMenu, selected)}
          </div>
        </div>
        <Footer className={showMenu ? "menuActive" : "menuDisabled"}>
          <div className="nav">
            {/* APARECER O NOME DO MENU ESCOLHIDO DO MENU PRINCIPAL */}
            <span>{ambiente}</span>
            {/* <span>Financeiro</span> */}
            <NavLink to="/">
              <img
                src={iconHome}
                alt="icone home"
                onClick={() => setShowMenu(false)}
              />
            </NavLink>
          </div>
          <footer>Emsoft Solutions © {yearCopyright}</footer>
        </Footer>
      </Container>
    </>
  );
}
