import { NavLink } from 'react-router-dom'
import dashboard from '../../../assets/Icons Sidebar/icon-dashboard.svg'
import relatorios from '../../../assets/Icons Sidebar/icon-relatorios.svg'

// Opções do Menu usados no Sidebar do Financeiro

export default function MenuFinanceiro({ setShowMenu, selected }) {
  return (
    <>
      <div>
        <NavLink
          to="/financeiro"
          end
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={dashboard} alt="" className="menu_img" />
          Dashboard
        </NavLink>
      </div>

      {/* <div>
        <NavLink
          to="financeiro/analises"
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={analises} alt="" className="menu_img" />
          Análises
        </NavLink>
      </div> */}

      <div>
        <NavLink
          to="/financeiro/relatorios"
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={relatorios} alt="" className="menu_img" />
          Relatórios
        </NavLink>
      </div>
    </>
  )
}
