import { useState, useContext, useCallback } from "react";
import { VinculaContext } from "./VinculaContext";
import { AuthContext } from "../Auth/AuthContext";
import { sessionStorageDecrypt } from "../../utils/crypto";
import { getProdutoresVinculados } from "../../services/apiPortalProdutor";

export const VinculaProvider = ({ children }) => {
  const [statusVinculaModal, setStatusVinculaModal] = useState(false);
  const [userId, setUserId] = useState(false);
  const [loading, setLoading] = useState(true);

  const { dono, userAuth, password } = useContext(AuthContext);
  const cnpjArmazem = sessionStorageDecrypt("cnpjArmazem");

  const [data, setData] = useState([]);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getProdutoresVinculados(
        dono,
        userAuth,
        password,
        "0",
        //"04492438000195"
        cnpjArmazem
      );
      const novoArray = [];

      for (const objeto of res) {
        // Verifica se já existe um objeto no novoArray com o mesmo ID_USUARIO
        const usuarioExistente = novoArray.find(
          (elemento) => elemento.ID_USUARIO === objeto.ID_USUARIO
        );

        if (usuarioExistente) {
          // Se o usuário já existe, adiciona a fazenda ao array FAZENDAS
          usuarioExistente.FAZENDAS.push({
            ID_PERMISSAO: objeto.ID_PERMISSAO,
            COD_CADASTRO: objeto.COD_CADASTRO,
            COD_FAZENDA: objeto.COD_FAZENDA,
            FAZENDA_ATIVA: objeto.FAZENDA_ATIVA,
            NOM_CADASTRO: objeto.NOM_CADASTRO,
            NUM_PR: objeto.NUM_PR,
            NOM_FAZE: objeto.NOM_FAZE,
            NOM_CIDADE: objeto.NOM_CIDADE,
            NOM_UF: objeto.NOM_UF,
          });
        } else {
          // Se o usuário não existe no novoArray, cria um novo objeto com o formato desejado
          novoArray.push({
            ID_USUARIO: objeto.ID_USUARIO,
            CNPJ_USUARIO: objeto.CNPJ_USUARIO,
            NOME_USUARIO: objeto.NOME_USUARIO,
            EMAIL_USUARIO: objeto.EMAIL_USUARIO,
            TELEFONE_USUARIO: objeto.TELEFONE_USUARIO,
            S_OBSERVACOES: objeto.S_OBSERVACOES,
            USUARIO_ATIVO: objeto.USUARIO_ATIVO,
            FLG_CADASTRO: objeto.FLG_CADASTRO,
            FAZENDAS: [
              {
                ID_PERMISSAO: objeto.ID_PERMISSAO,
                COD_CADASTRO: objeto.COD_CADASTRO,
                COD_FAZENDA: objeto.COD_FAZENDA,
                FAZENDA_ATIVA: objeto.FAZENDA_ATIVA,
                NOM_CADASTRO: objeto.NOM_CADASTRO,
                NUM_PR: objeto.NUM_PR,
                NOM_FAZE: objeto.NOM_FAZE,
                NOM_CIDADE: objeto.NOM_CIDADE,
                NOM_UF: objeto.NOM_UF,
              },
            ],
          });
        }
      }

      setData(novoArray);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [dono, userAuth, password, cnpjArmazem]);

  return (
    <VinculaContext.Provider
      value={{
        setStatusVinculaModal,
        statusVinculaModal,
        userId,
        setUserId,
        data,
        getData,
        loading,
      }}
    >
      {children}
    </VinculaContext.Provider>
  );
};
