import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  .content {
    margin-top: 8px;
    width: 100%;
    padding: 1rem;
    flex-direction: column;
    display: flex;
    gap: 0.5rem;
    overflow-y: auto;
  }

  .info {
    width: 100%;
    background-color: var(--blue-a3);
    flex-direction: column;
    display: flex;
    padding: 0.5rem 1.2rem;
    border-radius: 0.25rem;
    /* align-items: center; */
    .prod {
      font-weight: bold;
    }

    .fazendas {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.3rem;
      :nth-child(even) {
        background-color: var(--blue-a1);
      }

      .info-faz {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const Tag = styled.span`
  color: var(--blue-a1);
  width: 3rem;
  font-size: 12px;
  margin-right: 0.5rem;
  text-align: center;
  border-radius: 0.25rem;
  flex: 0 0 auto;
  background-color: ${(props) => (props.$active ? "green" : "red")};
`;
