import { FirebaseError } from '@firebase/util'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { auth } from '../services/firebase'

const UseAuthentication = () => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cancel, setCancel] = useState(false)

  function isCancel() {
    if (cancel) {
      return null
    }
  }

  function Logout() {
    isCancel()
    signOut(auth)
  }

  async function Login({ login, senha }) {
    isCancel()
    setLoading(true)
    setError(false)

    try {
      const res = await signInWithEmailAndPassword(auth, login, senha)
      return res
    } catch (err) {
      if (err instanceof FirebaseError) {
        console.error(err.message)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    return () => setCancel(true)
  }, [])

  return {
    error,
    loading,
    setLoading,
    Logout,
    Login,
  }
}

export default UseAuthentication
