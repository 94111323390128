import React from 'react'
import { Container } from './style'

import suporte from '../../assets/Icons Contato/suporte.svg'
import email from '../../assets/Icons Contato/email.svg'
import telefone from '../../assets/Icons Contato/telefone.svg'
import relogio from '../../assets/Icons Contato/relogio.svg'
import mapa from '../../assets/Icons Contato/mapa.svg'
import site from '../../assets/Icons Contato/site.svg'

const Contato = () => {
  return (
    <Container>
      <div className="contatoTitulo">
        <h2>Como ter acesso ao nosso Suporte</h2>
      </div>
      {/* <div className="contatoSuporte"> */}
      <div className="suporte">
        <img src={suporte} alt="" />
        <p>Suporte</p>
        <button>
          <a
            href="https://emsoft.com.br/suporte/"
            target="_blank"
            rel="noreferrer"
          >
            Acessar
          </a>
        </button>
      </div>
      <div className="email">
        <img src={email} alt="" />
        <p>E-mail</p>
        <a href="mailto:suporte@emsoft.com.br">suporte@emsoft.com.br</a>
      </div>
      <div className="telefone">
        <img src={telefone} alt="" />
        <p>Telefone</p>
        <div>
          <a href="tel:03535585991">(35)3558-5991</a>
          <a href="tel:03530420121">(35)3042-0121</a>
        </div>
      </div>
      {/* </div> */}
      {/* <div className="contatoInformacoes"> */}
      <div className="horario">
        <img src={relogio} alt="" />
        <p>Horário de Funcionamento</p>
        <div>
          <span>Seg a Sex</span>
          <span>08h as 18h</span>
        </div>
      </div>
      <div className="endereco">
        <img src={mapa} alt="" />
        <p>Endereço</p>
        <div>
          <span>Rua João Francisco Grilo n° 155</span>
          <span>1º Andar, Sala 5</span>
          <span>Jardim Mediterranêe</span>
          <span>São Sebastião do Paraíso MG</span>
        </div>
      </div>
      <div className="site">
        <img src={site} alt="" />
        <p>Nosso site</p>
        <button>
          <a href="https://www.emsoft.com.br/" target="_blank" rel="noreferrer">
            EMSOFT
          </a>
        </button>
      </div>
      {/* </div> */}
    </Container>
  )
}

export default Contato
