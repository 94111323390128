import styled from 'styled-components'

export const Header = styled.header`
  display: flex;
  background-color: var(--blue-a18);
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0.5rem;

  h2 {
    color: var(--blue-a1);
    margin: 0 auto;
  }

  button {
    background-color: transparent;
    outline: none;
    border: none;
    appearance: none;
    margin: 0 1rem;
  }
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  margin: 8px 4px;

  .infoTableModal {
    border: 2px solid var(--blue-a5);
    border-radius: 4px;
    padding: 2px;
    height: 265px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .cards {
      margin: 0 auto;
      canvas {
        width: 150px;
        height: 150px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      .info-blue::before {
        content: '';
        display: inline-flex;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        background-color: rgba(56, 58, 252);
      }
      .info-red::before {
        content: '';
        display: inline-flex;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        background-color: rgb(214, 47, 29);
      }

      .info-blue {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5px;
      }

      .info-red {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5px;
      }
    }
  }

  .graphicsPeneiraTableModal {
    border: 2px solid var(--blue-a5);
    border-radius: 4px;
    padding: 2px;
    grid-column: 1 / -1;
    height: 220px;
  }
`
