import React from "react";
import { Button } from "./style";
import { useContext } from "react";
import { AuthContext } from "../../../../../contexts/Auth/AuthContext";
import { getExtratoFisico, getExtratoProdutores } from "../../../../../services/apiPortalProdutor";
import { convertDate } from "../../Functions/convertDate";
import { getDataLote } from "../../../../../services/apiTestes";

const ButtonReturnData = ({
  relatorio,
  data,
  setError,
  setLoadingTable,
  setApi,
  setTotal,
  cpfCnpj,
  setMsg,
  codLote,
  dateRange,
}) => {
  const { dono, userAuth, password, codEmpresa, codFilial } = useContext(AuthContext);

  async function returnData() {
    setError(false);
    setLoadingTable(true);
    setApi([]);
    setTotal({ sacas: 0, peso: 0 });
    let total = { sacas: 0, peso: 0 };

    if (relatorio === 1) {
      try {
        const res = await getExtratoProdutores(dono, userAuth, password, codEmpresa, cpfCnpj, data);
        if (res.length > 0) {
          setApi(res);
          res.map((item) => {
            total.sacas += Number(item.VAL_SALDO_SACAS);
            total.peso += Number(item.VAL_SALDO_PESO);
            return null;
          });
          setTotal(total);
        } else {
          setMsg("Cliente não possui saldo no período digitado.");
        }
        setLoadingTable(false);
      } catch (err) {
        setMsg("Erro ao conectar com o Banco de Dados.");
        setError(true);
        setLoadingTable(false);
      }
    }

    if (relatorio === 2) {
      try {
        const res = await getExtratoFisico(dono, userAuth, password, codEmpresa, cpfCnpj);
        if (res.length > 0) {
          res.map((item) => {
            setApi(res);
            total.sacas += Number(item.NUM_SACAS);
            total.peso += Number(item.NUM_PESO_FISICO);
            return null;
          });
          setTotal(total);
        } else {
          setMsg("Cliente sem estoque.");
        }
        setLoadingTable(false);
      } catch (err) {
        setMsg("Erro ao conectar com o Banco de Dados.");
        setError(true);
        setLoadingTable(false);
      }
    }

    if (relatorio === 3) {
      try {
        // const { firstDate, lastDate } = convertDate(dateRange);
        const res = await getDataLote(dono, userAuth, password, codEmpresa, codFilial, codLote);
        setApi(res);
        setLoadingTable(false);
        // console.log(firstDate, lastDate);
      } catch (err) {
        console.log(err);
        setLoadingTable(false);
      }
    }

    if (relatorio === 4) {
      try {
        // const { firstDate, lastDate } = convertDate(dateRange);
        const res = await getExtratoProdutores(
          dono,
          userAuth,
          password,
          codEmpresa,
          "74551566691",
          "16/10/2023"
        );
        setApi(res);
        setLoadingTable(false);
        // console.log(firstDate, lastDate);
      } catch (err) {
        console.log(err);
        setLoadingTable(false);
      }
    }

    if (relatorio === 5) {
      const { firstDate, lastDate } = convertDate(dateRange);
      console.log(firstDate, lastDate);
    }
  }

  return <Button onClick={returnData}>Pesquisar</Button>;
};

export default ButtonReturnData;
