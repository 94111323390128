import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";

import { MovimEstoque } from "./data/Columns";
import { GlobalFilter } from "./data/GlobalFilter";
import { useReactToPrint } from "react-to-print";
import { Container } from "./style";
import { stylePrint } from "../../../../../utils/style";

import Loading from "../../../../../components/Layouts/Loading/Loading";
import { createEstoqueCafe } from "../Tables/functions";
import { testeDataAnalitico } from "./data/DATAFAKE";

export const TableEstoque = ({ dataTable, infoRelatorio }) => {
  const columns = useMemo(() => MovimEstoque, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [codProdutor, setCodProdutor] = useState([]);
  const [subHeader, setSubHeader] = useState([]);
  const [relevantSubHeaders, setRelevantSubHeaders] = useState([]);

  function contarElementos(arr) {
    let contador = 0;
    for (const item of arr) {
      if (item.columns && Array.isArray(item.columns)) {
        contador += contarElementos(item.columns);
      } else {
        contador++;
      }
    }
    return contador;
  }

  useEffect(() => {
    setLoading(false);
    if (dataTable) {
      if (infoRelatorio === 4) {
        const codCliente = createEstoqueCafe(testeDataAnalitico, "COD_PRODUTOR", "NOM_CLIENTE");
        const codLote = createEstoqueCafe(testeDataAnalitico, "COD_LOTE", "COD_PRODUTOR");
        setCodProdutor(codCliente);
        setSubHeader(codLote);
        setData(testeDataAnalitico);
      }
    }
  }, [dataTable, infoRelatorio]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const { globalFilter } = state;

  useEffect(() => {
    const filteredSubHeaders = subHeader.filter((subHeader) => {
      const subHeaderId = String(subHeader.id);
      for (const row of rows) {
        const subRowNum = String(row.original.COD_LOTE);
        if (subRowNum === subHeaderId) {
          return true;
        }
      }
      return false;
    });
    setRelevantSubHeaders(filteredSubHeaders);
  }, [globalFilter, rows, subHeader]);

  // print table
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "ARMAZÉM / ANÁLISE",
    pageStyle: stylePrint,
  });

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <Container>
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            handlePrint={handlePrint}
            data={dataTable}
          />

          <table {...getTableProps()} ref={componentRef} className="pagePrint">
            <thead className="sticky-header">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} key={index}>
                      {column.render("Header")}
                      <span>{column.isSorted ? (column.isSortedDesc ? " ↓" : " ↑") : " "}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {codProdutor.map((produtor, produtorIndex) => {
                const relevantSubHeadersForProdutor = relevantSubHeaders.filter(
                  (subHeader) => subHeader.subId === produtor.id
                );
                if (relevantSubHeadersForProdutor.length > 0) {
                  return (
                    <React.Fragment key={produtorIndex}>
                      <tr>
                        <td className="tdIr" colSpan={contarElementos(MovimEstoque)}>
                          {produtor.subId}
                        </td>
                      </tr>
                      {relevantSubHeadersForProdutor.map((subHeader, subIndex) => {
                        return (
                          <React.Fragment key={subIndex}>
                            <tr>
                              <td className="tdIr" colSpan={contarElementos(MovimEstoque)}>
                                {subHeader.id}
                              </td>
                            </tr>
                            {rows.map((row, index) => {
                              let subH = String(subHeader.id);
                              let subR = String(row.original.COD_LOTE);
                              if (subH === subR) {
                                prepareRow(row);
                                return (
                                  <tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                      return (
                                        <td {...cell.getCellProps()} key={index}>
                                          {cell.render("Cell")}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              } else {
                                return "";
                              }
                            })}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        </Container>
      )}
    </>
  );
};
