import { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { Container } from "./style";
import CalendarPicker from "../../../DataRangePicker/Date";
import iconFechar from "../../../../assets/Icons-Modal/icon-closed-a18.svg";
import { responsiveModal } from "../Modal";
import moment from "moment";

// Modal usado no calendário
// => Usado no Dashboard / Armazém

export default function DateModal({
  handleShowModalCalendar,
  handleRequestCloseCalendar,
  hiddenCalendar,
  setDate,
}) {
  const [firstEntrada, setFirstEntrada] = useState();
  const [lastEntrada, setLastEntrada] = useState();
  const [firstSaida, setFirstSaida] = useState();
  const [lastSaida, setLastSaida] = useState();
  const [firstPreparo, setFirstPreparo] = useState();
  const [lastPreparo, setLastPreparo] = useState();

  // função para recuperar os dados da data (value)
  function handleDatePicker(date, type) {
    //
    if (!date || date.length < 2) return;

    const [startDate, endDate] = date;

    const firstDate = startDate ? moment(startDate).format("DD/MM/YYYY") : null;
    const lastDate = endDate ? moment(endDate).format("DD/MM/YYYY") : null;

    // estrutura para setar as datas conforme o parametro type
    switch (type) {
      case "entrada":
        setFirstEntrada(firstDate);
        setLastEntrada(lastDate);
        break;

      case "saida":
        setFirstSaida(firstDate);
        setLastSaida(lastDate);
        break;

      case "preparo":
        setFirstPreparo(firstDate);
        setLastPreparo(lastDate);
        break;

      default:
        break;
    }
  }

  // função para retornar as datas digitadas pelo usuário ao clicar botão Alterar ou apertar Enter
  function buttonReturnDate() {
    const returnDates = {
      firstEntrada,
      lastEntrada,
      firstSaida,
      lastSaida,
      firstPreparo,
      lastPreparo,
    };
    handleRequestCloseCalendar();
    setDate(returnDates);
  }

  // função para detectar quando o Enter for apertado na tela do calendário
  const keyEnterCalendar = (event) => {
    if (event.key === "Enter") {
      buttonReturnDate();
    }
  };

  // evento para pegar a tecla que for digitada
  useEffect(() => {
    window.addEventListener("keydown", keyEnterCalendar);
  }, []);

  return (
    <>
      <ReactModal
        className="modal"
        isOpen={handleShowModalCalendar}
        onRequestClose={handleRequestCloseCalendar}
        ariaHideApp={false}
        style={{
          overlay: {
            display: hiddenCalendar === true ? "block" : "none",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
          content: {
            display: hiddenCalendar === true ? "block" : "none",
            marginLeft: responsiveModal(400, "5px", "auto"),
            left: "20px",
            height: responsiveModal(400, "21rem", "25rem"),
            width: responsiveModal(400, "20rem", "27rem"),
            marginInlineEnd: "16.5rem",
            marginBlock: "6.8rem",
            background: "var(--blue-a1)",
            borderRadius: "0.25rem",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "0",
          },
        }}
      >
        <Container>
          <div className="date-modal">
            <button onClick={handleRequestCloseCalendar}>
              <img src={iconFechar} alt="" className="icon-fechar" />
            </button>

            <span className="span-entrada">
              Entrada
              <div className="calendar">
                <CalendarPicker handleDatePicker={handleDatePicker} type="entrada" />
              </div>
            </span>
            <span className="span-saida">
              Saída
              <div className="calendar">
                <CalendarPicker handleDatePicker={handleDatePicker} type="saida" />
              </div>
            </span>
            <span className="span-preparo">
              Preparo
              <div className="calendar">
                <CalendarPicker handleDatePicker={handleDatePicker} type="preparo" />
              </div>
            </span>
          </div>
          <div className="button-calendar">
            <button
              className="button-alterar"
              onClick={buttonReturnDate}
              onKeyDown={keyEnterCalendar}
            >
              Alterar
            </button>
          </div>
        </Container>
      </ReactModal>
    </>
  );
}
