import React from "react";
import SelectFilter from "../../../../components/Layouts/SelectFilter/SelectFilter";
import DatePickerCalendar from "../../../../components/DatePickerCalendar/DatePickerCalendar";

const RtExtratoCafe = ({ produtores, handleSelectProdutor, handleDatePicker }) => {
  return (
    <>
      <SelectFilter options={produtores} onChange={handleSelectProdutor} />
      <DatePickerCalendar handleDateChange={handleDatePicker} type={"dataAnalise"} />
    </>
  );
};

export default RtExtratoCafe;
