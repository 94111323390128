import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  gap: 10px;
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  flex: 0 0 auto;
  width: 50px;
  height: 24px;
  margin-top: 10px;
  margin-right: 10px;

  .inputCheckBox {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    border-radius: 34px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;

    ::before {
      position: absolute;
      content: "";
      height: 22px;
      width: 22px;
      left: 1px;
      bottom: 1px;
      border-radius: 50%;
      background-color: white;
      transition: 0.4s;
    }
  }

  .inputCheckBox:checked + .slider {
    background-color: #2196f3;
  }

  .inputCheckBox:checked + .slider::before {
    transform: translateX(26px);
  }
`;

export const CheckboxLabel = styled.span`
  display: block;
  word-wrap: break-word;
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 1rem;
  margin: 5px 14px 0px 14px;
`;
