import { useState, useContext, useEffect } from 'react'

import { Container } from './style'

import { SwiperSlide } from 'swiper/react'

import { AuthContext } from '../../contexts/Auth/AuthContext'

import { getContasAberto } from '../../services/apiFinanceiro'

import moment from 'moment/moment'

// Importação de Componentes
import { Card, CardTotal } from '../../components/Cards/CardFin/CardFin'
import Modal from '../../components/Modal/ModalFin/Modal'
import Chart from '../../components/Charts/ChartFin/Chart'
import ListResult from '../../components/ListResult/ListResult'
import Slider from '../../components/Slider/Slider'
import Loading from '../../components/Layouts/Loading/Loading'
import { convertBRL } from '../../utils/convertBRL'

// importação dos icones
import pagarIcon from '../../assets/Icons Box/icon-pagar.svg'
import receberIcon from '../../assets/Icons Box/icon-receber.svg'
import bancoIcons from '../../assets/Icons Box/icon-banco.svg'

export default function DashboardFin() {
  const { dono, userAuth, password, codEmpresa } = useContext(AuthContext)
  const [statusModal, setStatusModal] = useState(false)
  const [infoModal, setInfoModal] = useState({})
  const [api, setApi] = useState({
    contaPagar: [],
    contaReceber: [],
    saldoBanco: [],
  })
  const [loading, setLoading] = useState(true)

  // variaveis para comparação das datas
  const dateToday = new Date()
  const dateWeek = moment().add(8, 'days')
  const dateMonth = moment().add(31, 'days')

  // variaveis usadas para o title das datas
  const dateTodayTitle = moment().add(1, 'days')
  const dateWeekTitle = moment().add(7, 'days')
  const dateMonthTitle = moment().add(30, 'days')

  const dates = {
    amanha: dateTodayTitle._d.toLocaleDateString(),
    semana: dateWeekTitle._d.toLocaleDateString(),
    mes: dateMonthTitle._d.toLocaleDateString(),
  }

  // ANCHOR Contas a Pagar
  const totalPagar = {
    dia: 0,
    semana: 0,
    mes: 0,
  }

  const dataPeriodoContasPagar = {
    dia: [],
    semana: [],
    mes: [],
  }

  const dataPeriodoContasReceber = {
    dia: [],
    semana: [],
    mes: [],
  }

  // const listaDia = [];
  // const listaSemana = [];
  // const listaMes = [];

  if (api.contaPagar) {
    api.contaPagar.forEach((item) => {
      const dateDuplicata = new Date(item.DAT_VENC)

      if (moment(dateDuplicata).isSame(dateToday, 'day')) {
        totalPagar.dia += item.VAL_PRINCIPAL
        dataPeriodoContasPagar.dia.push(item)
        // listaDia.push(item);
      }
      if (
        moment(dateDuplicata).isAfter(dateToday, 'day') &&
        moment(dateDuplicata).isBefore(dateWeek, 'day')
      ) {
        totalPagar.semana += item.VAL_PRINCIPAL
        dataPeriodoContasPagar.semana.push(item)
        // listaSemana.push(item);
      }
      if (
        moment(dateDuplicata).isAfter(dateToday, 'day') &&
        moment(dateDuplicata).isBefore(dateMonth, 'day')
      ) {
        totalPagar.mes += item.VAL_PRINCIPAL
        dataPeriodoContasPagar.mes.push(item)
        // listaMes.push(item);
      }
    })
  }

  // ANCHOR Contas a Receber
  const totalReceber = {
    dia: 0,
    semana: 0,
    mes: 0,
  }

  if (api.contaReceber) {
    api.contaReceber.forEach((item) => {
      const dateDuplicata = new Date(item.DAT_VENC)
      if (moment(dateDuplicata).isSame(dateToday, 'day')) {
        totalReceber.dia += item.VAL_PRINCIPAL
        dataPeriodoContasReceber.dia.push(item)
      }
      if (
        moment(dateDuplicata).isAfter(dateToday, 'day') &&
        moment(dateDuplicata).isBefore(dateWeek, 'day')
      ) {
        totalReceber.semana += item.VAL_PRINCIPAL
        dataPeriodoContasReceber.semana.push(item)
      }
      if (
        moment(dateDuplicata).isAfter(dateToday, 'day') &&
        moment(dateDuplicata).isBefore(dateMonth, 'day')
      ) {
        totalReceber.mes += item.VAL_PRINCIPAL
        dataPeriodoContasReceber.mes.push(item)
      }
    })
  }

  // ANCHOR Saldo do Banco
  let totalBanco = 0
  if (api.saldoBanco) {
    api.saldoBanco.forEach((item) => {
      totalBanco += item.SALDO
    })
  }

  useEffect(() => {
    getContasAberto(dono, userAuth, password, codEmpresa).then((res) => {
      setApi(res)
      setLoading(false)
    })
  }, [dono, userAuth, password, codEmpresa])

  // Função que altera o status do modal para True ou  False
  const handleShowModal = () => {
    setStatusModal(!statusModal)
  }

  // Função que fecha o modal ao apertar ESC ou clicando em sobreposição
  const handleRequestCloseFunc = () => {
    setStatusModal(false)
  }

  // Função que envia dados para o Modal
  const handleInfoModal = (title, color, infoApi) => {
    setInfoModal({
      title,
      color,
      infoApi,
    })
  }

  // Configuração do slide
  const settings = {
    spaceBetween: 30,
    slidesPerView: 1,
    // navigation: true,
    pagination: {
      clickable: true,
      renderBullet: function (index, className) {
        const moment = ['Hoje', '7 Dias', '30 Dias']
        return '<span class="' + className + '">' + moment[index] + '</span>'
      },
    },
    loop: true,
  }

  return (
    <>
      <Container>
        <Modal
          statusModalActual={statusModal}
          handleRequestCloseFunc={handleRequestCloseFunc}
          infoModal={infoModal}
        />

        <div className="main_cards">
          <Card
            card="card_pagar"
            icon={pagarIcon}
            valueDia={convertBRL(totalPagar.dia)}
            valueSemana={convertBRL(totalPagar.semana)}
            valueMes={convertBRL(totalPagar.mes)}
            dataPeriodoDia={dataPeriodoContasPagar.dia}
            dataPeriodoSemana={dataPeriodoContasPagar.semana}
            dataPeriodoMes={dataPeriodoContasPagar.mes}
            textDia="HOJE"
            textSemana="PRÓXIMOS 7 DIAS"
            textMes="PRÓXIMOS 30 DIAS"
            title="CONTAS A PAGAR"
            card_button="button_pagar"
            handleShowModal={handleShowModal}
            handleInfoModal={handleInfoModal}
            color={'#6B0009'}
            contas={api.contaPagar}
            loading={loading}
            dates={dates}
          />
          <Card
            card="card_receber"
            icon={receberIcon}
            valueDia={convertBRL(totalReceber.dia)}
            valueSemana={convertBRL(totalReceber.semana)}
            valueMes={convertBRL(totalReceber.mes)}
            dataPeriodoDia={dataPeriodoContasReceber.dia}
            dataPeriodoSemana={dataPeriodoContasReceber.semana}
            dataPeriodoMes={dataPeriodoContasReceber.mes}
            textDia="HOJE"
            textSemana="PRÓXIMOS 7 DIAS"
            textMes="PRÓXIMOS 30 DIAS"
            title="CONTAS A RECEBER"
            card_button="button_receber"
            handleShowModal={handleShowModal}
            handleInfoModal={handleInfoModal}
            color={'#00751F'}
            contas={api.contaReceber}
            loading={loading}
            dates={dates}
          />
          <CardTotal
            card="card_bancos"
            icon={bancoIcons}
            valueTotal={convertBRL(totalBanco)}
            textTotal=" SALDO TOTAL"
            title="BANCOS"
            card_button="button_bancos"
            handleShowModal={handleShowModal}
            handleInfoModal={handleInfoModal}
            color={'#030F14'}
            contas={api.saldoBanco}
            loading={loading}
          />
        </div>

        {loading && <Loading />}
        {!loading && (
          <Slider settings={settings}>
            <SwiperSlide>
              <div className="main_information">
                <div className="information_graphics">
                  <Chart
                    saldoBancos={totalBanco}
                    saldoReceber={totalReceber.dia}
                    saldoPagar={totalPagar.dia}
                  />
                </div>

                <div className="information_analises">
                  <ListResult
                    saldoBancos={totalBanco}
                    saldoReceber={totalReceber.dia}
                    saldoPagar={totalPagar.dia}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main_information">
                <div className="information_graphics">
                  <Chart
                    saldoBancos={totalBanco}
                    saldoReceber={totalReceber.semana}
                    saldoPagar={totalPagar.semana}
                  />
                </div>

                <div className="information_analises">
                  <ListResult
                    saldoBancos={totalBanco}
                    saldoReceber={totalReceber.semana}
                    saldoPagar={totalPagar.semana}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main_information">
                <div className="information_graphics">
                  <Chart
                    saldoBancos={totalBanco}
                    saldoReceber={totalReceber.mes}
                    saldoPagar={totalPagar.mes}
                  />
                </div>

                <div className="information_analises">
                  <ListResult
                    saldoBancos={totalBanco}
                    saldoReceber={totalReceber.mes}
                    saldoPagar={totalPagar.mes}
                  />
                </div>
              </div>
            </SwiperSlide>
          </Slider>
        )}
      </Container>
    </>
  )
}
