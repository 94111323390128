import { limitCaracter } from '../../../../utils/style'
import ButtonTable from '../../../Layouts/ButtonTable/ButtonTable'

let controleProcessosFin

export const ProcessosFinalizados = [
  {
    Header: 'Controle',
    accessor: 'FLG_MOVIMENTO',
    Cell: ({ cell: { value } }) => {
      controleProcessosFin = value
      if (value === 'P') {
        return (value = 'Preparo')
      } else if (value === 'R') {
        return (value = 'Liga')
      } else if (value === 'S') {
        return (value = 'Saida')
      } else if (value === 'E') {
        return (value = 'Entrada')
      }
    },
  },
  {
    Header: 'Nome',
    accessor: 'NOM_CLIENTE',
    Cell: ({ cell: { value } }) => <p style={limitCaracter()}>{value}</p>,
  },
  {
    Header: 'Previsão',
    accessor: 'DAT_PREVISAO',
  },
  {
    Header: 'Efetivo',
    accessor: 'DAT_EFETIVA',
  },
  {
    Header: 'Embalagem',
    accessor: 'NOM_MODALIDADE',
  },
  {
    Header: 'Quantidade',
    accessor: 'NUM_SACAS',
  },
  {
    Header: 'Documento',
    accessor: 'NUM_DOCUMENTO',
    Cell: ({ cell: { value } }) => {
      if (controleProcessosFin === 'P') {
        return <ButtonTable>{value}</ButtonTable>
      } else if (controleProcessosFin === 'R') {
        return <ButtonTable>{value}</ButtonTable>
      } else {
        return value
      }
    },
  },
]
