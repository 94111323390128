import { useGetProdutor } from "../../../../hooks/useGetProdutor";
import { useState } from "react";
import Warning from "../../../../components/Layouts/Warning/Warning";
import Loading from "../../../../components/Layouts/Loading/Loading";
import ProdutorIsExist from "../ProdutorIsExist/ProdutorIsExist";
import FormRegisterProdutor from "../FormResgisterProdutor/FormRegisterProdutor";
import * as S from "./style";
import ModalPortal from "../../../../components/Modal/ModalPortal/ModalPortal";
import ListarProdutores from "../../ListarProdutores/ListarProdutores";

const FormSearchProdutor = () => {
  const {
    error,
    messageError,
    loading,
    valueCpf,
    dataProdutor,
    handleInput,
    handleSubmit,
    getProdutorFirebase,
    setError,
  } = useGetProdutor();
  const [produtorIsExist, setProdutorIsExist] = useState(false);
  const [dbProdutor, setDbProdutor] = useState(null);
  const [statusModal, setStatusModal] = useState(false);

  // Função que altera o status do modal para True ou  False
  const handleShowModal = () => {
    setStatusModal(!statusModal);
  };

  // Função que fecha o modal ao apertar ESC ou clicando em sobreposição
  const handleRequestCloseFunc = () => {
    setStatusModal(false);
  };

  const handleRegister = async () => {
    // Verificar se o produtor existe cadastrado no firebase
    const { produtorExist, dataProdutorFirebase } = await getProdutorFirebase();
    setProdutorIsExist(produtorExist);
    if (produtorExist) {
      setDbProdutor(dataProdutorFirebase);
    }
    handleShowModal();
  };

  return (
    <>
      <ModalPortal statusModalActual={statusModal} handleRequestCloseFunc={handleRequestCloseFunc}>
        {produtorIsExist && (
          <ProdutorIsExist
            dbProdutor={dbProdutor}
            handleRequestCloseFunc={handleRequestCloseFunc}
          />
        )}
        {!produtorIsExist && (
          <FormRegisterProdutor
            handleRequestCloseFunc={handleRequestCloseFunc}
            dataProdutor={dataProdutor}
          />
        )}
      </ModalPortal>
      <S.Container>
        <S.DisplayCadastrar>
          <h1>Cadastro de Usuários</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="cpf_cnpj">CPF ou CNPJ</label>
            <input
              autoFocus
              type="text"
              id="cpf_cnpj"
              placeholder="Digite o CPF ou CNPJ"
              value={valueCpf}
              onChange={handleInput}
            />
            <S.Buttons type="submit" disabled={valueCpf.length <= 0}>
              Buscar
            </S.Buttons>
          </form>
          <div className="showAction">
            {dataProdutor && !error && (
              <>
                <h3>{dataProdutor.nome}</h3>
                <S.Buttons onClick={handleRegister}>Cadastrar</S.Buttons>
              </>
            )}
            {error && !messageError.includes("encontrado") && <Warning label={messageError} />}
            {error && messageError.includes("encontrado") && (
              <div>
                <h3>{messageError}</h3>
                <S.Buttons $cancel onClick={() => setError(null)}>
                  Desistir
                </S.Buttons>
                <S.Buttons onClick={handleRegister}>Cadastrar</S.Buttons>
              </div>
            )}
            {loading && <Loading />}
          </div>
        </S.DisplayCadastrar>
        <ListarProdutores />
      </S.Container>
    </>
  );
};

export default FormSearchProdutor;
