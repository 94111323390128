import React, { useState, useContext } from 'react'

import { AuthContext } from '../../../contexts/Auth/AuthContext'
import { FormLogin } from './style'

import Button from '../../../components/Layouts/Button/Button'
import Loading from '../../../components/Layouts/Loading/Loading'

const LoginForm = () => {
  const { signin } = useContext(AuthContext)
  const [formValuesLogin, setformValuesLogin] = useState({})
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState('')

  // ANCHOR função que controla o estado dos inputs de login
  const handleInputChangeLogin = (e) => {
    setMessageError(false)
    const { name, value } = e.target
    setformValuesLogin({ ...formValuesLogin, [name]: value })
  }

  // ANCHOR função que que recebe os dados do formulário que estiver sendo enviado no momento
  const handleSubmit = async (e) => {
    e.preventDefault()
    setMessageError(false)

    const formData = new FormData(e.target)
    const { user, password } = Object.fromEntries(formData)

    if (user && password) {
      setLoading(true)
      const { codeError } = await signin(user, password)

      if (codeError) {
        setMessageError(codeError)
        setLoading(false)
      }
      setformValuesLogin('')
    }
  }

  return (
    <FormLogin onSubmit={handleSubmit}>
      <div className="login_label-float">
        <input
          type="text"
          id="user"
          name="user"
          required
          autoFocus
          value={formValuesLogin.user || ''}
          onChange={handleInputChangeLogin}
        />
        <label htmlFor="user">Usuário</label>
      </div>
      <div className="login_label-float">
        <input
          type="password"
          id="password"
          name="password"
          required
          value={formValuesLogin.password || ''}
          onChange={handleInputChangeLogin}
        />
        <label htmlFor="password">Senha</label>
      </div>
      {messageError && (
        <span>
          {messageError === 'ERR_BAD_RESPONSE'
            ? 'Usuário ou senha incorretos'
            : 'Erro ao conectar com o servidor!'}
        </span>
      )}
      <div className="login_justify-center">
        {loading ? (
          <Loading />
        ) : (
          <Button id="button" type="submit">
            Entrar
          </Button>
        )}
      </div>
    </FormLogin>
  )
}

export default LoginForm
