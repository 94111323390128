export function getUserId(array) {
  let valorAposPipe = null;

  for (const chave in array[0]) {
    if (array[0].hasOwnProperty(chave) && chave.includes("|")) {
      valorAposPipe = array[0][chave].split("|")[1];
      break; // Se encontrou o valor, saia do loop
    }
  }
  return valorAposPipe;
}
