import styled from "styled-components";

export const Container = styled.div`
  background: var(--blue-a3);
  padding: 0 1rem;
  grid-area: main;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  overflow-y: auto;
`;
