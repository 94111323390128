import { useState } from "react";
import ReactModal from "react-modal";
import { Container } from "./style";
import iconFechar from "../../../../assets/Icons-Modal/icon-closed-a18.svg";
import CalendarPicker from "../../../DataRangePicker/Date";
import { responsiveModal } from "../Modal";
import moment from "moment/moment";

// Modal usado no calendário
// => Usado no Dashboard / Armazém

export default function DateModalAnalise({
  handleShowModalCalendar,
  handleRequestCloseCalendar,
  hiddenCalendar,
  setDate,
}) {
  const [dataAnaliseInicio, setDataAnaliseInicio] = useState();
  const [dataAnaliseFinal, setDataAnaliseFinal] = useState();

  // função para recuperar os dados da data (value)
  function handleDatePicker(date) {
    if (!date || date.length < 2) return;

    const [startDate, endDate] = date;

    const firstDate = startDate ? moment(startDate).format("DD/MM/YYYY") : null;
    const lastDate = endDate ? moment(endDate).format("DD/MM/YYYY") : null;

    setDataAnaliseInicio(firstDate);
    setDataAnaliseFinal(lastDate);
  }

  // função para retornar as datas digitadas pelo usuário ao clicar botão Alterar ou apertar Enter
  function buttonReturnDate() {
    if ((!dataAnaliseInicio && dataAnaliseFinal) || (dataAnaliseInicio && !dataAnaliseFinal)) {
      alert("Preencha a data corretamente");
    } else {
      const returnDates = {
        dataAnaliseInicio,
        dataAnaliseFinal,
      };

      setDate(returnDates);
      handleRequestCloseCalendar();
    }
  }

  return (
    <>
      <ReactModal
        className="modal"
        isOpen={handleShowModalCalendar}
        onRequestClose={handleRequestCloseCalendar}
        ariaHideApp={false}
        style={{
          overlay: {
            display: hiddenCalendar === true ? "block" : "none",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
          content: {
            display: hiddenCalendar === true ? "block" : "none",
            marginLeft: responsiveModal(400, "auto", "auto"),
            height: responsiveModal(400, "12rem", "12rem"),
            width: responsiveModal(400, "20rem", "27rem"),
            marginInlineEnd: "auto",
            marginBlock: "center",
            background: "var(--blue-a1)",
            borderRadius: "0.25rem",
            WebkitOverflowScrolling: "touch",
            padding: "0",
          },
        }}
      >
        <Container>
          <div className="date-modal">
            <button onClick={handleRequestCloseCalendar}>
              <img src={iconFechar} alt="" className="icon-fechar" />
            </button>

            <span className="span-entrada">
              Data
              <div className="calendar">
                <CalendarPicker handleDatePicker={handleDatePicker} type="dataAnalise" />
              </div>
            </span>
          </div>
          <div className="button-calendar">
            <button className="button-alterar" onClick={buttonReturnDate}>
              Alterar
            </button>
          </div>
        </Container>
      </ReactModal>
    </>
  );
}
