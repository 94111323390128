import AtualizarAvisos from "../../../../../Modules/AppProdutor/AtualizarAvisos/AtualizarAvisos";
import { ReactComponent as Delete } from "../../../../../assets/Icons-Portal/delete.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/Icons-Portal/edit.svg";
import moment from "moment";

// ANCHOR Cabeçalho da tabela

export const COLUMNS = [
  {
    Header: "Conteúdo",
    accessor: "S_NOTICIA",
  },
  {
    Header: "Data Início",
    accessor: "D_INICIO",
    Cell: ({ cell: { value } }) =>
      moment(new Date(value)).format("DD/MM/YYYY hh:mm"),
  },
  {
    Header: "Data Final",
    accessor: "D_FINAL",
    Cell: ({ cell: { value } }) =>
      moment(new Date(value)).format("DD/MM/YYYY hh:mm"),
  },
  {
    Header: "Ações",
    accessor: "cpf",
    Cell: ({ cell: { row } }) => {
      const { original } = row;
      return (
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <AtualizarAvisos
            title="Editar Aviso"
            actions={{ action: "edit", data: original }}
          >
            <EditIcon />
          </AtualizarAvisos>
          <AtualizarAvisos
            title="Deletar Aviso"
            actions={{ action: "disable", data: original }}
          >
            <Delete />
          </AtualizarAvisos>
        </div>
      );
    },
  },
];
