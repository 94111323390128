import { useState } from 'react'
import ReactModal from 'react-modal'
import iconClosed from '../../../assets/Icons-Modal/icon-closed-a18.svg'
import LotesTree from '../../LotesTree/LotesTree'
import * as S from './styles'

// import { Container, Header } from "./style";
// import Loading from "../../../Layouts/Loading/Loading";

// Modal usado no Dashboard / Armazém
// => É o modal usado para renderizar os gráficos e cards de Entrada, Saída, Preparo e Reensaque

export function responsiveModal(valueWidth, valueReturnTrue, valueReturnFalse) {
  if (window.innerWidth < valueWidth) {
    return valueReturnTrue
  }
  return valueReturnFalse
}

export default function LotesModal({
  statusModalActual,
  onRequestClose,
  tree,
  setViewLoteInfo,
  lote,
}) {
  const [renderTree, setRenderTree] = useState('Descendente')

  function handleCloseModalAndClearLoteInfo() {
    setViewLoteInfo(false)
    onRequestClose()
  }

  return (
    <>
      <ReactModal
        isOpen={statusModalActual}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
          content: {
            position: 'absolute',
            top: '5%',
            left: responsiveModal(650, '2%', '6%'),
            right: responsiveModal(650, '2%', '6%'),
            bottom: '5%',
            border: 'none',
            background: 'var(--blue-a1)',
            borderRadius: '8px',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: '0',
            maxWidth: '100%',
          },
        }}
        ariaHideApp={false}
      >
        <S.Header>
          <h2>LOTE: {lote}</h2>
          <div className="buttonsTree">
            <button
              onClick={() => setRenderTree('Ascendente')}
              id={renderTree === 'Ascendente' ? 'checked' : ''}
            >
              Ascendente
            </button>
            <button
              onClick={() => setRenderTree('Descendente')}
              id={renderTree === 'Descendente' ? 'checked' : ''}
            >
              Descendente
            </button>
          </div>
          <button onClick={handleCloseModalAndClearLoteInfo}>
            <img src={iconClosed} alt="Imagem de fechar" />
          </button>
        </S.Header>
        <S.Tree>
          <LotesTree tree={tree} renderTree={renderTree} />
        </S.Tree>
      </ReactModal>
    </>
  )
}
