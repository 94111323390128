import { Doughnut, Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  TimeScale,
  Title,
} from "chart.js";
import Warning from "../../../Layouts/Warning/Warning";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  TimeScale,
  ChartDataLabels,
  Title
);

// Chart usado no Dashboard / Armazém
// => É usado no Modal dos Cards

export function ChartModalProcFin({ embalagens, sacasModalidade, titleTooltip }) {
  const data = {
    labels: embalagens,
    datasets: [
      {
        data: sacasModalidade,
        backgroundColor: ["rgb(214, 47, 29)", "rgb(56, 58, 252)"],
        borderWidth: 1,
        radius: "90%",
        cutout: "50%",
        hoverOffset: "10",
      },
    ],
  };

  // variável que retornar o elemento no array de preparo
  const item = sacasModalidade.find((element) => element > 1);

  if (!item || sacasModalidade.length === 0) {
    return <Warning label="Não há movimentações neste período." />;
  }

  return (
    <Doughnut
      data={data}
      title={titleTooltip}
      options={{
        plugins: {
          title: {
            display: true,
            text: titleTooltip,
            color: "white",
          },
          datalabels: {
            display: false,
          },
        },
      }}
    />
  );
}

export function ChartModalProcFinBar({ datasetGraphic, height, width, titleTooltip, responsive }) {
  const data = { labels: [], datasets: [] };

  return (
    <>
      <Chart
        height={height}
        width={width}
        title={titleTooltip}
        data={datasetGraphic || data}
        options={{
          responsive: !(responsive <= 1500),
          plugins: {
            title: {
              display: true,
              text: titleTooltip,
              color: "white",
            },
            legend: {
              display: false,
              position: "bottom",
              labels: {},
            },
            datalabels: {
              formatter: (value) => {
                return value + "%";
              },
              color: "#fff",
              font: {
                weight: "bold",
              },
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.formattedValue + "%";
                },
              },
            },
          },
        }}
      />
    </>
  );
}
