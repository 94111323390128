import styled from "styled-components";

export const Container = styled.button`
  width: 230px;
  background-color: var(--blue-a18);
  color: #fff;
  padding: 8px;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: all 0.4s ease-out;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  :hover {
    background-color: var(--blue-a13);
    color: var(--gray-c3);
  }
`;
