export function cnpjMask(cnpj) {
  return cnpj
    .replace(/\D+/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}

export function cnpjClear(cnpj) {
  return cnpj.replace(/\D/g, "");
}

export const cpfCnpjMask = (v) => {
  v = v.replace(/\D/g, "");

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    v = cnpjMask(v);
  }

  return v;
};

export function phoneMask(phone) {
  return phone
    .replace(/\D+/g, "")
    .replace(/(\d{2})(\d{1,5})/, "($1) $2")
    .replace(/(\d{5})(\d{1,4})/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, "$1");
}

export function phoneClear(phone) {
  return phone.replace(/\D/g, "");
}
