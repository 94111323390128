import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
  color: var(--gray-c4);
  padding: 20px;
  border-radius: 8px;
  background-color: rgba(300, 0, 0, 0.2);
  border: 1px solid rgba(300, 0, 0, 0.4);
`;
