import React, { useState, useContext } from 'react'
import SelectGlobal from '../../../components/Layouts/Select/Select'
import { MessageEmpresa, FormEmpresa, customStylesSelectLogin } from './style'
import Button from '../../../components/Layouts/Button/Button'
import { AuthContext } from '../../../contexts/Auth/AuthContext'

const EmpresaForm = () => {
  const { apiValidaUser, setStateLogin } = useContext(AuthContext)

  const [value, setValue] = useState('')
  const [showButton, setShowButton] = useState('')
  const [filial, setFilial] = useState([{}])
  const [filialSelecionada, setFilialSelecionada] = useState()

  const selectEmpresa = apiValidaUser.reduce((acc, item, index, array) => {
    if (index === 0) {
      acc.push({
        value: item.NOM_EMPRESA_TELA,
        label: item.NOM_EMPRESA_TELA,
        id: item.COD_EMPRESA,
      })
    } else {
      if (array[index].COD_EMPRESA !== array[index - 1].COD_EMPRESA) {
        acc.push({
          value: item.NOM_EMPRESA_TELA,
          label: item.NOM_EMPRESA_TELA,
          id: item.COD_EMPRESA,
        })
      }
    }
    return acc
  }, [])

  function handleEmpresa(empresa) {
    if (empresa) {
      const filterEmpresa = apiValidaUser.reduce((acc, item) => {
        if (item.COD_EMPRESA === empresa.id) {
          acc.push({
            ...item,
            value: item.NOM_FILIAL,
            label: item.NOM_FILIAL,
          })
        }
        return acc
      }, [])
      setValue(true)
      setFilial(filterEmpresa)
    }
  }

  function handleFilial(filial) {
    setFilialSelecionada(filial)
    setShowButton(true)
  }

  return (
    <>
      <MessageEmpresa>
        {!value && <p>Selecione a Empresa</p>}
        {value && !showButton ? <p>Selecione a Filial</p> : <p></p>}
      </MessageEmpresa>
      <FormEmpresa>
        <div className="select">
          <SelectGlobal
            options={selectEmpresa}
            styles={customStylesSelectLogin}
            onChange={handleEmpresa}
          />
          {value && (
            <>
              <SelectGlobal
                options={filial}
                styles={customStylesSelectLogin}
                onChange={handleFilial}
                // value={""}
              />
            </>
          )}
        </div>

        {showButton && (
          <div className="button">
            <Button onClick={() => setStateLogin(filialSelecionada)}>
              Entrar
            </Button>
          </div>
        )}
      </FormEmpresa>
    </>
  )
}

export default EmpresaForm
