import styled from "styled-components";

export const Container = styled.div`
  grid-area: 1 / 1 / 3 / 5;
  background-color: var(--blue-a18);
  color: white;
  display: grid;
  grid-template-columns: 2fr 5fr 2fr;
  grid-template-rows: 1fr 1fr 10fr 1fr;

  h1 {
    grid-column: 2/3;
    grid-row: 2;
    text-align: center;
  }

  .buttonReturn {
    grid-row: 4;
    margin: 0 0 20px 20px;
    width: 50px;
  }

  .cards_home {
    grid-column: 2/2;
    grid-row: 3/4;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;

    div {
      height: 7rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      background-color: blue;
    }

    .card_home {
      background-color: var(--blue-a16);
      border-radius: 4px;
      transition: all ease 0.5s;

      .icon_container {
        width: 10rem;
        background-color: var(--blue-a14);
        margin: 0;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .menu_img {
        opacity: 0.8;
        :hover {
          opacity: 1;
        }
      }

      span {
        width: 100%;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 600;
      }

      :hover {
        background-color: var(--blue-a14);
      }
    }
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    overflow-x: hidden;
    h1 {
      padding-top: 30px;
      font-size: 1.5rem;
    }
    .cards_home div {
      /* width: 20rem; */
      height: 6rem;
    }
    .cards_home:last-child {
      padding-bottom: 20px;
    }
    .icon_container img {
      /* width: 3.5rem; */
    }
    .buttonReturn {
      grid-column: 2/3;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .suporte {
      display: none;
    }
  }

  @media only screen and (max-width: 360px) {
    .cards_home div {
      /* width: 18rem; */
      height: 6rem;
    }
  }
`;
