import React, { useEffect, useContext } from "react";
import * as S from "./style";
import { TableListarAvisos } from "../../../components/Tables/TablesAppProdutor/TableListarAvisos/TableListarAvisos";
import Loading from "../../../components/Layouts/Loading/Loading";
import Warning from "../../../components/Layouts/Warning/Warning";
import { AvisosContext } from "../../../contexts/Avisos/AvisosContext";

const ListarAvisos = () => {
  const { avisos, error, loading, listAllAvisos } = useContext(AvisosContext);

  useEffect(() => {
    listAllAvisos();
  }, []);

  return (
    <S.Container>
      {avisos && !loading && <TableListarAvisos dataTable={avisos} />}
      {loading && <Loading />}
      {!avisos && error && <Warning label="Erro inesperado!" />}
    </S.Container>
  );
};

export default ListarAvisos;
