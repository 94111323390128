import React from "react";
import { useState } from "react";
import Select from "react-select";

/**
 * Select
 * @param {object} props
 * @param {string} props.value
 * @param {object} props.styles
 * @param {[object]} props.options
 * @param {function} props.onChange
 */

// Select usado no Análises / Armazém
// => Esta sendo usado para selecionar a Análise e o tipo de Análise

export default function SelectGlobal({ options, styles, onChange, value }) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <>
      <Select
        value={value}
        styles={styles}
        options={options}
        onChange={onChange}
        menuPortalTarget={document.body}
        placeholder="Selecione"
        onFocus={handleFocus}
        onBlur={handleBlur}
        isSearchable={!isFocused}
        hideSelectedOptions
      />
    </>
  );
}
