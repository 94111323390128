import { useState, useEffect } from "react";
import { AmbienteContext } from "./AmbienteContext";
import { useLocation } from "react-router-dom";

export const AmbienteProvider = ({ children }) => {
  const [ambiente, setAmbiente] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes("armazem")) {
      sessionStorage.setItem("ambiente", "Armazém");
      setAmbiente("Armazém");
    } else if (pathname.includes("financeiro")) {
      sessionStorage.setItem("ambiente", "Financeiro");
      setAmbiente("Financeiro");
    } else if (pathname.includes("fiscal")) {
      sessionStorage.setItem("ambiente", "Fiscal");
      setAmbiente("Fiscal");
    } else if (pathname.includes("insumos")) {
      sessionStorage.setItem("ambiente", "Insumos");
      setAmbiente("Insumos");
    } else if (pathname.includes("graos")) {
      sessionStorage.setItem("ambiente", "Grãos");
      setAmbiente("Grãos");
    } else if (pathname.includes("fabrica")) {
      sessionStorage.setItem("ambiente", "Fábrica");
      setAmbiente("Fábrica");
    } else if (pathname.includes("contabil")) {
      sessionStorage.setItem("ambiente", "Contábil");
      setAmbiente("Contábil");
    } else if (pathname.includes("contato")) {
      sessionStorage.setItem("ambiente", "Contato");
      setAmbiente("Contato");
    } else if (pathname.includes("app-produtor")) {
      sessionStorage.setItem("ambiente", "App Produtor");
      setAmbiente("App Produtor");
    } else {
      setAmbiente("");
    }
  }, [pathname]);

  return (
    <AmbienteContext.Provider value={{ ambiente }}>
      {children}
    </AmbienteContext.Provider>
  );
};
