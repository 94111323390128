import React, { useEffect, useMemo, useState, useRef } from 'react'
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table'
import { COLUMNS } from './data/columns'
import { GlobalFilter } from './data/GlobalFilter'
import { useReactToPrint } from 'react-to-print'
import { Container } from './style'
import { stylePrint } from '../../../utils/style'

import Loading from '../../Layouts/Loading/Loading'

import moment from 'moment'

// ANCHOR Tabela página principal

// Table usado no Dashboard / Armazém

// Dependencias
// => data/Columns: Estrutura das colunas e cálculos da Table
// => data/GlobalFilter: Função de filtrar os dados da table através da pesquisa e ordenação das colunas

export const Table = ({ dataTable, loading }) => {
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])

  useEffect(() => {
    getDataTable(dataTable)
  }, [dataTable])

  // função que trabalha os dados vindo da api e transmite na tabela
  function getDataTable() {
    let numSacas = 0
    let numSacasDespejo = 0
    let numPercentDescarga = 0
    dataTable.map((table) => {
      // conversão da data que vem para o formato correto no qual a table consegue usar o sortBy
      const novaData = moment(table.DAT_PREVISAO, 'DD/MM/YYYY')
      table.DAT_PREVISAO = novaData._d

      numSacas = table.NUM_SACAS === null ? 0 : table.NUM_SACAS
      numSacasDespejo =
        table.NUM_SACAS_DESPEJO === null ? 0 : table.NUM_SACAS_DESPEJO
      numPercentDescarga =
        table.NUM_PERC_DESCARGA === null ? 0 : table.NUM_PERC_DESCARGA

      // condicional para calcular a % do progresso
      if (table.FLG_MOVIMENTO === 'L' || table.FLG_MOVIMENTO === 'S') {
        if (numPercentDescarga && numSacas)
          table.NUM_PERC_DESCARGA = `${(
            (parseFloat(numPercentDescarga) / parseFloat(numSacas)) *
            100
          ).toFixed(2)} %`
        else {
          table.NUM_PERC_DESCARGA = `${table.NUM_PERC_DESCARGA}.00 %`
        }
      } else if (table.FLG_MOVIMENTO === 'P' || table.FLG_MOVIMENTO === 'R') {
        table.NUM_PERC_DESCARGA = `${(
          (parseFloat(numSacasDespejo) / parseFloat(numSacas)) *
          100
        ).toFixed(2)} %`
      } else {
        table.NUM_PERC_DESCARGA = `0.00 %`
      }

      // condicional para mudar o nome do Movimento de acordo com a tag vinda da API
      switch (table.FLG_MOVIMENTO) {
        case 'E':
          table.FLG_MOVIMENTO = 'ENTRADA'
          break

        case 'S':
          table.FLG_MOVIMENTO = 'SAÍDA'
          break

        case 'P':
          table.FLG_MOVIMENTO = 'PREPARO'
          break

        case 'R':
          table.FLG_MOVIMENTO = 'REENSAQUE'
          break

        case 'L':
          table.FLG_MOVIMENTO = 'PRÉ-SAÍDA'
          break

        default:
          break
      }
      return null
    })

    setData(() => dataTable)
  }

  // variável que recebe todos os dados da tabela
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [{ id: 'DAT_PREVISAO', desc: false }],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const { globalFilter } = state
  const { pageIndex, pageSize } = state

  // print table

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'ARMAZÉM / DASHBOARD',
    pageStyle: stylePrint,
  })

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <Container>
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            handlePrint={handlePrint}
            data={data}
          />
          <table {...getTableProps()} ref={componentRef} className="pagePrint">
            <thead>
              {headerGroups.map((headerGroups, index) => (
                <tr {...headerGroups.getHeaderGroupProps()} key={index}>
                  {headerGroups.headers.map((column, index) => (
                    <th
                      key={index}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' ↓'
                            : ' ↑'
                          : ' '}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className="information_page">
            <span className="number_page">
              Página{' '}
              <strong>
                {pageIndex + 1} de {pageOptions.length}
              </strong>{' '}
            </span>
            <span className="go_page">
              {' '}
              <input
                className="go_page_button"
                type="text"
                placeholder="Ir a página"
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0
                  gotoPage(pageNumber)
                }}
              />
            </span>
            <div className="pagination pagination_media">
              <div className="page_direction">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Anterior
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  Próximo
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {'>>'}
                </button>
              </div>
              <select
                className="display_pages"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 25, 50, 100, 500].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Container>
      )}
    </>
  )
}
