import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  flex-direction: column;
  padding: 0.5rem;

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 1rem;

    p {
      width: 65%;
      color: red;
      font-size: 0.7rem;
    }
    h1,
    h2 {
      text-align: center;
    }
  }

  /* .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  } */

  .actions {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;

    button {
      padding: 0.8rem 0.8rem;
      outline: none;
      border: none;
      transition: all ease 0.3s;
      font-weight: bold;
      color: var(--blue-a1);
      border-radius: 4px;
      text-transform: uppercase;

      :hover {
        opacity: 0.7;
      }

      :disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .auth {
      background-color: var(--blue-a17);
    }

    .cancel {
      background-color: var(--red-output);
    }

    .success {
      background-color: var(--blue-emsoft);
      width: 12rem;
    }
  }

  .headerModal {
    font-size: 1.5rem;
  }

  @media (max-width: 700px) {
    .content {
      p {
        width: 90%;
      }
      h1,
      h2 {
        font-size: 1.2rem;
      }
    }

    .headerModal {
      font-size: 1rem;
    }
  }
`;
