import React from "react";
import { Container } from "./style";
import { formatNumberBR } from "../Tables/functions";

const CardTotal = ({ imgCardSacas, imgCardPeso, total }) => {
  return (
    <Container>
      <div className="totalCardSacas">
        <div className="imageCardSacas">
          <img src={imgCardSacas} alt="" />
        </div>
        <div className="infoCardSacas">
          <span>SACAS TOTAL</span>
          <span>{formatNumberBR(total.sacas)}</span>
        </div>
      </div>
      <div className="totalCardPeso">
        <div className="imageCardPeso">
          <img src={imgCardPeso} alt="" />
        </div>
        <div className="infoCardPeso">
          <span>PESO TOTAL</span>
          <span>{formatNumberBR(total.peso)}</span>
        </div>
      </div>
    </Container>
  );
};

export default CardTotal;
