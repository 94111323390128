import { useContext, useEffect } from "react";
import { VinculaContext } from "../../../../contexts/VincularProdutores/VinculaContext";
import { TableVinculaProdutores } from "../../../../components/Tables/TablesAppProdutor/TableVinculaProdutores/TableVinculaProdutores";
import Loading from "../../../../components/Layouts/Loading/Loading";
import FormVinculaProdutores from "./FormVinculaProdutores/FormVinculaProdutores";
import * as S from "./style";

const VinculaProdutores = () => {
  const { data, getData, loading } = useContext(VinculaContext);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      {loading && <Loading />}
      {!loading && data.length > 0 && (
        <S.Container>
          <FormVinculaProdutores />
          <TableVinculaProdutores dataTable={data} />
        </S.Container>
      )}
      {!loading && data.length === 0 && (
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Nenhum usuário com permissão concedida!
        </h1>
      )}
    </>
  );
};

export default VinculaProdutores;
