import { Container } from './style'
import { convertBRL } from '../../utils/convertBRL'

// Componente usado para exibir as informações no Slider (Dashboard / Financeiro)

export default function ListResult({ saldoBancos, saldoReceber, saldoPagar }) {
  const subTotal = saldoBancos + saldoReceber
  const resultado = subTotal - saldoPagar

  return (
    <>
      <Container>
        <ul className="lista">
          <li className="lista_bold">
            SALDO <span>VALOR</span>
          </li>
          <li>
            (+) Saldo Bancos <span>{convertBRL(saldoBancos)}</span>
          </li>
          <li>
            (+) Contas à Receber <span>{convertBRL(saldoReceber)}</span>
          </li>
          <li className="lista_bold">
            SUBTOTAL <span>{convertBRL(subTotal)}</span>
          </li>
          <li>
            (-) Contas à Pagar <span>{convertBRL(saldoPagar)}</span>
          </li>
          <li className="lista_bold">
            RESULTADO <span>{convertBRL(resultado)}</span>
          </li>
        </ul>
      </Container>
    </>
  )
}
