import { Container } from "./style";
import more from "../../../assets/Icons Box/icon-mais.svg";
import Loading from "../../Layouts/Loading/Loading";

// Componente que cria o Card para o Dashboard / Financeiro

export function CardTotal(props) {
  return (
    <>
      <Container>
        <div
          className={`card ${props.card}`}
          onClick={() =>
            props.handleInfoModal(
              props.title,
              props.color,
              props.contas,
              props.loading
            )
          }
        >
          {props.loading && <Loading />}
          {!props.loading && (
            <>
              <h4>{props.title}</h4>
              <div className="card_info">
                <img src={props.icon} alt="" />
                <div className="card_banco">
                  <span>
                    <p>{props.textTotal}</p>
                    {props.valueTotal}
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="card_button">
            <button
              disabled={props.loading}
              className={props.card_button}
              onClick={props.handleShowModal}
            >
              Detalhes
              <img src={more} alt="" />
            </button>
          </div>
        </div>
      </Container>
    </>
  );
}

export function Card(props) {
  function handleInfoModalPeriod(date) {
    props.handleInfoModal(props.title, props.color, date);
    props.handleShowModal();

    // const periodIni = props.dataPeriod[0];
    // const periodFim = props.dataPeriod[props.dataPeriod.length - 1];
  }

  return (
    <>
      <Container>
        <div className={`card ${props.card}`}>
          {props.loading && <Loading />}
          {!props.loading && (
            <>
              <h4 style={{ color: props.color }}>{props.title}</h4>
              <div className="card_info">
                <img src={props.icon} alt="" />
                <div className="info_values">
                  <span
                    className="contas"
                    onClick={() => handleInfoModalPeriod(props.dataPeriodoDia)}
                  >
                    <p>{props.textDia}</p>
                    {props.valueDia}
                  </span>
                  <hr />
                  <span
                    className="contas"
                    title={`${props.dates.amanha} até ${props.dates.semana}`}
                    onClick={() =>
                      handleInfoModalPeriod(props.dataPeriodoSemana)
                    }
                  >
                    <p>{props.textSemana}</p>
                    {props.valueSemana}
                  </span>
                  <hr />
                  <span
                    className="contas"
                    title={`${props.dates.amanha} até ${props.dates.mes}`}
                    onClick={() => handleInfoModalPeriod(props.dataPeriodoMes)}
                  >
                    <p>{props.textMes}</p>
                    {props.valueMes}
                  </span>
                </div>
              </div>
            </>
          )}
          <div
            className="card_button"
            onClick={() =>
              props.handleInfoModal(
                props.title,
                props.color,
                props.contas,
                props.loading
              )
            }
          >
            <button
              disabled={props.loading}
              className={props.card_button}
              onClick={props.handleShowModal}
            >
              Detalhes
              <img src={more} alt="" />
            </button>
          </div>
        </div>
      </Container>
    </>
  );
}
