import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { AmbienteContext } from "../../contexts/Home/AmbienteContext";
import { Container } from "./style";
import emsoftIcon from "../../assets/Icons Navbar/emsoftIcon.svg";
import tela from "../../assets/Icons Navbar/icon-monitor.svg";
import iconHome from "../../assets/Icons Navbar/icon-home.svg";
import atualizar from "../../assets/Icons Navbar/icon-refresh.svg";
import logout from "../../assets/Icons Navbar/icon-logout.svg";
import { sessionStorageDecrypt } from "../../utils/crypto";

// Navbar (topo) usado e reaproveitado em todos os módulos

export default function Navbar() {
  const auth = useContext(AuthContext);
  const { ambiente } = useContext(AmbienteContext);
  const infoCheckPhp = encodeURIComponent(sessionStorage.getItem("php"));
  const urlMapa = sessionStorageDecrypt("urlMapa");

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleLogout = async () => {
    await auth.signout();
  };
  return (
    <Container>
      <div className="navbar_saudacao">
        <h1>
          <img src={emsoftIcon} alt="" />
          {auth.empresa} | {auth.userAuth}
        </h1>
        <span>Bem vindo ao seu portal</span>
      </div>
      <div className="navbar_icons">
        {ambiente === "Armazém" && (
          <a
            href={`${urlMapa}/painel.php?storagephp=${infoCheckPhp}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={tela} alt="painel" />
          </a>
        )}
        <Link to="/">
          <img src={iconHome} alt="home" />
        </Link>

        <button onClick={handleRefresh}>
          <img src={atualizar} alt="atualizar" />
        </button>

        <Link to="/" onClick={handleLogout}>
          <img src={logout} alt="menu" />
        </Link>
      </div>
    </Container>
  );
}
