import React, { useEffect, useState, useRef } from "react";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";

import { Container } from "./style";
import { stylePrint } from "../../../../../utils/style";
import { ExtratoCafeFixo, ExtratoEstoque } from "./data/Columns";

import iconPrint from "../../../../../assets/Icons Analise/icon-print.svg";
import iconCSV from "../../../../../assets/Icons Analise/icon-csv.svg";
import search from "../../../../../assets/Icons Box/icon-pesquisa.svg";
import { createExtratoCafe, formatDate, formatNumberBR, formatSafra } from "./functions";

export const TableExtratoCafe = ({ dataTable, infoRelatorio }) => {
  const [data, setData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [extratoCafe, setExtratoCafe] = useState([]);
  const [flagTotal, setFlagTotal] = useState({ total: "", peso: "" });
  let totalGeralSacas = 0;
  let totalGeralPeso = 0;

  // print table
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "ARMAZÉM / RELATÓRIOS / EXTRATO PRODUTOR",
    pageStyle: stylePrint,
  });

  useEffect(() => {
    if (infoRelatorio === 1) {
      const ExtratoCafe = createExtratoCafe(dataTable, ExtratoCafeFixo);
      setFlagTotal({ total: "VAL_SALDO_SACAS", peso: "VAL_SALDO_PESO" });
      setExtratoCafe(ExtratoCafe);
    } else {
      setFlagTotal({ total: "NUM_SACAS", peso: "NUM_PESO_FISICO" });
      const ExtratoCafe = createExtratoCafe(dataTable, ExtratoEstoque);
      setExtratoCafe(ExtratoCafe);
    }

    const newDataTable = dataTable.map((item) => {
      const safra1 = formatSafra(item.NUM_SAFRA1);
      const safra2 = formatSafra(item.NUM_SAFRA2);
      item.ANO_SAFRA = `${safra1}/${safra2}`;
      item.NF_SERIE = `${item.NUM_NOTA}/${item.NOM_SERIE}`;
      item.DATA_ENTRADA = formatDate(item.DAT_ENTRADA);
      return item;
    });

    const newData = newDataTable.reduce((acc, item) => {
      if (!acc[item.NUM_PR]) {
        acc[item.NUM_PR] = {
          NUM_PR: item.NUM_PR,
          NOM_FAZENDA: item.NOM_FAZE,
          data: [],
        };
      }

      acc[item.NUM_PR].data.push(item);
      return acc;
    }, {});

    const newArray = Object.values(newData);

    setData(newArray);
  }, [dataTable, infoRelatorio]);

  const handleSort = (columnKey) => {
    if (columnKey === "DATA_ENTRADA") {
      columnKey = "DAT_ENTRADA";
      setSortedColumn("DAT_ENTRADA");
    }
    if (columnKey === sortedColumn) {
      setSortDirection((prevDirection) => (prevDirection === "asc" ? "desc" : "asc"));
    } else {
      setSortedColumn(columnKey);
      setSortDirection("asc");
    }
  };

  const filterDataTable = data
    .map((item) => ({
      ...item,
      data: item.data.filter((subItem) =>
        Object.values(subItem).join(" ").toLowerCase().includes(searchItem.toLowerCase())
      ),
    }))
    .filter((item) => item.data.length > 0);

  const sortedData = [...filterDataTable];

  return (
    <Container ref={componentRef} className="pagePrint">
      <div className="inputSearch">
        <div className="buttonsPrintCSV">
          <CSVLink data={dataTable}>
            <button className="button" title="Exportar CSV">
              <img src={iconCSV} alt="" />
            </button>
          </CSVLink>
          <button className="button" onClick={handlePrint} title="Imprimir">
            <img src={iconPrint} alt="" />
          </button>
        </div>

        <input
          type="text"
          placeholder="Pesquisar"
          value={searchItem}
          onChange={(e) => {
            setSearchItem(e.target.value);
          }}
        />
        <p className="icon_search">
          <img src={search} alt="" />
        </p>
      </div>

      <table>
        <thead className="sticky-header">
          <tr>
            {extratoCafe.map((item, index) => {
              let header = item.accessorKey;
              if (header === "DATA_ENTRADA") {
                header = "DAT_ENTRADA";
              }
              return (
                <th
                  key={index}
                  onClick={() => handleSort(header)}
                  className={sortedColumn === header ? sortDirection : ""}
                >
                  {item.header}
                  {sortedColumn === header && (
                    <span className="sort-icon">{sortDirection === "asc" ? " ↓" : " ↑"}</span>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => {
            let totalSacas = 0;
            let totalPeso = 0;
            return (
              <React.Fragment key={index}>
                <tr>
                  <td colSpan={extratoCafe.length} className="tdIr">
                    {`${item.NUM_PR ? item.NUM_PR : "ISENTO"} - ${item.NOM_FAZENDA}`}
                  </td>
                </tr>
                {item.data
                  .sort((a, b) => {
                    const valueA = a[sortedColumn] || "";
                    const valueB = b[sortedColumn] || "";

                    if (sortDirection === "asc") {
                      return valueA.toString().localeCompare(valueB.toString());
                    } else {
                      return valueB.toString().localeCompare(valueA.toString());
                    }
                  })
                  .map((subItem, subIndex) => {
                    totalSacas += subItem[flagTotal.total];
                    totalPeso += subItem[flagTotal.peso];

                    return (
                      <tr key={subIndex}>
                        {extratoCafe.map((testItem, index) => (
                          <td key={index}>
                            {testItem.formatter
                              ? testItem.formatter(subItem[testItem.accessorKey])
                              : subItem[testItem.accessorKey]}
                          </td>
                        ))}
                      </tr>
                    );
                  })}

                <tr className="tr-total">
                  {extratoCafe.map((item, index) => {
                    if (index === 0) {
                      return (
                        <td key={index} className="td-total">
                          TOTAL
                        </td>
                      );
                    }
                    if (item.accessorKey === flagTotal.total) {
                      totalGeralSacas += totalSacas;
                      return (
                        <td key={index} className="td-totalSacas">
                          {formatNumberBR(totalSacas)}
                        </td>
                      );
                    } else if (item.accessorKey === flagTotal.peso) {
                      totalGeralPeso += totalPeso;
                      return (
                        <td key={index} className="td-totalPeso">
                          {formatNumberBR(totalPeso)}
                        </td>
                      );
                    } else {
                      return <td key={index}></td>;
                    }
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div className="totalGeral">
        <span>{`SACAS TOTAL: ${formatNumberBR(totalGeralSacas)} | PESO TOTAL: ${formatNumberBR(
          totalGeralPeso
        )}`}</span>
        <span></span>
      </div>
    </Container>
  );
};
