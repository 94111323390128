import { Container } from './style'
import more from '../../../assets/Icons Box/icon-mais.svg'
import Loading from '../../Layouts/Loading/Loading'

// Componente que cria os cards do Dashboard / Módulo Armazém

export default function CardArm(props) {
  return (
    <>
      <Container>
        <div
          className={`card ${props.card}`}
          onClick={() =>
            props.handleInfoModal(
              props.title,
              props.color,
              props.value,
              props.documentos,
              props.realizado,
              props.progresso,
              props.embalagens,
              props.sacasModalidade,
            )
          }
        >
          {props.loading && <Loading />}
          {!props.loading && (
            <div className="card_info">
              <img src={props.icon} alt="" />
              <span>
                {props.value}
                <p>sacas</p>
              </span>
            </div>
          )}
          <div className="card_button">
            <button
              disabled={props.loading}
              className={props.card_button}
              onClick={props.handleShowModal}
            >
              {props.title}
              <img src={more} alt="" />
            </button>
          </div>
        </div>
      </Container>
    </>
  )
}
