import styled from "styled-components";
import Pattern from "../../assets/img/Pattern.png";
const larguraTela = window.innerWidth;

export const Container = styled.div`
  background-color: var(--blue-a17);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 2.55em;
    font-weight: 500;
    color: var(--blue-a18);
  }

  p {
    color: var(--blue-a11);
    font-size: 0.9em;
    margin-top: -4px;
  }

  .login_content {
    display: flex;
    flex-direction: row;
    z-index: 1;
  }

  .login_box_top {
    background-color: var(--blue-a16);
    width: 800px;
    height: 500px;
    border-radius: 4px;
    z-index: -1;
    position: absolute;
    transform: translate(-30px, -5%);
  }

  .login_box_bottom {
    background-color: var(--blue-a16);
    width: 800px;
    height: 500px;
    border-radius: 4px;
    z-index: -1;
    position: absolute;
    transform: translate(30px, 5%);
  }

  .login_section-logo {
    background-color: var(--blue-a18);
    background-image: url(${larguraTela > 850 ? Pattern : ""});
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    width: 400px;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login_logo {
    width: 300px;
    height: 360px;
    margin-top: -30px;
  }

  .login_section-form {
    background-color: var(--blue-a3);
    width: 400px;
    height: 500px;
    border-radius: 0px 4px 4px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  footer {
    color: var(--gray-c3);
    font-size: 12px;
    z-index: 999;
    margin-left: 400px;
    margin-top: -18px;
  }

  @media (max-width: 850px) {
    width: initial;
    height: 100vh;
    .login_content {
      display: grid;
      grid-template-columns: 1fr;
    }

    .login_box_top {
      display: none;
      background: none;
      width: 0px;
    }

    .login_box_bottom {
      display: none;
      background: none;
      width: 0px;
    }
    .login_section-logo {
      border-radius: 0px;
      height: auto;
    }
    .login_section-logo img {
      width: 80%;
      height: auto;
      margin-bottom: 2rem;
    }
    .login_logo {
      margin-top: 0px;
    }

    .login_logoFooter {
      margin-top: 0px;
    }
    .login_section-form {
      height: auto;
      justify-content: flex-start;
      /* gap: 15px; */
      padding-top: 5px;
      border-radius: 0px;
    }
    footer {
      margin: 0;
      padding: 0;
    }
  }

  @media (max-width: 400px) {
    background-color: var(--blue-a18);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .login_section-logo {
      width: 100%;
      height: 100%;
    }
    .login_section-form {
      width: 100%;
      min-height: 60vh;
    }

    footer {
      margin-bottom: 10px;
      text-align: center;
    }
  }
`;
