import React from 'react'
import { Container } from './style'

// Componente que cria os Cards das Análises / Armazém
// => Pesagem Rodoviária / Processos Finalizados

const CardProcessosFinalizados = ({ total, nameCard, icon }) => {
  return (
    <Container>
      <div className="CardProcessosFinalizados">
        <div className="totalCardProcessosFinalizados">{total}</div>
        <div className="nameCardProcessosFinalizados">{nameCard}</div>
      </div>
      <div className="IconCardProcessosFinalizados">
        <img src={icon} alt="" />
      </div>
    </Container>
  )
}

export default CardProcessosFinalizados
