import React from "react";
import Switch from "./Components/Switch/Switch";
import { Container } from "./style";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/Auth/AuthContext";
import { getDataSqlNotification } from "../../../services/apiPortalProdutor";
import Loading from "../../../components/Layouts/Loading/Loading";
import { updateNotifications } from "../../../services/apiPortalProdutor";
import ModalNotification from "./Components/ModalNotification/ModalNotification";

const generateRanges = (max) => {
  const ranges = [];
  for (let i = 0; i <= max; i += 7) {
    ranges.push([i, Math.min(i + 7, max)]);
  }

  return ranges;
};

function Notifications() {
  const { dono, userAuth, password } = useContext(AuthContext);
  const [countColuns, setCountColuns] = useState("");
  const [msgError, setMsgError] = useState(false);
  const [msgSucess, setMsgSucess] = useState(false);
  const [msgErrorNotification, setMsgErrorNotification] = useState(false);
  const [loading, setLoading] = useState(true);
  const sqlNotification = `SELECT * FROM table (${dono}.pack_app_remoto.tab_config_notify)`;

  const [switchData, setSwitchData] = useState([]);

  const getSqlNotification = async () => {
    try {
      const data = await getDataSqlNotification(userAuth, password, sqlNotification);
      setCountColuns(generateRanges(data.length).length);
      const formattedData = data.map(({ S_COLUNA, S_VALOR, S_COMENTARIO }) => ({
        S_COLUNA,
        S_VALOR: S_VALOR === "S" ? true : false,
        S_COMENTARIO,
      }));
      setMsgError(false);
      setSwitchData(formattedData);
      setLoading(false);
    } catch (err) {
      setMsgError(true);
      setLoading(false);
      console.log(err);
    }
  };

  const handleSendData = async () => {
    try {
      const newData = switchData.reduce((acc, item) => {
        const { S_COLUNA, S_VALOR } = item;
        acc[S_COLUNA] = S_VALOR ? "S" : "N";
        return acc;
      }, {});
      const {
        S_CAMINHAO_CHEGA,
        S_ABRE_PESO,
        S_FECHA_PESO,
        S_CAMINHAO_SAIDA,
        S_CLASSIFICA_LOTE,
        S_ABRE_ISD,
        S_FECHA_ISD,
        S_FATURA_SERVICO,
        S_EMITE_NFE,
        S_EMITE_CTR,
        S_EMITE_OC,
        S_EMITE_RD,
        S_EMITE_TRA,
      } = newData;
      const resData = await updateNotifications(
        dono,
        userAuth,
        password,
        S_CAMINHAO_CHEGA,
        S_ABRE_PESO,
        S_FECHA_PESO,
        S_CAMINHAO_SAIDA,
        S_CLASSIFICA_LOTE,
        S_ABRE_ISD,
        S_FECHA_ISD,
        S_FATURA_SERVICO,
        S_EMITE_NFE,
        S_EMITE_CTR,
        S_EMITE_OC,
        S_EMITE_RD,
        S_EMITE_TRA
      );
      setMsgErrorNotification(resData !== undefined ? false : true);
      if (resData !== undefined) {
        getSqlNotification();
        setMsgSucess(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRequestCloseFunc = () => {
    setMsgSucess(false);
  };

  useEffect(() => {
    getSqlNotification();
    // eslint-disable-next-line
  }, []);

  return (
    <Container countColuns={countColuns}>
      {msgError && <h1 className="msgError">Erro ao conectar com o Banco de Dados!</h1>}
      {loading && <Loading />}
      {!msgError && !loading && (
        <>
          <h1 className="textNotification">Painel de Notificações</h1>
          <div className="swithNotification">
            {switchData.map((data, index) => (
              <Switch
                key={index}
                label={data.S_COMENTARIO}
                checked={data.S_VALOR}
                onToggle={() => {
                  setSwitchData((prevSwitchData) =>
                    prevSwitchData.map((item) =>
                      item.S_COLUNA === data.S_COLUNA ? { ...item, S_VALOR: !item.S_VALOR } : item
                    )
                  );
                }}
              />
            ))}
          </div>

          <button onClick={handleSendData} className="buttonSendNotification">
            Enviar
          </button>
          {msgErrorNotification && <p>Erro ao enviar, tente novamente!</p>}
          {msgSucess && (
            <ModalNotification
              statusModalActual={msgSucess}
              handleRequestCloseFunc={handleRequestCloseFunc}
            />
          )}
        </>
      )}
    </Container>
  );
}

export default Notifications;
