import { useEffect, useState } from 'react'
import { AuthContext } from './AuthContext'
import { validateUser } from '../../services/api'
import { useNavigate } from 'react-router-dom'
import UseAuthentication from '../../hooks/useAuthentication'
import { doc, getDoc } from 'firebase/firestore'
import { db, auth } from '../../services/firebase'
import { encrypt, sessionStorageDecrypt } from '../../utils/crypto'

export const AuthProvider = ({ children }) => {
  // Estados que o usuário digita no inputs de login
  const [userAuth, setUserAuth] = useState('')
  const [password, setPassword] = useState('')

  // Estado de autenticação
  const [isLogged, setIsLogged] = useState(false)
  const { Logout } = UseAuthentication()

  // Roteamento
  const navigate = useNavigate()

  // Estados que foram resposta do ValidaPainel
  const [empresa, setEmpresa] = useState('')
  const [codEmpresa, setCodEmpresa] = useState('')
  const [filial, setFilial] = useState('')
  const [codFilial, setCodFilial] = useState('')
  const [isAutomatus, setIsAutomatus] = useState(false)
  const [dono, setDono] = useState('')
  const [keyAuth, setKeyAuth] = useState('') // Nível do usuário
  const [apiValidaUser, setApiValidaUser] = useState([])
  const [authFirebase, setAuthFirebase] = useState(false)

  useEffect(() => {
    const storage = sessionStorageDecrypt('newBiWeb')

    if (storage) {
      setUserAuth(storage.user)
      setEmpresa(storage.empresa)
      setFilial(storage.filial)
      setDono(storage.dono)
      setPassword(storage.pass)
      setCodEmpresa(storage.codEmpresa)
      setCodFilial(storage.codFilial)
      setIsAutomatus(storage.isAutomatus)
      setKeyAuth(storage.keyAuth)
    }
  }, [])

  const signin = async (user, password) => {
    try {
      const data = await validateUser(user, password)

      setPassword(password)
      const { TABLE } = data
      setApiValidaUser(TABLE)
      setUserAuth(user)
      setIsLogged(true)
      return { codeError: false }
    } catch (error) {
      return {
        codeError: error.code,
      }
    }
  }

  function setStateLogin(item) {
    if (item) {
      setDono(item.WSDONO)
      setEmpresa(item.NOM_EMPRESA_TELA)
      setFilial(item.NOM_FILIAL)
      setKeyAuth(item.NUM_NIVEL)
      setCodEmpresa(item.COD_EMPRESA)
      setCodFilial(item.COD_FILIAL)
      setKeyAuth(item.NUM_NIVEL)
      setIsAutomatus(item.FLG_AUTOMATUS)
      setValidate(
        userAuth,
        item.WSDONO,
        item.NOM_EMPRESA_TELA,
        item.NOM_FILIAL,
        password,
        item.COD_EMPRESA,
        item.COD_FILIAL,
        item.NUM_NIVEL,
        item.FLG_AUTOMATUS,
      )
      navigate('/')
    }
  }

  const signout = () => {
    setUserAuth(null)
    setIsLogged(false)
    Logout()
    setAuthFirebase(false)
    localStorage.clear()
    sessionStorage.clear()
    navigate('/login')
  }

  const setValidate = (
    user,
    donoStorage,
    empresaStorage,
    filialStorage,
    passStorage,
    codEmpresa,
    codFilial,
    keyAuth,
    isAutomatus,
  ) => {
    const infoStorageNewBiWeb = {
      user,
      dono: donoStorage,
      empresa: empresaStorage,
      filial: filialStorage,
      pass: passStorage,
      codEmpresa,
      codFilial,
      keyAuth,
      isAutomatus,
    }
    const infoEncryptNewBiWeb = encrypt(JSON.stringify(infoStorageNewBiWeb))
    sessionStorage.setItem('newBiWeb', infoEncryptNewBiWeb)

    const infoStoragePHP = {
      usuario: user,
      dono: donoStorage,
      senha: passStorage,
      empresa: codEmpresa,
      filial: codFilial,
    }
    const infoEncryptPHP = encrypt(JSON.stringify(infoStoragePHP))
    sessionStorage.setItem('php', infoEncryptPHP)
  }

  const firebaseStore = async (cnpj) => {
    const docRef = doc(db, 'clientes', cnpj)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists() && auth.currentUser.uid === docSnap.data().uid) {
      const { url, urlMapa } = docSnap.data()
      const cryptUrl = encrypt(JSON.stringify(url))
      const cryptUrlMapa = encrypt(JSON.stringify(urlMapa))
      const cryptCnpjArmazem = encrypt(JSON.stringify(cnpj))
      sessionStorage.setItem('urlApi', cryptUrl)
      sessionStorage.setItem('urlMapa', cryptUrlMapa)
      sessionStorage.setItem('cnpjArmazem', cryptCnpjArmazem)
      return true
    } else {
      return false
    }
  }

  return (
    <AuthContext.Provider
      value={{
        userAuth,
        signin,
        signout,
        empresa,
        filial,
        dono,
        password,
        keyAuth,
        codEmpresa,
        setCodEmpresa,
        codFilial,
        setCodFilial,
        isLogged,
        apiValidaUser,
        setStateLogin,
        setIsLogged,
        isAutomatus,
        authFirebase,
        setAuthFirebase,
        firebaseStore,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
