import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import Warning from '../../../Layouts/Warning/Warning'

ChartJS.register(ArcElement, Tooltip, Legend)

// Chart usado no Dashboard / Armazém
// => É usado no Modal dos Cards

export default function ChartModal({ embalagens, sacasModalidade }) {
  const data = {
    labels: embalagens,
    datasets: [
      {
        data: sacasModalidade,
        backgroundColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 114,1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 114,1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
        radius: '90%',
        cutout: '50%',
        hoverOffset: '20',
      },
    ],
  }

  // variável que retornar o elemento no array de preparo
  const item = sacasModalidade.find((element) => element > 1)

  if (!item || sacasModalidade.length === 0) {
    return <Warning label="Não há movimentações neste período." />
  }

  return (
    <Doughnut
      data={data}
      options={{
        plugins: {
          legend: {
            display: true,
            position: 'bottom',

            labels: {
              boxWidth: 20,
              padding: 10,
              font: {
                size: 14,
                family: 'Roboto',
              },
            },
          },
          datalabels: {
            display: false,
          },
        },
        layout: {
          padding: 0,
        },
      }}
    />
  )
}
