import { phoneMask } from "../../../../../utils/regex";
import { ReactComponent as Visivel } from "../../../../../assets/Icons-Portal/visivel.svg";
import { ReactComponent as AddProdutor } from "../../../../../assets/Icons-Portal/group-add.svg";
import AddPermission from "../../../../../Modules/AppProdutor/CadastroProdutor/VinculaProdutores/FormVinculaProdutores/AddPermission/AddPermission";
import DetailsPermissions from "../../../../../Modules/AppProdutor/CadastroProdutor/VinculaProdutores/FormVinculaProdutores/DetailsPermissions/DetailsPermissions";

// ANCHOR Cabeçalho da tabela

export const COLUMNS = [
  {
    Header: "Nome do usuário",
    accessor: "NOME_USUARIO",
  },
  {
    Header: "Status",
    accessor: "USUARIO_ATIVO",
    Cell: ({ cell: { value } }) => {
      if (value === "N") {
        return "Inativo";
      } else {
        return "Ativo";
      }
    },
  },
  {
    Header: "Telefone",
    accessor: "TELEFONE_USUARIO",
    Cell: ({ cell: { value } }) => {
      if (value) {
        return phoneMask(value);
      } else {
        return "Não cadastrado";
      }
    },
  },

  {
    Header: "Observações",
    accessor: "S_OBSERVACOES",
    Cell: ({ cell: { value } }) => {
      if (value) {
        return value;
      } else {
        return "Não cadastrado";
      }
    },
  },
  {
    Header: "Ações",
    accessor: "",
    Cell: ({ cell: { row } }) => {
      const { original } = row;

      return (
        <div
          style={{
            display: "flex",
            gap: "1rem",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AddPermission id={original.ID_USUARIO} name={original.NOME_USUARIO}>
            <AddProdutor title="Adicionar permissão" />
          </AddPermission>
          <DetailsPermissions dataPermission={original}>
            <Visivel title="Ver Todas" />
          </DetailsPermissions>
        </div>
      );
    },
  },
];
