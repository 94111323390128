import { useProdutorExist } from "../../../../hooks/useProdutorExist";
import Loading from "../../../../components/Layouts/Loading/Loading";
import HeaderModalPortal from "../../../../components/Modal/ModalPortal/HeaderModalPortal";
import { Wrapper } from "./style";

const ProdutorIsExist = ({ dbProdutor, handleRequestCloseFunc }) => {
  const { isAuthorization, loading, verifyRegister } =
    useProdutorExist(dbProdutor);

  return (
    <Wrapper>
      <HeaderModalPortal
        handleRequestCloseFunc={handleRequestCloseFunc}
        title="Verificação de cadastro"
      />
      <div className="content">
        <div className="info">
          {loading ? (
            <Loading />
          ) : (
            <>
              {!isAuthorization.auth && (
                <>
                  <h1>Usuário já cadastrado!</h1>
                  <h2>Deseja autorizar o usuário?</h2>
                </>
              )}
              {isAuthorization.auth && <h1>{isAuthorization.message}</h1>}
            </>
          )}
        </div>
        <div className="actions">
          {!isAuthorization.auth && (
            <>
              <button className="cancel" onClick={handleRequestCloseFunc}>
                cancelar
              </button>
              <button
                className="auth"
                onClick={verifyRegister}
                disabled={isAuthorization.auth}
              >
                autorizar
              </button>
            </>
          )}
          {isAuthorization.auth && (
            <button className="success" onClick={handleRequestCloseFunc}>
              OK
            </button>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default ProdutorIsExist;
