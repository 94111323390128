import styled from 'styled-components'

export const Container = styled.div`
  color: var(--blue-a1);
  display: flex;
  flex-direction: column;
  margin-left: 50px;

  .formLotes {
    display: grid;
    grid-template-rows: 30px 50px;
    grid-template-columns: 270px 100px;

    label {
      grid-column: 1 / -1;
    }

    input {
      width: 250px;
      max-height: 30px;
      border: none;
      box-shadow: none;
      outline: none;
      border-radius: 4px;
      padding-left: 10px;
      font-size: 0.9rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    button {
      max-height: 30px;
      border: none;
      border-radius: 4px;
      font-size: 0.9rem;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.5s;

      :hover {
        background-color: var(--blue-a4);
      }

      :disabled {
        background-color: var(--blue-a16);
        color: var(--blue-a12);
        cursor: not-allowed;
      }
    }
  }

  .detailsLotes {
    display: flex;
    background-color: var(--blue-a16);
    flex-direction: column;
    margin-right: 50px;
    border-radius: 8px;
    font-size: 1.2rem;

    p {
      margin: 15px 15px 10px 30px;
    }

    button {
      font-weight: bold;
      font-size: 1.2rem;
      margin: 10px 0px 15px 30px;
      max-height: 50px;
      max-width: 200px;
      padding: 10px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.5s;

      :hover {
        background-color: var(--blue-a4);
      }
    }
  }
  .ErrorLote {
    background-color: var(--blue-a1);
    display: flex;
    padding: 0;
    width: 370px;
    height: 50px;
    color: var(--blue-a18);
    border: none;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  @media only screen and (max-width: 500px) {
    margin-left: 20px;
    .formLotes {
      grid-template-columns: 1fr;

      button {
        width: 200px;
        height: 50px;
        margin-bottom: 30px;
      }
    }
    .ErrorLote {
      width: 250px;
    }
  }
`
