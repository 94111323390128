import styled from "styled-components";

export const FormLogin = styled.form`
  width: 100%;
  height: 30%;
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    color: red;
    margin-top: -10px;
  }

  input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  .login_label-float input {
    width: 230px;
    padding: 3px 3px;
    display: inline-block;
    background: transparent;
    border: 0;
    border-bottom: 2px solid var(--blue-a18);
    outline: none;
    min-width: 180px;
    font-size: 16px;
    border-radius: 0;
    transition: all 0.3s ease-out;
  }

  .login_label-float {
    position: relative;
    padding-top: 13px;
    margin-top: 1%;
    margin-bottom: 5%;
  }

  .login_label-float input:focus {
    border-bottom: 2px solid var(--blue-emsoft);
  }

  .login_label-float label {
    color: var(--gray-c3);
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 13px;
    transition: all 0.3s ease-out;
  }

  .login_label-float input:focus + label,
  .login_label-float input:valid + label {
    font-size: 13px;
    margin-top: 0;
    color: var(--blue-emsoft);
  }

  .login_justify-center {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
`;

export const MessageEmpresa = styled.div`
  margin-top: 20px;
  height: 10px;
  p {
    color: var(--blue-a18);
    font-weight: bold;
    padding: 2px;
  }
`;
export const FormEmpresa = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 30%;
  margin-top: 40px;

  .select {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .button {
    margin-top: 15px;
  }
`;

export const customStylesSelectLogin = {
  // control => estiliza o select
  control: (styles) => ({
    ...styles,
    backgroundColor: "#030F14",
    width: 300,
    height: 40,
    fontSize: 14,
  }),
  // option => estiliza as opções
  option: (styles) => ({
    ...styles,
    backgroundColor: "#030F14",
    color: "#F1F3F4",
    width: 300,
    cursor: "Pointer",
    fontSize: 14,
    "&:hover": {
      color: "#030F14",
      backgroundColor: "#A2BECA",
      fontWeight: 600,
    },
  }),
  // menu => estiliza a caixa das opções
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#030F14",
    width: 300,
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "white",
  }),
  container: (styles) => ({
    ...styles,
    width: 300,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "white",
  }),
};
