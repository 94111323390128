import { useState, useContext } from "react";
import { db } from "../services/firebase";
import { updateDoc, doc } from "firebase/firestore";
import { sessionStorageDecrypt } from "../utils/crypto";
import { getUserId } from "../utils/functions";
import { AuthContext } from "../contexts/Auth/AuthContext";
import { createUpdateDeleteUsersApp } from "../services/apiPortalProdutor";
import { VinculaContext } from "../contexts/VincularProdutores/VinculaContext";

export const useProdutorExist = (dbProdutor) => {
  const [isAuthorization, setIsAuthorization] = useState({
    auth: false,
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const { dono, userAuth, password } = useContext(AuthContext);
  const { setUserId, setStatusVinculaModal } = useContext(VinculaContext);

  // const { empresa } = useContext(AuthContext)
  // const urlArmazem = sessionStorageDecrypt('urlApi')
  // const codProdutorArm = sessionStorageDecrypt('codProdutor')
  const idProdutor = sessionStorageDecrypt("idProdutor");
  const cnpjArmazem = sessionStorageDecrypt("cnpjArmazem");

  const verifyRegister = async () => {
    setLoading(true);
    if (dbProdutor) {
      // se o firebase retornar o cadastro do cpf do produtor, verificar se o armazém já o autorizou
      // const cnpjs = dbProdutor.armazens.map((item) => item.cnpj)
      if (dbProdutor.armazens.includes(cnpjArmazem)) {
        setLoading(false);
        setIsAuthorization(() => ({
          auth: true,
          message: "Usuário já autorizado!",
        }));
      } else {
        // se não adicionar os dados da empresa

        const { nome, cpf, email } = dbProdutor;
        const addArmazem = [...dbProdutor.armazens, cnpjArmazem];
        const docRef = doc(db, "produtores", idProdutor);

        try {
          // Verifica se o usuário já está cadastrado no banco local
          const isUserExist = await createUpdateDeleteUsersApp(
            dono,
            userAuth,
            password,
            "C",
            "0",
            cpf,
            "",
            "",
            "",
            "",
            "S"
          );

          const userIdResult = parseInt(getUserId(isUserExist));

          // Snão houver registro, castrar e se houver cadastar apenas no firebase
          if (userIdResult === 0) {
            const resId = await createUpdateDeleteUsersApp(
              dono,
              userAuth,
              password,
              "I",
              "0",
              cpf,
              nome.toUpperCase(),
              email,
              "",
              "",
              "S"
            );
            const valorAposPipe = getUserId(resId);
            setUserId({ id: valorAposPipe, name: nome });

            await updateDoc(docRef, { armazens: addArmazem });
            setLoading(false);
            setIsAuthorization(() => ({
              auth: true,
              message: "Usuário autorizado com SUCESSO!",
            }));
            setStatusVinculaModal(true);
          } else {
            const resId = await createUpdateDeleteUsersApp(
              dono,
              userAuth,
              password,
              "A",
              "0",
              cpf,
              nome.toUpperCase(),
              email,
              "",
              "",
              "S"
            );
            const valorAposPipe = getUserId(resId);
            setUserId({ id: valorAposPipe, name: nome });

            await updateDoc(docRef, { armazens: addArmazem });
            setLoading(false);
            setIsAuthorization(() => ({
              auth: true,
              message: "Usuário autorizado com SUCESSO!",
            }));
            setStatusVinculaModal(true);
          }
        } catch (error) {
          setLoading(false);
          setIsAuthorization(() => ({
            auth: true,
            message:
              "Erro ao tentar realizar cadastro! Por favor tente mais tarde ou contate o suporte.",
          }));
        }
      }
    }
  };

  return {
    loading,
    isAuthorization,
    verifyRegister,
  };
};
