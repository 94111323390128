export const OptionsSelect = [
  {
    selecione: [
      {
        value: "",
        label: "",
      },
    ],

    analise: [
      {
        id: 1,
        value: "Extrato de Cafe",
        label: "Extrato de Café",
      },
      {
        id: 2,
        value: "Estoque Fisico",
        label: "Estoque Fisíco",
      },
      // {
      //   id: 3,
      //   value: "Movim. Estoque por Lote",
      //   label: "Movim. Estoque por Lote",
      // },
      // {
      //   id: 4,
      //   value: "Movim. Estoque Analitico",
      //   label: "Movim. Estoque Analítico",
      // },
      // {
      //   id: 5,
      //   value: "Movim. Estoque Sintetico",
      //   label: "Movim. Estoque Sintético",
      // },
    ],
  },
];
