import styled from "styled-components";

export const Container = styled.div`
  input {
    text-align: center;
    height: 38px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    outline: none;
  }
`;
