import styled from "styled-components";

export const Container = styled.div`
  .date-modal {
    display: flex;
    flex-direction: column;
    margin: 20px;
    gap: 28px;
    font-size: 1.2rem;
  }

  button {
    display: block;
    margin-left: auto;
    outline: none;
    border: none;
    appearance: none;
  }

  .icon-fechar {
    height: 10px;
    display: block;
  }

  .span-entrada::before {
    content: "";
    display: inline-block;
    background: var(--green-entry);
    width: 20px;
    height: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 4px;
  }

  .span-saida::before {
    content: "";
    display: inline-block;
    background: var(--red-output);
    width: 20px;
    height: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 4px;
  }

  .span-preparo::before {
    content: "";
    display: inline-block;
    background: var(--yellow-preparation);
    width: 20px;
    height: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 4px;
  }

  .span-reensaque::before {
    content: "";
    display: inline-block;
    background: var(--blue-repack);
    width: 20px;
    height: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 4px;
  }

  .calendar {
    font-size: 1rem;
  }

  .button-calendar {
    display: flex;
    place-content: center;
  }

  .button-alterar {
    outline: none;
    background: var(--blue-a18);
    color: var(--blue-a1);
    border-radius: 4px;
    width: 6rem;
    height: 2rem;
    margin: 0.3rem 1rem 1rem 1rem;
    font-size: 1rem;
  }

  @media (max-width: 400px) {
    .date-modal {
      margin: 5px;
      gap: 20px;
    }
    .calendar {
      font-size: 0.8rem;
    }
  }
`;
