import styled from "styled-components";

export const Container = styled.div`
  grid-area: main;
  display: grid;
  margin: 1rem 2rem 0 2rem;
  grid-template-rows: 90px 1fr;
  gap: 4px;

  .selectRelatorios {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .selectOptions {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .cardsTotal {
    margin-left: 1rem;
  }

  .opcoesProdutores {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .containerTable {
    display: flex;
    border-radius: 4px;
    height: 70vh;
    grid-column: 1 / -1;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
  }

  .tableRelatorioExtratoCafe {
    grid-column: 1 / -1;
    align-self: flex-start;
    margin: 2px 5px 5px 5px;
    max-height: 100%;
    width: 100%;
  }

  .LoadingMsgTable {
    grid-column: 1 / -1;
    grid-row: 3;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px 0 0 5px;

    .opcoesProdutores {
      grid-template-columns: 1fr;
      flex-direction: column;
      align-items: start;
    }

    .tableRelatorioExtratoCafe {
      margin: 0px;
    }

    .cardsTotal {
      display: none;
    }
  }
`;

export const customStylesSelect = {
  // control => estiliza o select
  control: (styles) => ({
    ...styles,
    backgroundColor: "#fff",
    width: 300,
    minHeight: 25,
    height: 38,
    fontSize: 16,
  }),
  // option => estiliza as opções
  option: (styles) => ({
    ...styles,
    color: "#030F14",
    width: 300,
    fontSize: 14,
    cursor: "Pointer",
    "&:hover": {
      color: "#030F14",
      backgroundColor: "#A2BECA",
      fontWeight: 600,
    },
  }),
  // menu => estiliza a caixa das opções
  menu: (styles) => ({
    ...styles,
    width: 300,
  }),
  // Estilização do Selecione
  placeholder: (styles) => ({
    ...styles,
    color: "#000",
    fontWeight: "bold",
  }),
  // Estilização do texto quando selecionado
  singleValue: (styles) => ({
    ...styles,
    color: "#000",
    fontWeight: "bold",
  }),
  // estilização da flechinha do menu e do separador
  indicatorsContainer: (styles) => ({
    ...styles,
    height: 35,
  }),
};
