import { useAtualizarProdutores } from "../../../../hooks/useAtualizarProdutores";
import { phoneMask } from "../../../../utils/regex";
import HeaderModalPortal from "../../../../components/Modal/ModalPortal/HeaderModalPortal";
import Loading from "../../../../components/Layouts/Loading/Loading";
import * as S from "./style";
// import { auth } from '../../../../services/firebase'

const AtualizaEmail = ({ handleRequestCloseFunc, data }) => {
  const {
    error,
    loading,
    handleEmailProdutor,
    email,
    setEmail,
    inativo,
    observacao,
    setObservacao,
    telefone,
    setTelefone,
  } = useAtualizarProdutores(data);

  return (
    <S.Wrapper>
      <HeaderModalPortal
        handleRequestCloseFunc={handleRequestCloseFunc}
        title="Formulário de atualização de e-mail"
      />
      {loading ? (
        <Loading />
      ) : !inativo ? (
        <>
          <S.Form onSubmit={handleEmailProdutor}>
            <div>
              <label htmlFor="email_produtor">E-mail</label>
              <S.Input
                autoFocus
                type="email"
                id="email_produtor"
                placeholder="Digite o novo e-mail do usuário"
                $invalid={error}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error && <small>{error}</small>}
            </div>
            <div>
              <label htmlFor="telefone_user">Telefone</label>
              <S.Input
                type="text"
                id="telefone_user"
                placeholder="(99) 99999-9999"
                value={phoneMask(telefone)}
                onChange={(e) => setTelefone(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="obs">Observação</label>
              <S.Input
                type="text"
                id="obs"
                placeholder="Digite uma observação"
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
                required
              />
            </div>

            <S.Button>Enviar</S.Button>
          </S.Form>
        </>
      ) : (
        <div className="success">
          <h1>Dados atualizados com SUCESSO!</h1>
          <S.Button $success onClick={handleRequestCloseFunc}>
            Ok
          </S.Button>
        </div>
      )}
    </S.Wrapper>
  );
};

export default AtualizaEmail;
