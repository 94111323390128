import styled from "styled-components";

export const Container = styled.div`
  grid-area: main;
  margin: 0.2rem 2rem 0 2rem;
  display: grid;
  grid-template-rows: 40px 40px 1fr;
  align-items: center;

  h1 {
    margin: 0;
    padding: 0;
  }

  .tableContainer {
    grid-column: 1 / -1;
    align-self: flex-start;
    margin-top: 8px;
    overflow-y: auto;
    max-height: 75vh;
  }

  .searchTable {
    grid-row: 2;
    form {
      label {
        font-weight: bold;
        font-size: 1rem;
      }

      input {
        border: 0;
        outline: none;
        margin: 0 1rem;
        padding: 4px;
        border-radius: 6px;
        font-size: 1rem;
        border: 2px solid var(--blue-a18);
        transition: border 0.2s;
      }

      input:focus {
        border: 2px solid var(--blue-emsoft);
      }

      button {
        padding: 4px;
        border-radius: 6px;
        font-weight: bold;
        width: 100px;
        font-size: 1rem;
        color: var(--blue-a1);
        background: var(--blue-a18);
        transition: color 0.2s, background-color 0.2s;
      }

      button:hover,
      button:focus {
        color: var(--blue-a18);
        background: var(--blue-a1);
      }
    }
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 1.5rem;
    }
    .searchTable {
      margin-top: 40px;
      grid-row: 2;

      label {
        margin-top: 10px;
      }
      form {
        display: flex;
        flex-direction: column;
        /* gap: 5px; */
        input {
          margin-left: 0px;
          margin-bottom: 5px;
          width: 100%;
        }
      }
    }
    .tableContainer {
      margin-top: 60px;
    }
  }
`;
