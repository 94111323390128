export const testeDataSintetico = [
  {
    COD_PRODUTOR: 1,
    COD_FAZENDA: 1,
    COD_LOTE: 1000,
    DATA: "01/01/2000",
    DOC: 99999,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    COD_FAZENDA: 1,
    COD_LOTE: 1000,
    DATA: "01/01/2000",
    DOC: 999991,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    COD_FAZENDA: 2,
    COD_LOTE: 1001,
    DATA: "01/01/2000",
    DOC: 999992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 2,
    COD_FAZENDA: 3,
    COD_LOTE: 1002,
    DATA: "01/01/2000",
    DOC: 999993,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 2,
    COD_FAZENDA: 3,
    COD_LOTE: 1002,
    DATA: "01/01/2000",
    DOC: 999994,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 2,
    COD_FAZENDA: 4,
    COD_LOTE: 1003,
    DATA: "01/01/2000",
    DOC: 999994,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 3,
    COD_FAZENDA: 5,
    COD_LOTE: 1004,
    DATA: "01/01/2000",
    DOC: 999995,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 4,
    COD_FAZENDA: 6,
    COD_LOTE: 1005,
    DATA: "01/01/2000",
    DOC: 999996,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
];

export const testeDataAnalitico = [
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: 1000,
    DATA: "01/01/2000",
    DOC: 99999,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: 1000,
    DATA: "01/01/2000",
    DOC: 999991,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 222222222,
    COD_LOTE: 1001,
    DATA: "01/01/2000",
    DOC: 999992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 2,
    NOM_CLIENTE: "JOAO PAULINO",
    NUM_PR: 3333333333,
    COD_LOTE: 1002,
    DATA: "01/01/2000",
    DOC: 999993,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 2,
    NOM_CLIENTE: "JOAO PAULINO",
    NUM_PR: 3333333333,
    COD_LOTE: 1002,
    DATA: "01/01/2000",
    DOC: 999994,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 2,
    NOM_CLIENTE: "JOAO PAULINO",
    NUM_PR: 444444444,
    COD_LOTE: 1003,
    DATA: "01/01/2000",
    DOC: 999994,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 3,
    NOM_CLIENTE: "JOSE PAULINO",
    NUM_PR: 55555555555,
    COD_LOTE: 1004,
    DATA: "01/01/2000",
    DOC: 999995,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 3,
    NOM_CLIENTE: "JOSE PAULINO",
    NUM_PR: 666666666,
    COD_LOTE: 1005,
    DATA: "01/01/2000",
    DOC: 999996,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 4,
    NOM_CLIENTE: "NORMA PAULINO",
    NUM_PR: 666666666,
    COD_LOTE: 1006,
    DATA: "01/01/2000",
    DOC: 999997,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
];

export const testeDataLote = [
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 222222,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 333333,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 222222222,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 444444,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 3333333333,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 555555555,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 3333333333,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 999994,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 444444444,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 999994,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 55555555555,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 999995,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 666666666,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 999996,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
  {
    COD_PRODUTOR: 1,
    NOM_CLIENTE: "JEAN PAULINO",
    NUM_PR: 1111111111,
    COD_LOTE: "CDT/332/MKC",
    DATA: "01/01/2000",
    DOC: 21101992,
    SACAS_ENTRADA: 100,
    PESO_ENTRADA: 500,
    SACAS_DESPEJO: 100,
    PESO_DESPEJO: 500,
    SACAS_RESULTADO: 100,
    PESO_RESULTADO: 500,
    SACAS_SAIDA: 100,
    PESO_SAIDA: 500,
    SACAS_SALDO: 100,
    PESO_SALDO: 500,
  },
];
