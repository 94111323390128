function datasetGraphicsProcessosFin(entrada, saida, preparo, liga) {
  const datasets = [
    {
      type: 'bar',
      label: 'Entrada',
      backgroundColor: '#1ecfd6',
      data: entrada,
    },
    {
      type: 'bar',
      label: 'Saída',
      backgroundColor: '#003d73',
      data: saida,
    },
    {
      type: 'bar',
      label: 'Preparo',
      backgroundColor: '#c05640',
      data: preparo,
    },
    {
      type: 'bar',
      label: 'Liga',
      backgroundColor: '#edd170',
      data: liga,
    },
  ]
  return datasets
}

function datasetCardsProcessosFin(totalSacas, totalDocs, media) {
  return { totalSacas, totalDocs, media }
}

// LINK Funcões para cada tipo de analise utilizadas na função graphicCargaDescarca

const AnaliseProcessosFin = ({ api }) => {
  // variaveis do gráfico
  const total = {
    entrada: [],
    saida: [],
    preparo: [],
    liga: [],
  }

  let labels = []
  const totalEntrada = {
    diversos: 0,
  }
  const totalSaida = {
    diversos: 0,
  }
  const totalPreparo = {
    diversos: 0,
  }
  const totalLiga = {
    diversos: 0,
  }

  // Variaveis do Cards
  const totalSacas = 0
  const totalDocs = 0
  const media = 0

  if (api) {
    labels = api.reduce((acc, item) => {
      if (!acc.includes(item.NOM_MODALIDADE) && item.NOM_MODALIDADE) {
        acc.push(item.NOM_MODALIDADE)
      }
      return acc
    }, [])

    labels.map((label) => {
      totalEntrada[label] = 0
      totalSaida[label] = 0
      totalPreparo[label] = 0
      totalLiga[label] = 0
      api.map((item) => {
        if (item.NOM_MODALIDADE === label) {
          if (item.FLG_MOVIMENTO === 'E') {
            totalEntrada[label] += item.NUM_SACAS
          }

          if (item.FLG_MOVIMENTO === 'S') {
            totalSaida[label] += item.NUM_SACAS
          }

          if (item.FLG_MOVIMENTO === 'P') {
            totalPreparo[label] += item.NUM_SACAS
          }
          if (item.FLG_MOVIMENTO === 'R') {
            totalLiga[label] += item.NUM_SACAS
          }
        }
        return api
      })

      total.entrada.push(totalEntrada[label])
      total.saida.push(totalSaida[label])
      total.preparo.push(totalPreparo[label])
      total.liga.push(totalLiga[label])

      totalEntrada[label] = 0
      totalSaida[label] = 0
      totalPreparo[label] = 0
      totalLiga[label] = 0

      return label
    })

    api.map((item) => {
      if (item.NOM_MODALIDADE === null) {
        if (item.FLG_MOVIMENTO === 'E') {
          totalEntrada.diversos += item.NUM_SACAS
        }

        if (item.FLG_MOVIMENTO === 'S') {
          totalSaida.diversos += item.NUM_SACAS
        }

        if (item.FLG_MOVIMENTO === 'P') {
          totalPreparo.diversos += item.NUM_SACAS
        }
        if (item.FLG_MOVIMENTO === 'R') {
          totalLiga.diversos += item.NUM_SACAS
        }
      }
      return item
    })

    total.entrada.push(totalEntrada.diversos)
    total.saida.push(totalSaida.diversos)
    total.preparo.push(totalPreparo.diversos)
    total.liga.push(totalLiga.diversos)

    totalEntrada.diversos = 0
    totalSaida.diversos = 0
    totalPreparo.diversos = 0
    totalLiga.diversos = 0
  }
  api.length > 1 ? labels.push('NÃO INFORMADO') : labels.push('')

  // opcoes de estilo do gráfico
  const { entrada, saida, preparo, liga } = total
  const datasets = datasetGraphicsProcessosFin(entrada, saida, preparo, liga)
  const datacards = datasetCardsProcessosFin(totalSacas, totalDocs, media)

  return { labels, datasets, datacards }
}

const CardProcessosFin = ({ api }) => {
  // Variaveis do Cards
  let totalSacas = 0
  let totalDocs = 0
  let media = 0

  if (api) {
    api.map((item) => {
      totalSacas += item.NUM_SACAS
      totalDocs += 1
      return item
    })
  }
  media = totalSacas === 0 ? 0 : (totalSacas / totalDocs).toFixed(2)

  const datacards = datasetCardsProcessosFin(totalSacas, totalDocs, media)

  return { datacards }
}

export const DataProcessosFinalizados = {
  AnaliseProcessosFin,
  CardProcessosFin,
}
