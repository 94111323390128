import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    //position:absolute;
    top: 50%;
    left: 50%;
    width: 6.25em;
    height: 3.125em;
    //margin:-1.562em 0 0 -3.125em;
  }
  .led {
    fill: none;
    stroke: #030f14;
    opacity: 0;
  }
  .one {
    animation: blip 1s ease-in-out 0s infinite alternate;
  }
  .two {
    animation: blip 1s ease-in-out 0.1s infinite alternate;
  }
  .three {
    animation: blip 1s ease-in-out 0.2s infinite alternate;
  }
  .four {
    animation: blip 1s ease-in-out 0.3s infinite alternate;
  }
  @keyframes blip {
    0% {
      stroke-width: 0;
      opacity: 0;
    }
    50% {
      stroke-width: 20;
      opacity: 1;
    }
    100% {
      stroke-width: 0;
      opacity: 0;
    }
  }
`;
