import { apiDecryptURL } from "../utils/webService";

export const getDataLote = async (
  dono,
  user,
  password,
  empresa,
  filial,
  lote,
  typeMoviment = "X"
) => {
  const api = apiDecryptURL();
  const url = "/WebService.asmx/View_Ret_Arvore_LoteJSon";
  const auth = `Dono=${dono}&Usuario=${user}&Senha=${password}&Empresa=${empresa}&Filial=${filial}&Lote=${lote}&AscDesc=${typeMoviment}`;
  const { data } = await api.post(url, auth);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};
