import React from 'react'
import CircleAnalisePesagemRodoviaria from './CircleAnalisePesagemRodoviaria'
import { Container } from './style'

// Componente que cria os Cards das Análises / Armazém
// => Pesagem Rodoviária / Processos Finalizados

const CardAnalisePesagemRodoviaria = ({
  percent,
  nameCard,
  totalSacas,
  totalPesagens,
  classCard,
  percentArray,
}) => {
  return (
    <Container percentArray={percentArray}>
      <div className="CardAnalise">
        <div className="skill">
          <div className="outer">
            <div className="inner">
              <div id="number">{percent}%</div>
            </div>
          </div>
          <CircleAnalisePesagemRodoviaria
            strokeDashoffset={percent}
            className={classCard}
          />
        </div>
        <div>
          <div className="dadosCard">{nameCard}</div>
          <div className="dadosCard">{totalSacas} sacas</div>
          <div className="dadosCard">{totalPesagens} pesagens</div>
        </div>
      </div>
    </Container>
  )
}

export default CardAnalisePesagemRodoviaria
