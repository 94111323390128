import styled from "styled-components";

export const Container = styled.div`
  button:disabled {
    opacity: 0.5;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 8.75rem;
    width: 250px;
    border-radius: 0.25rem;
    background: var(--blue-a1);
    position: relative;
    float: left;
  }
  .card_info {
    display: flex;
    align-content: center;
    justify-content: space-around;
  }

  .card_info span {
    font-size: 2.25rem;
    font-weight: 600;
    color: var(--blue-a13);
  }

  .card_info span p {
    text-align: right;
    font-size: 1rem;
    font-weight: 400;
    color: var(--gray-c3);
  }

  .card_button button {
    border: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blue-a1);
    font-size: 1rem;
    transition: all 0.3s ease-out;
  }

  .card_button button img {
    margin-left: 1rem;
  }
`;
