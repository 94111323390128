import CryptoJS from "crypto-js";

export function encrypt(msg) {
  const key = process.env.REACT_APP_PASS_CRYPTO;
  return CryptoJS.AES.encrypt(msg, key).toString();
}

function decrypt(msgEncrypt) {
  const key = process.env.REACT_APP_PASS_CRYPTO;
  const msgDecrypt = CryptoJS.AES.decrypt(msgEncrypt, key);
  const originalMsg = msgDecrypt.toString(CryptoJS.enc.Utf8);
  return originalMsg;
}

export function sessionStorageDecrypt(session) {
  const infoSession = sessionStorage.getItem(session);
  const decryptInfoSession = infoSession && decrypt(infoSession);
  return decryptInfoSession && JSON.parse(decryptInfoSession);
}
