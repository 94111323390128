import styled from "styled-components";

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--blue-a18);

  p {
    color: var(--blue-a18);
    font-weight: bold;
  }

  img {
    cursor: pointer;
    transition: all ease 0.3s;
    margin-right: 0.5rem;

    :hover {
      opacity: 0.6;
    }
  }

  .headerModal {
    margin-left: 0.5rem;
  }
`;
