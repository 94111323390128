import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem;

  .success {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }

  .headerModal {
    font-size: 1.5rem;
  }

  @media (max-width: 700px) {
    .headerModal {
      font-size: 1rem;
    }
  }
`;
export const Form = styled.form`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-direction: column;

  div {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 1rem;

    label {
      font-weight: bold;
    }

    small {
      color: red;
    }
  }

  button {
  }
`;

export const Input = styled.input`
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 16px;
  background-color: var(--blue-a2);
  border: ${(props) => (props.$invalid ? "1px solid red" : "1px solid #030F14")};
  outline: none;

  :focus {
    border: 2px solid var(--blue-emsoft);
  }
`;

export const Button = styled.button`
  padding: 0.5rem;
  max-width: 12rem;
  min-width: 12rem;
  border-radius: 0.25rem;
  font-size: 16px;
  color: var(--blue-a1);
  background: ${(props) => (props.$success ? "#0093DD" : "#030F14")};
  font-weight: bold;
  border: 1px solid var(--blue-a18);
  outline: none;
  appearance: none;
  transition: all ease 0.3s;
  align-self: ${(props) => (props.$success ? "" : "center")};

  :hover {
    background: var(--blue-a13);
  }
`;
