import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;

  button {
    width: 100px;
    background-color: var(--blue-a18);
    color: #fff;
    padding: 2px;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 4px;
    outline: none;
    cursor: pointer;

    :hover {
      background-color: var(--blue-a13);
      color: var(--gray-c3);
    }
  }
`;
