import { useContext } from "react";
import { VinculaContext } from "../../../../../../contexts/VincularProdutores/VinculaContext";
import { AuthContext } from "../../../../../../contexts/Auth/AuthContext";
import { createUpdateDeleteUsersApp } from "../../../../../../services/apiPortalProdutor";
import { getUserId } from "../../../../../../utils/functions";

const AddPermission = ({ children, id, name, linking = true }) => {
  const { setUserId, setStatusVinculaModal } = useContext(VinculaContext);
  const { dono, userAuth, password } = useContext(AuthContext);

  const handleAddPermission = async () => {
    if (linking) {
      setUserId({ id, name });
      setStatusVinculaModal(true);
    } else {
      const isUserExist = await createUpdateDeleteUsersApp(
        dono,
        userAuth,
        password,
        "C",
        "0",
        id,
        "",
        "",
        "",
        "",
        "S"
      );

      const userIdResult = getUserId(isUserExist);
      setUserId({ id: userIdResult, name });
      setStatusVinculaModal(true);
    }
  };

  return (
    <div style={{ cursor: "pointer" }} onClick={handleAddPermission}>
      {children}
    </div>
  );
};

export default AddPermission;
