import React from "react";
import SelectFilter from "../../../../components/Layouts/SelectFilter/SelectFilter";

const RtEstoqueFisico = ({ produtores, handleSelectProdutor }) => {
  return (
    <>
      <SelectFilter options={produtores} onChange={handleSelectProdutor} />
    </>
  );
};

export default RtEstoqueFisico;
