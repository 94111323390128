import React from "react";
import Select from "react-select-virtualized";
import { ContainerSelect } from "./styles";

/**
 * Select
 * @param {object} props
 * @param {[object]} props.options
 * @param {function} props.onChange
 */

// Select usado no Análises / Armazém
// => Esta sendo usado para selecionar a Análise e o tipo de Análise

export default function SelectFilter({ options, onChange }) {
  return (
    <ContainerSelect>
      <Select
        options={options}
        onChange={onChange}
        classNamePrefix="react-select"
        placeholder="Selecione"
      />
    </ContainerSelect>
  );
}
