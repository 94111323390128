import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .textFazenda {
    margin: 0;
    padding: 0;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
  }

  .messageFazenda {
    color: red;
  }
`;

export const Form = styled.form`
  .content {
    overflow-y: auto;
    height: 250px;
    .contentInfo {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0.5rem 0;
      &:nth-last-child(even) {
        border-bottom: 1px solid gray;
        border-top: 1px solid gray;
      }
      .nomFazenda {
        font-weight: bold;
      }
    }
  }

  .fazendaInfo {
    .fazendaLabel {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      cursor: pointer;
      input {
        flex: 0;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }

  .actions {
    align-self: center;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  @media (max-width: 700px) {
  }
`;

export const Button = styled.button`
  width: 6rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 16px;
  color: ${(props) => (props.$cancel ? "red" : "#F1F3F4")};
  background-color: ${(props) => (props.$cancel ? "#F1F3F4" : "#030F14")};
  font-weight: bold;
  border: 1px solid ${(props) => (props.$cancel ? "red" : "#030F14")};
  outline: none;
  appearance: none;
  transition: all ease 0.3s;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  :hover {
    background-color: ${(props) => (props.$cancel ? "#DBDBDB" : "#195067")};
  }
`;
