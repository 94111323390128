import styled from "styled-components";

export const Container = styled.div`
  background: var(--blue-a3);
  grid-area: main;
  display: grid;
  overflow-y: auto;
  align-items: start;
  align-content: start;

  .icon-calendar {
    display: flex;
    justify-self: end;
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .information-calendar {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.05rem;
    font-size: 0.7rem;
    width: 100%;
  }

  .main_cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, 240px);
    justify-content: space-evenly;
    gap: 1.1rem;
    margin: 0.5rem 0rem 1rem 0rem;

    .card_entrada {
      border: 1px solid var(--green-entry);
      width: 240px;

      .button_entrada {
        background: var(--green-entry);

        :hover {
          background-color: #00370f;
        }
      }
    }

    .card_saida {
      border: 1px solid var(--red-output);
      width: 240px;

      .button_saida {
        background: var(--red-output);

        :hover {
          background-color: #4e070e;
        }
      }
    }

    .card_preparo {
      border: 1px solid var(--yellow-preparation);
      width: 240px;

      .button_preparo {
        background: var(--yellow-preparation);

        :hover {
          background-color: #5e4404;
        }
      }
    }

    .card_reensaque {
      border: 1px solid var(--blue-repack);
      width: 240px;

      .button_reensaque {
        background: var(--blue-repack);

        :hover {
          background-color: #00080c;
        }
      }
    }
  }

  .main_table {
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    margin: 0.5rem 1.5rem 1.875rem 1.5rem;
  }

  @media only screen and (max-width: 1330px) {
    .main_cards {
      gap: 0.5rem;
    }
    .main_cards .card_entrada {
      width: 230px;
    }
    .main_cards .card_saida {
      width: 230px;
    }
    .main_cards .card_preparo {
      width: 230px;
    }
    .main_cards .card_reensaque {
      width: 230px;
    }
  }

  @media only screen and (max-width: 1250px) {
    .main_cards {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media only screen and (max-width: 500px) {
    .main_cards {
      display: grid;
      grid-template-columns: 1fr;
      justify-self: center;
    }
    .main_cards .card_entrada {
      width: 300px;
    }
    .main_cards .card_saida {
      width: 300px;
    }
    .main_cards .card_preparo {
      width: 300px;
    }
    .main_cards .card_reensaque {
      width: 300px;
    }
  }
`;
