import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  border: 2px solid var(--blue-a15);
  border-radius: 4px;
  padding: 8px;
  gap: 4px;
  width: 230px;
  height: 120px;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 1rem;
  .CardProcessosFinalizados {
    color: white;
    .totalCardProcessosFinalizados {
      font-size: 2rem;
    }
  }
  .IconCardProcessosFinalizados {
  }
`
