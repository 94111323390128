import styled from "styled-components";

export const Button = styled.button`
  border: none;
  outline: none;
  transition: all ease 0.3s;
  background-color: inherit;

  :hover {
    opacity: 0.5;
  }
`;
