import React from "react";
import { Container } from "./style";

const InputBuscaLote = ({ id, label, type, onChange, placeholder, value }) => {
  return (
    <Container>
      <label htmlFor={id}>{label}</label>
      <input type={type} id={id} onChange={onChange} placeholder={placeholder} value={value} />
    </Container>
  );
};

export default InputBuscaLote;
