import styled from "styled-components";

export const Container = styled.div`
  button:disabled {
    opacity: 0.5;
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* justify-content: center; */
    height: 9rem;
    width: 250px;
    border-radius: 0.25rem;
    background: var(--blue-a2);
    position: relative;
    float: left;
    text-align: center;
    h4 {
      margin-top: 5px;
    }
  }

  .card_info {
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
  }

  .card_info span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--blue-a13);
  }

  .card_info .contas {
    transform: scale(1);
    transition: all ease 0.3s;
    cursor: pointer;
  }

  .card_info .contas:hover {
    transform: scale(1.1);
  }

  .card_info span p {
    font-size: 0.7rem;
    font-weight: 600;
    color: var(--gray-c3);
  }

  .info_values {
    display: flex;
    flex-direction: column;
    hr {
      width: 100%;
      height: 2px;
      background-color: var(--gray-c8);
    }
  }

  .info_values span {
    margin: 3px 0px;
  }

  .card_banco {
    span {
      display: inline-block;
    }
    p {
      margin-bottom: 8px;
    }
  }

  .card_button button {
    border: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blue-a1);
    font-size: 1rem;
    transition: all 0.3s ease-out;
  }

  .card_button button img {
    margin-left: 1rem;
  }
`;
