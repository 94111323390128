import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .msgError {
    text-align: center;
  }

  .textNotification {
    color: var(--blue-a18);
    text-align: center;
    margin-top: 1rem;
  }

  .swithNotification {
    display: grid;
    grid-template-columns: repeat(${(props) => props.countColuns}, 1fr);
    padding: 20px;
  }

  @media (max-width: 700px) {
    justify-content: start;
    .textNotification {
      font-size: 1.4rem;
      margin-top: 0;
      padding: 0;
    }
    .swithNotification {
      height: 70vh;
      overflow-y: scroll;
      grid-template-columns: 1fr;
    }
  }

  .buttonSendNotification {
    width: 230px;
    background-color: var(--blue-a18);
    color: #fff;
    padding: 8px;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.2rem;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: all 0.4s ease-out;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    :hover {
      background-color: var(--blue-a13);
      color: var(--gray-c3);
    }
  }
`;
