import React from 'react'
import moment from 'moment'
import { dayToday } from '../../../../utils/dates'
import { convertBRL } from '../../../../utils/convertBRL'
import { limitCaracter } from '../../../../utils/style'

function styleTable(color) {
  return [
    {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    {
      height: '8px',
      width: '4px',
      marginRight: '4px',
      background: color,
      borderRadius: '4px',
    },
  ]
}

export const columnsAberto = [
  {
    Header: 'Cliente',
    accessor: 'NOM_CADASTRO',
    Cell: ({ cell: { value } }) => <p style={limitCaracter()}>{value}</p>,
  },
  {
    Header: 'Tipo Cliente',
    accessor: 'TIPO_CLIENTE',
    Cell: ({ cell: { value } }) => value,
  },
  {
    Header: 'Tipo Doc.',
    accessor: 'COD_TIPODOC',
    Cell: ({ cell: { value } }) => value,
  },
  {
    Header: 'Num. Doc',
    accessor: 'NUM_DOC',
    Cell: ({ cell: { value } }) => value,
  },
  {
    Header: 'Parcela',
    accessor: 'NUM_SEQ',
    Cell: ({ cell: { value } }) => value,
  },
  {
    Header: 'Vencimento',
    accessor: 'DAT_VENC',
    Cell: ({ cell: { value } }) => {
      if (moment(value).isBefore(dayToday)) {
        return (
          <div style={styleTable()[0]}>
            <div style={styleTable('red')[1]}></div>
            <div>{new Date(value).toLocaleDateString()}</div>
          </div>
        )
      } else {
        return (
          <div style={styleTable()[0]}>
            <div style={styleTable('green')[1]}></div>
            <div>{new Date(value).toLocaleDateString()}</div>
          </div>
        )
      }
    },
  },
  {
    Header: 'Valor',
    accessor: 'VAL_PRINCIPAL',
    Cell: ({ cell: { value } }) => convertBRL(value),
  },
]
