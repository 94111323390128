export const OptionsSelect = [
  {
    selecione: [
      {
        value: '',
        label: '',
      },
    ],

    analise: [
      {
        value: 'Carga e Descarga',
        label: 'Carga e Descarga',
      },
      // {
      //   value: "Classificação de Café",
      //   label: "Classificação de Café",
      // },
      // {
      //   value: "Movimentações 24h",
      //   label: "Movimentações 24h",
      // },
      {
        value: 'Pesagem Rodoviária',
        label: 'Pesagem Rodoviária',
      },
      {
        value: 'Processos Finalizados',
        label: 'Processos Finalizados',
      },
      {
        value: 'Instruções de Serviço',
        label: 'Instruções de Serviço',
      },
      {
        value: 'Rastreio de Lotes',
        label: 'Rastreio de Lotes',
      },
    ],
    CargaDescarga: [
      {
        value: 'Tempo Médio por Sacas',
        label: 'Tempo Médio por Sacas',
      },
      {
        value: 'Tempo Médio por Tickets',
        label: 'Tempo Médio por Tickets',
      },
      {
        value: 'Sacas por Controle',
        label: 'Sacas por Controle',
      },
      {
        value: 'Pesagem por Embalagem',
        label: 'Pesagem por Embalagem',
      },
      {
        value: 'Sacas por Tipo de Caminhão',
        label: 'Sacas por Tipo de Caminhão',
      },
      {
        value: 'Sacas por Controle e Tipo de Caminhão',
        label: 'Sacas por Controle e Tipo de Caminhão',
      },
    ],
    ClassificacaoCafe: [
      {
        value: 'Tipo de Peneira',
        label: 'Tipo de Peneira',
      },
      {
        value: 'Tipo de Café',
        label: 'Tipo de Café',
      },
      {
        value: 'Tipo de Bebida',
        label: 'Tipo de Bebida',
      },
      {
        value: 'Tipo de Padrão',
        label: 'Tipo de Padrão',
      },
    ],
    Movimentacao24h: [
      {
        value: 'Empilhadeira',
        label: 'Empilhadeira',
        graphic: [
          'Horas Trabalhadas',
          'Qntd. Sacas',
          'Qntd. Lotes',
          'Entradas',
          'Saídas',
          'Despejo',
          'Resultado',
          'Remoção',
          'Entrada + Saída',
          'Despejo + Resultado',
        ],
      },
      {
        value: 'Motoristas',
        label: 'Motoristas',
        graphic: [
          'Horas Trabalhadas',
          'Qntd. Sacas',
          'Qntd. Lotes',
          'Entradas',
          'Saídas',
          'Despejo',
          'Resultado',
          'Remoção',
          'Entrada + Saída',
          'Despejo + Resultado',
        ],
      },
    ],
    PesagemRodoviaria: [
      {
        value: 'Peso por dia',
        label: 'Peso por dia',
      },
      {
        value: 'Retorno por Embalagem',
        label: 'Retorno por Embalagem',
      },
      {
        value: 'Qtd. de Pesagem por Embalagem',
        label: 'Qtd. de Pesagem por Embalagem',
      },
      {
        value: 'Qtd. de Pesagem por Caminhão',
        label: 'Qtd. de Pesagem por Caminhão',
      },
    ],
    Finalizados: [
      {
        value: 'Resumo Geral',
        label: 'Resumo Geral',
      },
    ],
    InstrucaoServicos: [
      {
        value: 'Instruções de Serviço',
        label: 'Instruções de Serviço',
      },
    ],
  },
]
