import styled from 'styled-components'

export const Button = styled.button`
  padding: 0px 4px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--blue-a18);
  color: white;
`
