import React from "react";
import InputBuscaLote from "./InputBuscaLote/InputBuscaLote";
import CalendarPicker from "../../../../components/DataRangePicker/Date";

const RtEstoqueLote = ({ handleDatePicker, id, onChange }) => {
  return (
    <>
      <InputBuscaLote type={"text"} placeholder={"Número do Lote"} onChange={onChange} />
      <CalendarPicker handleDatePicker={handleDatePicker} type={"dataAnalise"} />
    </>
  );
};

export default RtEstoqueLote;
