import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

// Chart usado no Dashboard / Financeiro
// Usado nos Sliders

const options = {
  plugins: {
    legend: {
      position: 'bottom',
      display: true,
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          const datasets = tooltipItem.dataset.data
          let sum = 0
          datasets.map((item) => (sum += item))
          const percentage = Math.round((tooltipItem.parsed / sum) * 100) + '%'
          return ` ${tooltipItem.label}: ${percentage}`
        },
      },
    },
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  offset: true,
  radius: '90%',
}

export default function Chart({ saldoBancos, saldoReceber, saldoPagar }) {
  const data = {
    labels: ['Contas a Receber', 'Contas a Pagar', 'Saldo Banco'],
    datasets: [
      {
        data: [saldoReceber, saldoPagar, saldoBancos],
        backgroundColor: ['#00751F', '#6B0009', '#00080c'],
        borderWidth: 0,
        offset: 15,
        hoverOffset: 25,
      },
    ],
  }
  return <Pie data={data} options={options} />
}
