import { NavLink } from 'react-router-dom'

import { Container } from './styles'

import logoNotFound from '../../assets/img/logo.png'
import Button from '../../components/Layouts/Button/Button'

const NotFound = () => {
  return (
    <Container>
      <img src={logoNotFound} />

      <div className="content">
        <h1>404</h1>
        <p>Erro: Página não encontrada!</p>
        <NavLink to="/">
          <Button>Voltar ao Início</Button>
        </NavLink>
      </div>
    </Container>
  )
}

export default NotFound
