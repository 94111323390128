import React from "react";
import { Routes, Route } from "react-router-dom";

import PrivateRoutes from "./utils/PrivateRoutes";

import DashboardArm from "./Modules/Armazem/Dashboard/DashboardArm";
import DashboardFin from "./Modules/Financeiro/DashboardFin";
import Login from "./pages/login/Login";
import NotFound from "./pages/NotFound/NotFound";
import AnalisesArm from "./Modules/Armazem/Analises/AnalisesArm";
import Home from "./pages/Home/Home";
import AppProdutor from "./Modules/AppProdutor/AppProdutor";
import RelatoriosArm from "./Modules/Armazem/Relatorios/RelatoriosArm";

import ConstructionPage from "./components/Layouts/ConstructionPage/ConstructionPage";
import HistoricoTag from "./Modules/Armazem/HistoricoTag/HistoricoTag";
import RelatoriosFin from "./Modules/Financeiro/Relatorios/RelatoriosFin";
import Contato from "./Modules/Contato/Contato";
import QuadroAvisos from "./Modules/AppProdutor/QuadroAvisos/QuadroAvisos";
import VinculaProdutores from "./Modules/AppProdutor/CadastroProdutor/VinculaProdutores/VinculaProdutores";
import Notifications from "./Modules/AppProdutor/Notificacoes/Notifications";
import ModulosMenu from "./Modules/AppProdutor/ModulosMenu/ModulosMenu";

const Rotas = () => {
  return (
    <>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<Home />} path="/" />
          <Route element={<DashboardFin />} path="financeiro" />
          <Route element={<ConstructionPage />} path="fiscal" />
          <Route element={<ConstructionPage />} path="insumos" />
          <Route element={<ConstructionPage />} path="graos" />
          <Route element={<ConstructionPage />} path="fabrica" />
          <Route element={<ConstructionPage />} path="contabil" />
          <Route element={<DashboardArm />} path="armazem" exact />
          <Route element={<AppProdutor />} path="app-produtor" exact />
          <Route
            element={<VinculaProdutores />}
            path="app-produtor/vincular-produtores"
            exact
          />
          <Route
            element={<Notifications />}
            path="app-produtor/notificacoes"
            exact
          />
          <Route
            element={<ConstructionPage />}
            path="app-produtor/custom-app"
            exact
          />
          <Route
            element={<ModulosMenu />}
            path="app-produtor/modulos-menu"
            exact
          />
          <Route element={<AnalisesArm />} path="armazem/analises" exact />
          <Route
            element={<RelatoriosFin />}
            path="financeiro/relatorios"
            exact
          />
          <Route
            element={<QuadroAvisos />}
            path="app-produtor/quadro-avisos"
            exact
          />
          <Route element={<RelatoriosArm />} path="armazem/relatorios" exact />
          <Route element={<HistoricoTag />} path="armazem/tags" exact />
          <Route element={<ConstructionPage />} path="armazem/mapa" exact />
          <Route element={<Contato />} path="/contato" exact />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Rotas;
