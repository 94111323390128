import Tree from 'react-d3-tree'
import { Container } from './style'
import { ReactComponent as IconPLus } from '../../assets/Icons Analise/icon-mais.svg'
import { ReactComponent as IconNegative } from '../../assets/Icons Analise/icon-menos.svg'
import { useEffect, useState } from 'react'

function returnTypeDocument(flag) {
  switch (flag) {
    case 'T':
      return 'TRA'

    case 'E':
      return 'NF'

    case 'NDE':
      return 'NF'

    case 'R':
      return 'IS'

    default:
      return 'Outros'
  }
}

function createDataTree(array) {
  const dataRes = array.map((item, index) => {
    const controleIndice =
      item.NUM_INDICE < 0 ? Math.abs(item.NUM_INDICE) : item.NUM_INDICE

    const mov = returnTypeDocument(item.FLG_TIPO_DOCUMENTO)

    if (item.NUM_INDICE === 0) {
      return {
        id: index,
        parentId: null,
        name: item.COD_LOTE,
        attributes: {
          nota: `${mov}: ${item.NUM_NOTA}`,
          client: item.NOM_CADASTRO,
          ala: item.NOM_ALAS,
        },
      }
    } else {
      return {
        id: index,
        parentId: controleIndice - 1,
        name: item.COD_LOTE,
        attributes: {
          nota: `${mov}: ${item.NUM_NOTA}`,
          client: item.NOM_CADASTRO,
          ala: item.NOM_ALAS,
        },
      }
    }
  })

  const idMapping = dataRes.reduce((acc, el, i) => {
    acc[el.id] = i
    return acc
  }, {})

  let root
  dataRes.forEach((el) => {
    // Handle the root element
    if (el.parentId === null) {
      root = el
      return
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = dataRes[idMapping[el.parentId]]
    // Add our current el to its parent's `children` array
    parentEl.children = [...(parentEl.children || []), el]
  })

  return root
}

function mountObjectTree(tree) {
  // const data = [
  //   { id: 0, parentId: null, name: "P11826/2" },
  //   { id: 1, parentId: 0, name: "P11825/2" },
  //   { id: 2, parentId: 1, name: "P11847/2" },
  //   { id: 3, parentId: 1, name: "P11825/2" },
  //   { id: 4, parentId: 1, name: "213317/A" },
  // ];

  if (tree) {
    const filterAscendente = tree
      .filter((item) => item.NUM_INDICE <= 0)
      .reverse()

    const filterDescendente = tree.filter((item) => item.NUM_INDICE >= 0)

    const treeAscendente = createDataTree(filterAscendente)
    const treeDescendente = createDataTree(filterDescendente)

    return { treeAscendente, treeDescendente }
  }
}

const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
}) => (
  <g>
    <circle r={0}></circle>
    {/* `foreignObject` requires width & height to be explicitly set. */}
    <foreignObject {...foreignObjectProps}>
      <Container>
        <h3 className="boxLote">{nodeDatum.name}</h3>

        <>
          <div className="boxTree">
            <p className="boxType">{nodeDatum.attributes.nota}</p>
            <p className="boxName">{nodeDatum.attributes.client}</p>
            <p className="boxAla">
              Alas: {nodeDatum.attributes.ala ? nodeDatum.attributes.ala : '-'}
            </p>
          </div>
          <div className="boxButton">
            {nodeDatum.children && (
              <button onClick={toggleNode}>
                {nodeDatum.__rd3t.collapsed ? <IconPLus /> : <IconNegative />}
              </button>
            )}
          </div>
        </>
      </Container>
    </foreignObject>
  </g>
)

export default function LotesTree({ tree, renderTree }) {
  const [lotes, setLotes] = useState({
    id: '',
    parentId: '',
    name: '',
    attributes: {
      nota: '',
      client: '',
    },
  })
  const [width, setWidth] = useState((window.innerWidth * 87) / 100 / 2)
  const [center, setCenter] = useState({ x: width, y: 120 })

  const nodeSize = { x: 250, y: 300 }
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -125,
    y: -100,
  }

  useEffect(() => {
    if (tree) {
      const { treeAscendente, treeDescendente } = mountObjectTree(tree)
      if (renderTree === 'Ascendente') {
        setWidth((window.innerWidth * 87.0001) / 100 / 2)
        setCenter({ x: width, y: 110 })
        setLotes(treeAscendente)
      } else {
        setWidth((window.innerWidth * 87) / 100 / 2)
        setCenter({ x: width, y: 110 })
        setLotes(treeDescendente)
      }
    }
  }, [tree, renderTree, width])

  return (
    <div
      id="treeWrapper"
      style={{
        maxWidth: '100%',
        height: '99%',
      }}
    >
      <Tree
        data={lotes}
        orientation="vertical"
        translate={center}
        depthFactor={renderTree === 'Ascendente' ? -300 : false}
        nodeSize={nodeSize}
        pathFunc={'step'}
        separation={{ siblings: 1.1, nonSiblings: 500 }}
        renderCustomNodeElement={(rd3tProps) =>
          renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
        }
        dimensions={
          renderTree === 'Ascendente'
            ? { height: 820, width: 1200 }
            : { height: 220, width: 1200 }
        }
        initialDepth={false}
      />
    </div>
  )
}
