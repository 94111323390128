import { limitCaracter } from '../../../../utils/style'

export const COLUMNS = [
  {
    Header: 'Movimento',
    accessor: 'FLG_MOVIMENTO',
  },
  {
    Header: 'Data',
    accessor: 'DAT_PREVISAO',
    Cell: ({ cell: { value } }) => new Date(value).toLocaleDateString(),
    sortType: 'datetime',
  },

  {
    Header: 'Cliente',
    accessor: 'NOM_CLIENTE',
    Cell: ({ cell: { value } }) => <p style={limitCaracter()}>{value}</p>,
  },
  {
    Header: 'Documento',
    accessor: 'NUM_DOCUMENTO',
  },
  {
    Header: '',
    accessor: 'NOM_COR_STATUS',
    Cell: ({ cell: { value } }) => (
      <div
        style={{
          backgroundColor: `${value}`,
          width: '5px',
          height: '15px',
          borderRadius: '4px',
          marginRight: '-5px',
        }}
      ></div>
    ),
  },

  {
    Header: 'Status',
    accessor: 'NOM_STATUS',
  },
  {
    Header: 'Quantidade',
    accessor: 'NUM_SACAS',
  },
  {
    Header: 'Progresso',
    accessor: 'NUM_PERC_DESCARGA',
  },
]
