import MenuAppProdutor from "../components/Sidebar/MenuAppProdutor/MenuAppProdutor";
import MenuArmazem from "../components/Sidebar/MenuArmazem/MenuArmazem";
import MenuFinanceiro from "../components/Sidebar/MenuFinanceiro/MenuFinanceiro";

export const menuRender = (ambiente, setShowMenu, selected) => {
  switch (ambiente) {
    case "Armazém":
      return <MenuArmazem setShowMenu={setShowMenu} selected={selected} />;
    case "Financeiro":
      return <MenuFinanceiro setShowMenu={setShowMenu} selected={selected} />;
    case "App Produtor":
      return <MenuAppProdutor setShowMenu={setShowMenu} selected={selected} />;
    case "Contato":
      return "";
    default:
      return <p style={{ color: "white" }}>Em desenvolvimento</p>;
  }
};
