import styled from "styled-components";

export const Container = styled.nav`
  background: var(--blue-a3);
  grid-area: nav;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 0 1rem;
  border-bottom: 0.25rem solid var(--blue-a18);

  button {
    outline: none;
    background: none;
    border: none;
    appearance: none;
  }

  .navbar_saudacao h1 {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    margin-left: 1rem;
  }

  .navbar_saudacao h1 img {
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 0.5rem;
  }

  .navbar_saudacao span {
    display: flex;
    color: var(--gray-c3);
    font-size: 0.8rem;
    margin-left: 5.1rem;
    margin-top: -0.7rem;
  }

  .navbar_icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 10rem;
  }

  .navbar_icons Link {
  }

  @media (max-width: 1200px) {
    .navbar_saudacao h1 {
      margin-left: 50px;
      margin-bottom: 10px;
    }
    .navbar_saudacao h1 img {
      display: none;
    }
    .navbar_saudacao span {
      margin-left: 50px;
    }
  }

  @media only screen and (max-width: 978px) {
    .nav_icon {
      display: inline;
    }
  }

  @media only screen and (max-width: 480px) {
    .navbar_saudacao h1 {
      font-size: 0.65rem;
    }
    .navbar_saudacao span {
      font-size: 0.5rem;
      gap: 2px;
      margin-left: 3.2rem;
    }
    .navbar_icons {
      gap: 7px;
    }
  }
`;
