import calendarIcon from '../../../assets/Icons-Modal/icon-calendar.svg'
import { Button } from './style'

// Button do Calendário(icone do Calendar) / Armazém
// Usado no Dashboard / Análises

export default function ButtonCalendar({ handleShowModalCalendar, control }) {
  return (
    <>
      <Button onClick={handleShowModalCalendar} disabled={control}>
        <img src={calendarIcon} alt="" />
      </Button>
    </>
  )
}
