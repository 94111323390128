import React from 'react'

// dependências:
// AnaliseArm => É onde é renderizado os gráficos e a table
// settingsAnalises => É onde é importado as funções de cálculo para exibir nos gráficos
// tables => É usado para renderizar ou não a table na Análise
// TableProcessosFinalizados => É usado caso haja a necessidade de alterar a estrutura da table
// Columns => É usado para importar, exibir e fazer cálculos nos dados da table

const PesagemRodoviaria = ({
  value,
  renderGraphic,
  datasetOneGraphic,
  loading,
  height,
  width,
  responsive,
}) => {
  return (
    <>
      {!loading && (
        <div className="chart-diario">
          <div className="diario">
            {renderGraphic(
              value,
              datasetOneGraphic,
              height,
              width,
              false,
              '',
              responsive,
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default PesagemRodoviaria
