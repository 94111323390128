import { useState, useContext } from "react";
import moment from "moment";
import { sessionStorageDecrypt } from "../../../../utils/crypto";
import { formatDateWithHours } from "../../../../utils/dates";
import { createUpdateDeleteAvisos } from "../../../../services/apiPortalProdutor";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";
import { AvisosContext } from "../../../../contexts/Avisos/AvisosContext";
import * as S from "./style";
import HeaderModalPortal from "../../../../components/Modal/ModalPortal/HeaderModalPortal";
import Loading from "../../../../components/Layouts/Loading/Loading";

const FormRegisterAvisos = ({ handleRequestCloseFunc, title, id = 0, operacao = "I" }) => {
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [avisoContent, setAvisoContent] = useState("");
  const [erro, setErro] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const { dono, userAuth, password } = useContext(AuthContext);
  const cnpjArmazem = sessionStorageDecrypt("cnpjArmazem");
  const { listAllAvisos } = useContext(AvisosContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const today = moment(new Date()).format("YYYY-MM-DDThh:mm");
    if (moment(dataInicio).isBefore(today)) {
      setErro("Inicial");
      return;
    } else if (moment(dataFim).isBefore(dataInicio)) {
      setErro("Final");
      return;
    } else if (avisoContent.length === 0) {
      setErro("Preencha");
      return;
    }

    setMessage(false);
    setErro(false);
    setLoading(true);

    const dateIniFormat = formatDateWithHours(dataInicio);
    const dateFimFormat = formatDateWithHours(dataFim);
    try {
      await createUpdateDeleteAvisos(
        dono,
        userAuth,
        password,
        operacao,
        cnpjArmazem,
        id,
        dateIniFormat,
        dateFimFormat,
        avisoContent,
        "S"
      );
      setMessage(true);
    } catch (error) {
      console.log(error);
      setErro("Não foi possível cadastrar o aviso! Por favor tente mais tarde.");
    } finally {
      setLoading(false);
    }
  };

  function handleUpdateTable() {
    handleRequestCloseFunc();
    listAllAvisos();
  }

  return (
    <S.Wrapper>
      <HeaderModalPortal handleRequestCloseFunc={handleRequestCloseFunc} title={title} />
      <div className="content">
        {!loading && !message && (
          <S.Form onSubmit={handleSubmit}>
            <div className="dates">
              <div className="date">
                <label htmlFor="date_ini">Data/Hora - Início</label>
                <input
                  required
                  type="datetime-local"
                  id="date_ini"
                  className={erro && erro.includes("Inicial") && "error"}
                  value={dataInicio}
                  onChange={(e) => setDataInicio(e.target.value)}
                />
                {erro && erro.includes("Inicial") && (
                  <small>A data inicial não pode ser anterior a hoje.</small>
                )}
              </div>
              <div className="date">
                <label htmlFor="date_fim">Data/Hora - Fim</label>
                <input
                  required
                  type="datetime-local"
                  id="date_fim"
                  className={erro && erro.includes("Final") && "error"}
                  value={dataFim}
                  onChange={(e) => setDataFim(e.target.value)}
                />
                {erro && erro.includes("Final") && (
                  <small>A data final não pode ser anterior a data inicial.</small>
                )}
              </div>
            </div>

            <div className="inputs">
              <div className="length_content">
                <label htmlFor="content_avisos">Conteúdo do Aviso</label>
                <span>{avisoContent.length}/200</span>
              </div>
              <S.Input
                autoFocus
                id="content_avisos"
                placeholder="Digite o aviso para ser publicado."
                maxLength="200"
                rows="6"
                value={avisoContent}
                $invalid={erro && erro.includes("Preencha")}
                onChange={(e) => setAvisoContent(e.target.value)}
              />
              {erro && erro.includes("Preencha") && <small>Preencha com algum aviso.</small>}
            </div>

            <S.Button>Publicar</S.Button>
          </S.Form>
        )}
        {loading && <Loading />}
        {message && (
          <div className="success">
            <h2>Aviso publicado com SUCESSO!</h2>
            <S.Button $success onClick={handleUpdateTable}>
              ok
            </S.Button>
          </div>
        )}
      </div>
    </S.Wrapper>
  );
};

export default FormRegisterAvisos;
