import styled from 'styled-components'

export const Container = styled.div`
  grid-area: main;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 50px 1fr 1fr;
  overflow-y: auto;

  img {
    margin-top: 20px;
  }

  p {
    font-size: 1.3rem;
    color: var(--blue-emsoft);
    word-break: break-all;
  }

  span {
    font-style: italic;
  }

  .contatoTitulo {
    display: flex;
    grid-column: 1 / -1;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    margin: 0px 20px 10px 20px;
    height: 50px;
    font-size: 1.1rem;
    color: var(--blue-a13);
  }

  .suporte,
  .email,
  .telefone,
  .horario,
  .endereco,
  .site {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    height: 100%;
    width: 100%;
    font-weight: bold;
  }

  .suporte {
    border-right: 2px solid var(--gray-c2);
    border-bottom: 2px solid var(--gray-c2);
  }
  .email {
    border-right: 2px solid var(--gray-c2);
    border-bottom: 2px solid var(--gray-c2);
  }
  .telefone {
    border-bottom: 2px solid var(--gray-c2);
  }
  .horario {
    border-right: 2px solid var(--gray-c2);
  }
  .endereco {
    border-right: 2px solid var(--gray-c2);
  }

  .suporte button {
    margin-top: 20px;
    font-size: 1rem;
    font-weight: 500;
    padding: 10px 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--blue-a18);
    color: white;
  }

  .email a {
    margin-top: 20px;
  }

  .telefone div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
  }

  .horario div,
  .endereco div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
  }

  .site button {
    margin-top: 20px;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--blue-emsoft);
    color: var(--blue-a18);
  }

  @media (max-width: 850px) {
    grid-template-columns: 1fr 1fr;
    .suporte,
    .email,
    .telefone,
    .horario,
    .endereco,
    .site {
      margin-bottom: 20px;
    }
    .telefone {
      border-right: 2px solid var(--gray-c2);
    }
    .horario {
      border-bottom: 2px solid var(--gray-c2);
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    .contatoTitulo {
      font-size: 0.75rem;
    }

    .endereco {
      border-bottom: 2px solid var(--gray-c2);
    }
  }
`
