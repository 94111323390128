import { useState, useContext } from "react";
import { encrypt } from "../utils/crypto";
import { db } from "../services/firebase";
import { doc, getDoc } from "firebase/firestore";
import { cpfCnpjMask, cnpjClear } from "../utils/regex";
import { AuthContext } from "../contexts/Auth/AuthContext";
import { getProdutores } from "../services/apiPortalProdutor";

export const useGetProdutor = () => {
  const { dono, userAuth, password, codEmpresa } = useContext(AuthContext);
  const [dataProdutor, setDataProdutor] = useState(null);
  const [valueCpf, setValueCpf] = useState("");
  const [error, setError] = useState(null);
  const [messageError, setMessageError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cpfProdutor, setCpfProdutor] = useState(null);

  const handleInput = (e) => {
    setDataProdutor(null);
    const { value } = e.target;
    setValueCpf(cpfCnpjMask(value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setDataProdutor(null);
    setValueCpf("");
    if (valueCpf) {
      setCpfProdutor(cnpjClear(valueCpf));

      const cryptCpfProdutor = encrypt(JSON.stringify(cnpjClear(valueCpf)));
      sessionStorage.setItem("idProdutor", cryptCpfProdutor);

      try {
        const res = await getProdutores(
          dono,
          userAuth,
          password,
          codEmpresa,
          cnpjClear(valueCpf)
        );

        if (res.length <= 0)
          throw new Error(`Produtor não encontrado! Deseja fazer o cadastro do cpf ${valueCpf} para acessar o
          aplicativo EMX Produtor?`);

        // let codProdutor

        const produtor = res.reduce(
          (acc, item, index) => {
            acc.nome = item.NOM_CLIENTE;
            acc.dataFaz.push({
              nomeFaz: item.NOM_FAZE,
              ie: item.NUM_PR_FAZ,
              id: index,
              codProdutor: item.COD_CLIENTE,
            });
            // codProdutor = item.COD_CLIENTE
            return acc;
          },
          { nome: "", dataFaz: [] }
        );

        setDataProdutor(produtor);
      } catch ({ name, message, code }) {
        if (code) {
          setError(true);
          setMessageError("Erro ao conectar com o servidor");
        } else {
          setError(true);
          setMessageError(message);
        }
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError(true);
      setMessageError("Digite um CPF ou CNPJ");
    }
  };

  const getProdutorFirebase = async () => {
    setLoading(true);
    setError(false);

    try {
      const docRef = doc(db, "produtores", cpfProdutor);
      const docSnap = await getDoc(docRef);

      return {
        produtorExist: docSnap.exists(),
        dataProdutorFirebase: docSnap.data(),
      };
    } catch (err) {
      setError(true);
      setMessageError(
        "Erro inesperado! Por favor tente mais tarde ou faça login novamente"
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    error,
    messageError,
    loading,
    valueCpf,
    dataProdutor,
    handleInput,
    handleSubmit,
    getProdutorFirebase,
    setError,
  };
};
