import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  flex-direction: column;
  padding: 0.5rem;

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 12px;
  }

  .success {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }

  @media (max-width: 700px) {
    .dates {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
`;

export const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    .length_content {
      display: flex;
      justify-content: space-between;
    }

    span {
      font-size: 12px;
    }

    label {
      font-weight: bold;
    }

    small {
      color: red;
    }
  }

  .dates {
    display: flex;
    justify-content: space-between;

    .error {
      border: 1px solid red;
    }

    input {
      padding: 0.3rem;
      border-radius: 0.25rem;
      font-size: 16px;
      background-color: var(--blue-a2);
      border: 1px solid #030f14;

      :focus {
        border: 2px solid var(--blue-emsoft);
      }
    }

    small {
      color: red;
    }
  }

  .date {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    label {
      font-weight: bold;
    }
  }
`;

export const Input = styled.textarea`
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 16px;
  background-color: var(--blue-a2);
  border: ${(props) => (props.$invalid ? "1px solid red" : "1px solid #030F14")};
  outline: none;

  :focus {
    border: 2px solid var(--blue-emsoft);
  }

  small {
    color: red;
  }
`;

export const Button = styled.button`
  padding: 0.5rem;
  max-width: 12rem;
  min-width: 12rem;
  border-radius: 0.25rem;
  font-size: 16px;
  color: var(--blue-a1);
  background: ${(props) => (props.$success ? "#0093DD" : "#030F14")};
  font-weight: bold;
  border: 1px solid var(--blue-a18);
  outline: none;
  appearance: none;
  transition: all ease 0.3s;
  align-self: ${(props) => (props.$success ? "" : "flex-end")};

  :hover {
    background: var(--blue-a13);
  }
`;
