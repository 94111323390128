export const OptionsSelect = [
  {
    selecione: [
      {
        value: '',
        label: '',
      },
    ],

    analise: [
      {
        value: 'Contas Recebidas',
        label: 'Contas Recebidas',
      },
      {
        value: 'Contas Pagas',
        label: 'Contas Pagas',
      },
      {
        value: 'Contas a Receber',
        label: 'Contas a Receber',
      },
      {
        value: 'Contas a Pagar',
        label: 'Contas a Pagar',
      },
    ],
  },
]
