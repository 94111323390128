import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  float: left;
  background: var(--blue-a1);
  border-radius: 4px;
  padding: 0.1rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .inputSearch {
    display: flex;
    width: 100%;
    padding: 0.2rem;
    align-items: center;
    justify-content: end;
    gap: 10px;

    input {
      background-color: var(--blue-a4);
      padding: 0.5rem;
      border-radius: 4px;
      border-style: none;
      width: 230px;
      outline: none;
    }
    .icon_search {
      position: absolute;
      padding-right: 1rem;
      margin-top: 0.125rem;
    }
  }

  .buttonsPrintCSV {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;

    .button {
      border: none;
      padding: 5px 0px;
      margin: 0;
    }
  }

  table {
    border-collapse: collapse;
  }

  table td,
  table th {
    padding: 0.5rem;
    font-size: 0.75rem;
    :nth-child(n + 9) {
      text-align: center;
    }
  }
  table tr:nth-child(even) {
    background-color: var(--blue-a4);
  }

  table th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    text-align: start;
    background-color: var(--blue-a18);
    color: white;
    font-size: 0.8rem;
    font-weight: 500;
  }

  .sticky-header {
    position: sticky;
    top: 0px;
  }

  .tdIr {
    position: sticky;
    top: 9%;
    padding: 0.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: start;
    color: var(--blue-a1);
    background-color: var(--blue-a13);
  }

  .tr-total {
    background-color: var(--blue-a7);
    font-weight: bold;
  }

  .td-total {
    font-size: 1.2rem;
    padding: 4px;
  }

  .td-totalSacas,
  .td-totalPeso {
    font-size: 0.875rem;
  }

  .totalGeral {
    box-sizing: border-box;
    text-align: center;
    background-color: var(--blue-a18);
    padding: 8px;

    span {
      color: var(--blue-a1);
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  @media (max-width: 750px) {
    table th {
      font-size: 0.7rem;
    }
    .totalGeral {
      width: 100vw;
      background-color: var(--blue-a3);
      span {
        color: var(--blue-a18);
      }
    }
  }

  @media (max-width: 650px) {
    table th {
      font-size: 0.7rem;
    }
    .tdIr {
      top: 6%;
    }
    .pagination_media {
      display: flex;
      flex-wrap: wrap;
    }
    .display_pages {
      display: none;
    }
    .page_direction {
      margin: 0;
    }
    .information_page {
      width: 575px;
    }
    .totalGeral {
      padding: 2px;
      span {
        font-size: 0.8rem;
      }
    }
  }
`;
