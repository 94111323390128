// apiCalls.js

import { getDadosProdutores } from "../../../../services/apiPortalProdutor";

export async function fetchProdutores(dono, userAuth, password, relatorio) {
  try {
    const res = await getDadosProdutores(dono, userAuth, password);
    const cpfMap = {};
    const selectFilter = res.filter((item) => {
      if (!cpfMap[item.NUM_CPFCGC]) {
        cpfMap[item.NUM_CPFCGC] = true;
        return true;
      }
      return false;
    });
    const produtoresData = selectFilter.map((item) => ({
      value: `${item.NOM_CLIENTE.substring(0, 30)} (${item.NUM_CPFCGC})`,
      label: `${item.NOM_CLIENTE.substring(0, 30)} (${item.NUM_CPFCGC})`,
      cpf: item.NUM_CPFCGC,
    }));

    if (relatorio === 2) {
      const updateProdutores = [
        { analise: [{ value: "GERAL", label: "GERAL", cpf: 0 }, ...produtoresData] },
      ];
      return updateProdutores;
    } else {
      const updateProdutores = [{ analise: [...produtoresData] }];
      return updateProdutores;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
}
