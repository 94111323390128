import {
  dayToday,
  lastSevenDay,
  dateToEn,
  dateFirstLast,
  compararDatas,
  dayTodayFormated,
  lastSevenDayFormated,
  convertSecondsInHours,
} from "../../../../../utils/dates";
import moment from "moment/moment";

const quantidade = {
  dia: {
    bigbag: 0,
    granel: 0,
    sacaria: 0,
    outras: 0,
  },
  mes: {
    bigbag: 0,
    granel: 0,
    sacaria: 0,
    outras: 0,
  },
  ano: {
    bigbag: 0,
    granel: 0,
    sacaria: 0,
    outras: 0,
  },
};

const veiculo = {
  dia: {
    cacamba: 0,
    graneleiro: 0,
    outros: 0,
  },
  mes: {
    cacamba: 0,
    graneleiro: 0,
    outros: 0,
  },
  ano: {
    cacamba: 0,
    graneleiro: 0,
    outros: 0,
  },
};

const totalPeriodo = {};

const tempoMedia = {};

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const colors = [
  "#06b6d4",
  "#047857",
  "#be123c",
  "#003d73",
  "#c05640",
  "#edd170",
  `rgb(${getRandomInt(254)},${getRandomInt(254)},${getRandomInt(254)})`,
  `rgb(${getRandomInt(254)},${getRandomInt(254)},${getRandomInt(254)})`,
  `rgb(${getRandomInt(254)},${getRandomInt(254)},${getRandomInt(254)})`,
  `rgb(${getRandomInt(254)},${getRandomInt(254)},${getRandomInt(254)})`,
];

function graphics(dados, controle) {
  if (dados) {
    const datasets = controle.map((controls, index) => ({
      type: "bar",
      label: controls,
      backgroundColor: `${colors[index]}`,
      data: dados[controls],
    }));
    // fazer um map no data(dados) usando o index para percorrer cada elemento e dividir pela quantidade do controls
    return datasets;
  }
}

function datasetGraphicsEmbalagens(bigbag, sacaria, granel, outras) {
  const datasets = [
    {
      type: "bar",
      label: "Granel",
      backgroundColor: "#1ecfd6",
      data: granel,
    },
    {
      type: "bar",
      label: "Bigbag",
      backgroundColor: "#003d73",
      data: bigbag,
    },
    {
      type: "bar",
      label: "Sacaria",
      backgroundColor: "#c05640",
      data: sacaria,
    },
    {
      type: "bar",
      label: "Outras",
      hidden: true,
      backgroundColor: "#edd170",
      data: outras,
    },
  ];
  return datasets;
}

function datasetGraphicsTipoCaminhao(cacamba, graneleiro, outros) {
  const datasets = [
    {
      type: "bar",
      label: "Caçamba",
      backgroundColor: "#1ecfd6",
      data: cacamba,
    },
    {
      type: "bar",
      label: "Graneleiro",
      backgroundColor: "#003d73",
      data: graneleiro,
    },
    {
      type: "bar",
      label: "Outros",
      backgroundColor: "#c05640",
      data: outros,
    },
  ];
  return datasets;
}

function generateLabel(dscControls, apiArray, dateIni, dateFim, typeDate, optionDate) {
  let dateItem;
  const filterLabel = [];
  let label = [];
  dscControls.map((controls) => {
    if (apiArray[controls] !== undefined) {
      apiArray[controls].map((item) => {
        dateItem = new Date(item.DAT_PESO_INI);
        if (
          moment(item.DAT_PESO_INI).isSameOrAfter(dateIni) &&
          moment(item.DAT_PESO_INI).isSameOrBefore(dateFim, typeDate)
        ) {
          dateItem = dateItem.toLocaleString("default", optionDate);
          if (!filterLabel.includes(dateItem)) {
            filterLabel.push(dateItem);
          }
        }
        return null;
      });
    }
    return null;
  });

  label = filterLabel.sort(compararDatas);

  return label;
}

function convertDate(dataAnaliseInicio, dataAnaliseFinal) {
  let date = {};
  if (dataAnaliseInicio === undefined || dataAnaliseFinal === undefined) {
    const dayFinaly = moment(dateToEn(dayTodayFormated));
    const dayOne = moment(dateToEn(lastSevenDayFormated));
    date = { dateBaseIni: dayOne, dateBaseFim: dayFinaly };
  } else {
    const dayFinaly = moment(dateToEn(dataAnaliseFinal));
    const dayOne = moment(dateToEn(dataAnaliseInicio));
    date = { dateBaseIni: dayOne, dateBaseFim: dayFinaly };
  }
  return date;
}

function filterTableAnalise({ api, dataAnaliseInicio, dataAnaliseFinal }) {
  // no caso da table, fazer um map dentro do map
  const optionsMonthDate = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  let newDateIni;
  let newDateFim;

  if (dataAnaliseInicio === undefined || dataAnaliseFinal === undefined) {
    newDateIni = lastSevenDay._d;
    newDateFim = dayToday._d;
  } else {
    newDateIni = moment(dateToEn(dataAnaliseInicio));
    newDateFim = moment(dateToEn(dataAnaliseFinal));
  }

  const filterDataTable = api.filter((item) => {
    if (
      moment(item.DAT_PESO_INI).isSameOrAfter(newDateIni) &&
      moment(item.DAT_PESO_INI).isSameOrBefore(newDateFim, "date")
    ) {
      // função para renderizar na tabela o total e o tempo por sacas
      const time = moment(item.DAT_PESO_FIM).diff(moment(item.DAT_PESO_INI), "seconds");
      const dateInicio = new Date(item.DAT_PESO_INI).toLocaleString(`pt-BR`, optionsMonthDate);
      const dateFim = new Date(item.DAT_PESO_FIM).toLocaleString(`pt-BR`, optionsMonthDate);
      const totalTable = convertSecondsInHours(time);
      const tempoPorSacas = convertSecondsInHours(time / item.NUM_SACAS);

      item.dateInicio = dateInicio;
      item.dateFim = dateFim;
      item.tempoTotal = totalTable;
      item.tempoSacas = tempoPorSacas;

      return item;
    }
    return null;
  });
  return filterDataTable;
}

function tempoMedioSacas({ dataAnaliseInicio, dataAnaliseFinal, apiArray, dscControls }) {
  function calcDataMediaSacas(
    dscControls,
    labels,
    apiArray,
    dateBaseIni,
    dateBaseFim,
    total,
    tempo,
    typeDate,
    optionDate
  ) {
    let dateItem;
    const totalGeral = {};
    dscControls.map((controls) => {
      total[controls] = 0;
      tempo[controls] = 0;
      totalGeral[controls] = [];

      labels.map((label) => {
        apiArray[controls].map((item) => {
          dateItem = new Date(item.DAT_PESO_INI);
          if (
            moment(item.DAT_PESO_INI).isSameOrAfter(dateBaseIni) &&
            moment(item.DAT_PESO_INI).isSameOrBefore(dateBaseFim, typeDate)
          ) {
            dateItem = dateItem.toLocaleString("default", optionDate);

            if (item.DSC_CONTROLE === controls) {
              if (label === dateItem) {
                const segundosInicial = moment(item.DAT_PESO_INI);
                const segundosFinal = moment(item.DAT_PESO_FIM);
                total[controls] += item.NUM_SACAS === 0 ? (item.NUM_SACAS = 1) : item.NUM_SACAS;
                tempo[controls] += segundosFinal.diff(segundosInicial, "seconds");
              }
            }
          }
          return null;
        });

        totalGeral[controls].push((tempo[controls] / total[controls]).toFixed(5));
        total[controls] = 0;
        tempo[controls] = 0;
        return null;
      });
      return null;
    });
    return totalGeral;
  }

  const { dateBaseIni, dateBaseFim } = convertDate(dataAnaliseInicio, dataAnaliseFinal);

  const tempoMedioSacasDia = () => {
    let totalGeral = {};
    let labels;
    const optionsDayDate = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    if (apiArray) {
      labels = generateLabel(
        dscControls,
        apiArray,
        dateBaseIni,
        dateBaseFim,
        "date",
        optionsDayDate
      );

      if (labels.length > 0) {
        totalGeral = calcDataMediaSacas(
          dscControls,
          labels,
          apiArray,
          dateBaseIni,
          dateBaseFim,
          totalPeriodo,
          tempoMedia,
          "date",
          optionsDayDate
        );
      }
    }

    const datasets = graphics(totalGeral, dscControls);

    return { labels, datasets };
  };

  const tempoMedioSacasMes = () => {
    let totalGeral = {};
    let labels = [];
    const optionsMonthDate = {
      month: "2-digit",
      year: "numeric",
    };

    const { firstDay } = dateFirstLast(dateBaseIni, "month");
    const { lastDay } = dateFirstLast(dateBaseFim, "month");

    if (apiArray) {
      labels = generateLabel(dscControls, apiArray, firstDay, lastDay, "", optionsMonthDate);

      if (labels.length > 0) {
        totalGeral = calcDataMediaSacas(
          dscControls,
          labels,
          apiArray,
          firstDay,
          lastDay,
          totalPeriodo,
          tempoMedia,
          "date",
          optionsMonthDate
        );
      }
    }

    const datasets = graphics(totalGeral, dscControls);

    return { labels, datasets };
  };

  const tempoMedioSacasAno = () => {
    let totalGeral = {};
    let labels = [];

    const optionsYearDate = {
      year: "numeric",
    };

    const { firstDay } = dateFirstLast(dateBaseIni, "year");
    const { lastDay } = dateFirstLast(dateBaseFim, "year");
    if (apiArray) {
      labels = generateLabel(dscControls, apiArray, firstDay, lastDay, "", optionsYearDate);

      if (labels.length > 0) {
        totalGeral = calcDataMediaSacas(
          dscControls,
          labels,
          apiArray,
          firstDay,
          lastDay,
          totalPeriodo,
          tempoMedia,
          "date",
          optionsYearDate
        );
      }
    }

    const datasets = graphics(totalGeral, dscControls);

    return { labels, datasets };
  };
  const datasetGraphicDia = tempoMedioSacasDia(apiArray, dataAnaliseInicio, dataAnaliseFinal);
  const datasetGraphicMes = tempoMedioSacasMes(apiArray, dataAnaliseInicio, dataAnaliseFinal);
  const datasetGraphicAno = tempoMedioSacasAno(apiArray, dataAnaliseInicio, dataAnaliseFinal);
  return { datasetGraphicDia, datasetGraphicMes, datasetGraphicAno };
}

function tempoMedioTickets({ dataAnaliseInicio, dataAnaliseFinal, apiArray, dscControls }) {
  function calcDataMediaTickets(
    dscControls,
    labels,
    apiArray,
    dateBaseIni,
    dateBaseFim,
    total,
    tempo,
    typeDate,
    optionDate
  ) {
    let dateItem;
    const totalGeral = {};
    dscControls.map((controls) => {
      total[controls] = 0;
      tempo[controls] = 0;
      totalGeral[controls] = [];

      labels.map((label) => {
        apiArray[controls].map((item) => {
          dateItem = new Date(item.DAT_PESO_INI);
          if (
            moment(item.DAT_PESO_INI).isSameOrAfter(dateBaseIni) &&
            moment(item.DAT_PESO_INI).isSameOrBefore(dateBaseFim, typeDate)
          ) {
            dateItem = dateItem.toLocaleString("default", optionDate);

            if (item.DSC_CONTROLE === controls) {
              if (label === dateItem) {
                const segundosInicial = moment(item.DAT_PESO_INI);
                const segundosFinal = moment(item.DAT_PESO_FIM);
                total[controls] += 1;
                tempo[controls] += segundosFinal.diff(segundosInicial, "seconds");
              }
            }
          }
          return null;
        });

        totalGeral[controls].push((tempo[controls] / total[controls]).toFixed(5));
        total[controls] = 0;
        tempo[controls] = 0;
        return null;
      });
      return null;
    });
    return totalGeral;
  }
  const { dateBaseIni, dateBaseFim } = convertDate(dataAnaliseInicio, dataAnaliseFinal);

  const tempoMedioTicketDia = () => {
    let totalGeral = {};
    let labels;
    const optionsDayDate = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    if (apiArray) {
      labels = generateLabel(
        dscControls,
        apiArray,
        dateBaseIni,
        dateBaseFim,
        "date",
        optionsDayDate
      );

      if (labels.length > 0) {
        totalGeral = calcDataMediaTickets(
          dscControls,
          labels,
          apiArray,
          dateBaseIni,
          dateBaseFim,
          totalPeriodo,
          tempoMedia,
          "date",
          optionsDayDate
        );
      }
    }

    const datasets = graphics(totalGeral, dscControls);

    return { labels, datasets };
  };

  const tempoMedioTicketMes = () => {
    let totalGeral = {};
    let labels = [];
    const optionsMonthDate = {
      month: "2-digit",
      year: "numeric",
    };

    const { firstDay } = dateFirstLast(dateBaseIni, "month");
    const { lastDay } = dateFirstLast(dateBaseFim, "month");
    if (apiArray) {
      labels = generateLabel(dscControls, apiArray, firstDay, lastDay, "", optionsMonthDate);

      if (labels.length > 0) {
        totalGeral = calcDataMediaTickets(
          dscControls,
          labels,
          apiArray,
          firstDay,
          lastDay,
          totalPeriodo,
          tempoMedia,
          "date",
          optionsMonthDate
        );
      }
    }

    const datasets = graphics(totalGeral, dscControls);

    return { labels, datasets };
  };

  const tempoMedioTicketAno = () => {
    let totalGeral = {};
    let labels = [];

    const optionsYearDate = {
      year: "numeric",
    };

    const { firstDay } = dateFirstLast(dateBaseIni, "year");
    const { lastDay } = dateFirstLast(dateBaseFim, "year");
    if (apiArray) {
      labels = generateLabel(dscControls, apiArray, firstDay, lastDay, "", optionsYearDate);

      if (labels.length > 0) {
        totalGeral = calcDataMediaTickets(
          dscControls,
          labels,
          apiArray,
          firstDay,
          lastDay,
          totalPeriodo,
          tempoMedia,
          "date",
          optionsYearDate
        );
      }
    }

    const datasets = graphics(totalGeral, dscControls);

    return { labels, datasets };
  };
  const datasetGraphicDia = tempoMedioTicketDia(apiArray, dataAnaliseInicio, dataAnaliseFinal);
  const datasetGraphicMes = tempoMedioTicketMes(apiArray, dataAnaliseInicio, dataAnaliseFinal);
  const datasetGraphicAno = tempoMedioTicketAno(apiArray, dataAnaliseInicio, dataAnaliseFinal);
  return { datasetGraphicDia, datasetGraphicMes, datasetGraphicAno };
}

function qtdSacasControle({ dataAnaliseInicio, dataAnaliseFinal, apiArray, dscControls }) {
  function calcDataSacasControle(
    dscControls,
    labels,
    apiArray,
    dateBaseIni,
    dateBaseFim,
    total,
    tempo,
    typeDate,
    optionDate
  ) {
    let dateItem;
    const totalGeral = {};
    dscControls.map((controls) => {
      total[controls] = 0;
      tempo[controls] = 0;
      totalGeral[controls] = [];

      labels.map((label) => {
        apiArray[controls].map((item) => {
          dateItem = new Date(item.DAT_PESO_INI);
          if (
            moment(item.DAT_PESO_INI).isSameOrAfter(dateBaseIni) &&
            moment(item.DAT_PESO_INI).isSameOrBefore(dateBaseFim, typeDate)
          ) {
            dateItem = dateItem.toLocaleString("default", optionDate);

            if (item.DSC_CONTROLE === controls) {
              if (label === dateItem) {
                total[controls] += item.NUM_SACAS;
              }
            }
          }
          return null;
        });
        totalGeral[controls].push(total[controls]);
        total[controls] = 0;
        return null;
      });
      return null;
    });
    return totalGeral;
  }
  const { dateBaseIni, dateBaseFim } = convertDate(dataAnaliseInicio, dataAnaliseFinal);
  const qtdSacasControleDia = () => {
    let totalGeral = {};
    let labels;
    const optionsDayDate = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    if (apiArray) {
      labels = generateLabel(
        dscControls,
        apiArray,
        dateBaseIni,
        dateBaseFim,
        "date",
        optionsDayDate
      );

      if (labels.length > 0) {
        totalGeral = calcDataSacasControle(
          dscControls,
          labels,
          apiArray,
          dateBaseIni,
          dateBaseFim,
          totalPeriodo,
          tempoMedia,
          "date",
          optionsDayDate
        );
      }
    }

    const datasets = graphics(totalGeral, dscControls);

    return { labels, datasets };
  };

  const qtdSacasControleMes = () => {
    let totalGeral = {};
    let labels = [];
    const optionsMonthDate = {
      month: "2-digit",
      year: "numeric",
    };

    const { firstDay } = dateFirstLast(dateBaseIni, "month");
    const { lastDay } = dateFirstLast(dateBaseFim, "month");
    if (apiArray) {
      labels = generateLabel(dscControls, apiArray, firstDay, lastDay, "", optionsMonthDate);

      if (labels.length > 0) {
        totalGeral = calcDataSacasControle(
          dscControls,
          labels,
          apiArray,
          firstDay,
          lastDay,
          totalPeriodo,
          tempoMedia,
          "date",
          optionsMonthDate
        );
      }
    }

    const datasets = graphics(totalGeral, dscControls);

    return { labels, datasets };
  };

  const qtdSacasControleAno = () => {
    let totalGeral = {};
    let labels = [];

    const optionsYearDate = {
      year: "numeric",
    };

    const { firstDay } = dateFirstLast(dateBaseIni, "year");
    const { lastDay } = dateFirstLast(dateBaseFim, "year");
    if (apiArray) {
      labels = generateLabel(dscControls, apiArray, firstDay, lastDay, "", optionsYearDate);

      if (labels.length > 0) {
        totalGeral = calcDataSacasControle(
          dscControls,
          labels,
          apiArray,
          firstDay,
          lastDay,
          totalPeriodo,
          tempoMedia,
          "date",
          optionsYearDate
        );
      }
    }

    const datasets = graphics(totalGeral, dscControls);

    return { labels, datasets };
  };

  const datasetGraphicDia = qtdSacasControleDia(apiArray, dataAnaliseInicio, dataAnaliseFinal);
  const datasetGraphicMes = qtdSacasControleMes(apiArray, dataAnaliseInicio, dataAnaliseFinal);
  const datasetGraphicAno = qtdSacasControleAno(apiArray, dataAnaliseInicio, dataAnaliseFinal);
  return { datasetGraphicDia, datasetGraphicMes, datasetGraphicAno };
}

function pesagemEmbalagem({ api, dataAnaliseInicio, dataAnaliseFinal }) {
  const { dateBaseIni, dateBaseFim } = convertDate(dataAnaliseInicio, dataAnaliseFinal);
  function calcDataPesagemEmbalagem(dateIni, dateFim, typeDate, optionDate) {
    const total = {
      bigbag: [],
      sacaria: [],
      granel: [],
      outras: [],
    };

    const labels = [];
    let dateItem;
    let dateArray;

    const newApi = api.filter(
      (item) =>
        moment(item.DAT_PESO_INI).isSameOrAfter(dateIni) &&
        moment(item.DAT_PESO_INI).isSameOrBefore(dateFim, typeDate)
    );
    newApi.map((item, index, array) => {
      dateItem = new Date(item.DAT_PESO_INI);
      const contador = array.length > index + 1 ? index + 1 : index;
      // esse if faz o controle das datas digitadas pelo usuário, para trazer somente
      // => os dados da api que faz referência a essas datas
      dateItem = dateItem.toLocaleString("default", optionDate);
      if (item.NUM_BIGBAG === 1) {
        quantidade.dia.bigbag += 1;
      }
      if (item.NUM_SACARIA === 1) {
        quantidade.dia.sacaria += 1;
      }
      if (item.NUM_GRANEL === 1) {
        quantidade.dia.granel += 1;
      }
      if (item.NUM_OUTRAS === 1) {
        quantidade.dia.outras += 1;
      }

      // o código entra nesse if em todos os casos, menos na ultima vez, quando o array
      // => já não existe mais e nesse caso entra no else para finalizar a aplicação

      dateArray = new Date(array[contador].DAT_PESO_INI).toLocaleString("default", optionDate);

      // o código entra nesse if quando a data do item muda e é necessário armazenar
      // => os dados nas variáveis de total (qtdSacas e tempoMedia) e também é armazenado a data
      // => no labels para preenchimento do gráfico.
      if (dateItem !== dateArray || array.length === index + 1) {
        labels.push(dateItem);
        total.bigbag.push(quantidade.dia.bigbag);
        total.sacaria.push(quantidade.dia.sacaria);
        total.granel.push(quantidade.dia.granel);
        total.outras.push(quantidade.dia.outras);
        quantidade.dia.bigbag = 0;
        quantidade.dia.sacaria = 0;
        quantidade.dia.granel = 0;
        quantidade.dia.outras = 0;
      }
      return null;
    });
    return { total, labels };
  }

  const pesagemEmbalagemDia = () => {
    const optionsDayDate = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const { total, labels } = calcDataPesagemEmbalagem(
      dateBaseIni,
      dateBaseFim,
      "date",
      optionsDayDate
    );

    // opcoes de estilo do gráfico
    const { bigbag, sacaria, granel, outras } = total;
    const datasets = datasetGraphicsEmbalagens(bigbag, sacaria, granel, outras);

    return { labels, datasets };
  };

  const pesagemEmbalagemMes = () => {
    const optionsMonthDate = {
      month: "2-digit",
      year: "numeric",
    };

    const { firstDay } = dateFirstLast(dateBaseIni, "month");
    const { lastDay } = dateFirstLast(dateBaseFim, "month");

    const { total, labels } = calcDataPesagemEmbalagem(firstDay, lastDay, "date", optionsMonthDate);

    const { bigbag, sacaria, granel, outras } = total;
    const datasets = datasetGraphicsEmbalagens(bigbag, sacaria, granel, outras);

    return { labels, datasets };
  };

  const pesagemEmbalagemAno = () => {
    const optionsYearDate = {
      year: "numeric",
    };

    const { firstDay } = dateFirstLast(dateBaseIni, "year");
    const { lastDay } = dateFirstLast(dateBaseFim, "year");

    const { total, labels } = calcDataPesagemEmbalagem(firstDay, lastDay, "date", optionsYearDate);

    const { bigbag, sacaria, granel, outras } = total;
    const datasets = datasetGraphicsEmbalagens(bigbag, sacaria, granel, outras);

    return { labels, datasets };
  };

  const datasetGraphicDia = pesagemEmbalagemDia(api, dataAnaliseInicio, dataAnaliseFinal);
  const datasetGraphicMes = pesagemEmbalagemMes(api, dataAnaliseInicio, dataAnaliseFinal);
  const datasetGraphicAno = pesagemEmbalagemAno(api, dataAnaliseInicio, dataAnaliseFinal);
  return { datasetGraphicDia, datasetGraphicMes, datasetGraphicAno };
}

function pesagemTipoCaminhao({ api, dataAnaliseInicio, dataAnaliseFinal }) {
  const { dateBaseIni, dateBaseFim } = convertDate(dataAnaliseInicio, dataAnaliseFinal);

  function calcDataPesagemTipoCaminhao(dateIni, dateFim, typeDate, optionDate) {
    const total = {
      cacamba: [],
      graneleiro: [],
      outros: [],
    };

    const labels = [];
    let dateItem;
    let dateArray;

    api.map((item, index, array) => {
      const contador = array.length > index + 1 ? index + 1 : index;
      dateItem = new Date(item.DAT_PESO_INI);

      // esse if faz o controle das datas digitadas pelo usuário, para trazer somente
      // => os dados da api que faz referência a essas datas
      if (
        moment(item.DAT_PESO_INI).isSameOrAfter(dateIni) &&
        moment(item.DAT_PESO_INI).isSameOrBefore(dateFim, typeDate)
      ) {
        dateItem = dateItem.toLocaleString("default", optionDate);
        if (item.NUM_CACAMBA_CAM === 1) {
          veiculo.dia.cacamba += item.NUM_SACAS;
        }
        if (item.NUM_GRANELEIRO_CAM === 1) {
          veiculo.dia.graneleiro += item.NUM_SACAS;
        }
        if (item.NUM_OUTROS_CAM === 1) {
          veiculo.dia.outros += item.NUM_SACAS;
        }

        // o código entra nesse if em todos os casos, menos na ultima vez, quando o array
        // => já não existe mais e nesse caso entra no else para finalizar a aplicação
        dateArray = new Date(array[contador].DAT_PESO_INI).toLocaleString("default", optionDate);

        // o código entra nesse if quando a data do item muda e é necessário armazenar
        // => os dados nas variáveis de total (qtdSacas e tempoMedia) e também é armazenado a data
        // => no labels para preenchimento do gráfico.
        if (dateItem !== dateArray || array.length === index + 1) {
          labels.push(dateItem);
          total.cacamba.push(veiculo.dia.cacamba);
          total.graneleiro.push(veiculo.dia.graneleiro);
          total.outros.push(veiculo.dia.outros);
          veiculo.dia.cacamba = 0;
          veiculo.dia.graneleiro = 0;
          veiculo.dia.outros = 0;
        }
      }
      return null;
    });
    return { total, labels };
  }

  const pesagemTipoCaminhaoDia = () => {
    const optionsDayDate = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const { total, labels } = calcDataPesagemTipoCaminhao(
      dateBaseIni,
      dateBaseFim,
      "date",
      optionsDayDate
    );

    // opcoes de estilo do gráfico
    const { cacamba, graneleiro, outros } = total;
    const datasets = datasetGraphicsTipoCaminhao(cacamba, graneleiro, outros);

    return { labels, datasets };
  };

  const pesagemTipoCaminhaoMes = () => {
    const optionsMonthDate = {
      month: "2-digit",
      year: "numeric",
    };

    const { firstDay } = dateFirstLast(dateBaseIni, "month");
    const { lastDay } = dateFirstLast(dateBaseFim, "month");

    const { total, labels } = calcDataPesagemTipoCaminhao(
      firstDay,
      lastDay,
      "date",
      optionsMonthDate
    );

    const { cacamba, graneleiro, outros } = total;
    const datasets = datasetGraphicsTipoCaminhao(cacamba, graneleiro, outros);

    return { labels, datasets };
  };

  const pesagemTipoCaminhaoAno = () => {
    const optionsYearDate = {
      year: "numeric",
    };

    const { firstDay } = dateFirstLast(dateBaseIni, "year");
    const { lastDay } = dateFirstLast(dateBaseFim, "year");

    const { total, labels } = calcDataPesagemTipoCaminhao(
      firstDay,
      lastDay,
      "date",
      optionsYearDate
    );

    const { cacamba, graneleiro, outros } = total;
    const datasets = datasetGraphicsTipoCaminhao(cacamba, graneleiro, outros);

    return { labels, datasets };
  };

  const datasetGraphicDia = pesagemTipoCaminhaoDia(api, dataAnaliseInicio, dataAnaliseFinal);
  const datasetGraphicMes = pesagemTipoCaminhaoMes(api, dataAnaliseInicio, dataAnaliseFinal);
  const datasetGraphicAno = pesagemTipoCaminhaoAno(api, dataAnaliseInicio, dataAnaliseFinal);
  return { datasetGraphicDia, datasetGraphicMes, datasetGraphicAno };
}

const qtdSacasControleCaminhao = ({
  dataAnaliseInicio,
  dataAnaliseFinal,
  dscControls,
  apiArray,
}) => {
  const { dateBaseIni, dateBaseFim } = convertDate(dataAnaliseInicio, dataAnaliseFinal);
  let totalCacamba = 0;
  let totalGraneleiro = 0;
  let totalOutros = 0;
  const totalGeral = {};

  dscControls.map((controls) => {
    totalGeral[controls] = [];

    apiArray[controls].map((item) => {
      if (
        moment(item.DAT_PESO_INI).isSameOrAfter(dateBaseIni) &&
        moment(item.DAT_PESO_INI).isSameOrBefore(dateBaseFim, "date")
      ) {
        if (item.NUM_CACAMBA_CAM === 1) {
          if (item.DSC_CONTROLE === controls) {
            totalCacamba += item.NUM_SACAS === 0 ? (item.NUM_SACAS = 1) : item.NUM_SACAS;
          }
        }
        if (item.NUM_GRANELEIRO_CAM === 1) {
          if (item.DSC_CONTROLE === controls) {
            totalGraneleiro += item.NUM_SACAS === 0 ? (item.NUM_SACAS = 1) : item.NUM_SACAS;
          }
        }
        if (item.NUM_OUTROS_CAM === 1) {
          if (item.DSC_CONTROLE === controls) {
            totalOutros += item.NUM_SACAS === 0 ? (item.NUM_SACAS = 1) : item.NUM_SACAS;
          }
        }
      }
      return null;
    });
    totalGeral[controls].push(totalCacamba, totalGraneleiro, totalOutros);
    totalCacamba = 0;
    totalGraneleiro = 0;
    totalOutros = 0;
    return null;
  });

  const labels = ["Caçamba", "Graneleiro", "Outros"];

  const datasets = graphics(totalGeral, dscControls);

  return { labels, datasets };
};

export const DataCargaDescarga = {
  tempoMedioSacas,
  tempoMedioTickets,
  qtdSacasControle,
  pesagemEmbalagem,
  pesagemTipoCaminhao,
  qtdSacasControleCaminhao,
  filterTableAnalise,
};
