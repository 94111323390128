import { Container } from "./style";

// Loading usado de forma geral no projeto: Armazém / Financeiro
// Usado antes de renderizar os Cards, Table e Modal (Dashboard / Armazém)
// Usado antes de renderizar os Cards, Slider e Modal (Dashboard / Financeiro)

export default function Loading() {
  return (
    <Container>
      <svg>
        <path d="m 12.5,20 15,0 0,0 -15,0 z" className="led one" />
        <path d="m 32.5,20 15,0 0,0 -15,0 z" className="led two" />
        <path d="m 52.5,20 15,0 0,0 -15,0 z" className="led three" />
        <path d="m 72.5,20 15,0 0,0 -15,0 z" className="led four" />
      </svg>
    </Container>
  );
}
