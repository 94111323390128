import ReactModal from "react-modal";

// Modal usado no Dashboard / Financeiro
// => É o modal usado para renderizar as tables do Contas a Pagar, Contas a Receber e Bancos

function responsiveModal(valueWidth, valueReturnTrue, valueReturnFalse) {
  if (window.innerWidth < valueWidth) {
    return valueReturnTrue;
  }
  return valueReturnFalse;
}

export default function ModalPortal({ statusModalActual, handleRequestCloseFunc, children }) {
  return (
    <>
      <ReactModal
        isOpen={statusModalActual}
        onRequestClose={handleRequestCloseFunc}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
          content: {
            position: "absolute",
            top: "10%",
            left: responsiveModal(650, "4%", "24%"),
            right: responsiveModal(650, "4%", "24%"),
            bottom: "14%",
            border: "none",
            background: "var(--blue-a1)",
            borderRadius: "8px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "0",
            maxWidth: "100%",
          },
        }}
        ariaHideApp={false}
      >
        {children}
      </ReactModal>
    </>
  );
}
