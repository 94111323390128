import { useState, useContext } from "react";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";
import { AvisosContext } from "../../../../contexts/Avisos/AvisosContext";
import { createUpdateDeleteAvisos } from "../../../../services/apiPortalProdutor";
import { sessionStorageDecrypt } from "../../../../utils/crypto";
import HeaderModalPortal from "../../../../components/Modal/ModalPortal/HeaderModalPortal";
import Warning from "../../../../components/Layouts/Warning/Warning";
import Loading from "../../../../components/Layouts/Loading/Loading";
import { Wrapper } from "./style";

const DeletarAviso = ({ handleRequestCloseFunc, operacao, id }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const cnpjArmazem = sessionStorageDecrypt("cnpjArmazem");
  const { dono, userAuth, password } = useContext(AuthContext);
  const { listAllAvisos } = useContext(AvisosContext);

  const HandleDeleteAviso = async () => {
    setMessage(false);
    setError(false);
    setLoading(true);
    try {
      await createUpdateDeleteAvisos(
        dono,
        userAuth,
        password,
        operacao,
        cnpjArmazem,
        // "04492438000195",
        id,
        // dateIniFormat,
        "00/00/0000 00:00:00",
        "00/00/0000 00:00:00",
        "DELETE",
        "S"
      );
      setMessage(true);
    } catch (error) {
      console.log(error);
      setError("Não foi possível deletar o aviso! Por favor tente mais tarde.");
    } finally {
      setLoading(false);
    }
  };

  function handleUpdateTable() {
    handleRequestCloseFunc();
    listAllAvisos();
  }

  return (
    <Wrapper>
      <HeaderModalPortal handleRequestCloseFunc={handleRequestCloseFunc} title="Deletar Aviso" />
      <div className="content">
        {error && <Warning label={error} />}
        {loading ? (
          <Loading />
        ) : (
          !error && (
            <>
              {!message ? (
                <>
                  <h1>Deseja Realmente excluir o aviso?</h1>
                  <div className="actions">
                    <button className="cancel" onClick={handleRequestCloseFunc}>
                      cancelar
                    </button>
                    <button className="auth" onClick={() => HandleDeleteAviso()}>
                      confirmar
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h1>Aviso deletado com sucesso!</h1>
                  <div className="actions">
                    <button className="success" onClick={handleUpdateTable}>
                      OK
                    </button>
                  </div>
                </>
              )}
            </>
          )
        )}
      </div>
    </Wrapper>
  );
};

export default DeletarAviso;
