import { Container } from './styles'

// Componente para exibir uma mensagem de alerta
// => Usada no Login para mostrar a msg de login / senha incorreta

const Warning = ({ label, className }) => {
  return (
    <Container className={className}>
      <span>{label}</span>
    </Container>
  )
}

export default Warning
