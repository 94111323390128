import { Container } from './style'

// Loading secundário usado no Análises / Armazém
// Usado inicialmente para a pessoa selecionar os gráficos e depois na troca das análises

export default function SecondLoading({ text }) {
  return (
    <Container>
      <div className="loading">
        <h2>{text}</h2>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </Container>
  )
}
