import { Container } from "./style";
import logoWeb from "../../assets/img/logotipoWeb.svg";
import logoMobile from "../../assets/img/logotipoMobile.svg";
import { yearCopyright } from "../../utils/dates";
import LoginForm from "./components/LoginForm";
import EmpresaForm from "./components/EmpresaForm";
import { useContext } from "react";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import FirebaseForm from "./components/FirebaseForm";

export default function Login() {
  const { isLogged, authFirebase } = useContext(AuthContext);
  const larguraTela = window.innerWidth;

  return (
    <>
      <Container>
        <div className="login_content">
          <div className="login_box_top"></div>
          <div className="login_box_bottom"></div>

          <div className="login_section-logo">
            <img
              className="login_logo"
              src={larguraTela < 400 ? logoMobile : logoWeb}
              alt="logo emsoft"
            />
          </div>

          <div className="login_section-form">
            <h1>Auto BI</h1>
            <p>Business Intelligence</p>
            {!authFirebase && <FirebaseForm />}
            {authFirebase && !isLogged && <LoginForm />}
            {isLogged && <EmpresaForm />}
          </div>
        </div>
        <footer>Emsoft Solutions © {yearCopyright}</footer>
      </Container>
    </>
  );
}
