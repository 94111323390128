import { limitCaracter } from '../../../../utils/style'

export const DuplicatasPagar = [
  {
    Header: 'Cliente',
    accessor: 'NOM_CADASTRO',
    Cell: ({ cell: { value } }) => <p style={limitCaracter()}>{value}</p>,
  },
  {
    Header: 'Tipo Cliente',
    accessor: 'FLG_CADASTRO',
    Cell: ({ cell: { value } }) => (
      <div style={{ textAlign: 'center' }}>{value}</div>
    ),
  },
  {
    Header: 'Tipo Doc.',
    accessor: 'COD_TIPODOC',
    Cell: ({ cell: { value } }) => (
      <div style={{ textAlign: 'center' }}>{value}</div>
    ),
  },
  {
    Header: 'Num. Documento',
    accessor: 'NUM_DOC',
    Cell: ({ cell: { value } }) => (
      <div style={{ textAlign: 'center' }}>{value}</div>
    ),
  },
  {
    Header: 'Parcela',
    accessor: 'NUM_SEQ',
    Cell: ({ cell: { value } }) => (
      <div style={{ textAlign: 'center' }}>{value}</div>
    ),
  },
  {
    Header: 'Vencimento',
    accessor: 'DAT_VENC',
    Cell: ({ cell: { value } }) => (
      <div style={{ textAlign: 'center' }}>
        {new Date(value).toLocaleDateString()}
      </div>
    ),
  },
  {
    Header: 'Valor',
    accessor: 'VAL_PRINCIPAL',
    Cell: ({ cell: { value } }) =>
      value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
  },
]

export const DuplicasReceber = [
  {
    Header: 'Cliente',
    accessor: 'NOM_CADASTRO',
  },
  {
    Header: 'Tipo Cliente',
    accessor: 'FLG_CADASTRO',
    Cell: ({ cell: { value } }) => (
      <div style={{ textAlign: 'center' }}>{value}</div>
    ),
  },
  {
    Header: 'Tipo Doc.',
    accessor: 'COD_TIPODOC',
    Cell: ({ cell: { value } }) => (
      <div style={{ textAlign: 'center' }}>{value}</div>
    ),
  },
  {
    Header: 'Num. Documento',
    accessor: 'NUM_DOC',
    Cell: ({ cell: { value } }) => (
      <div style={{ textAlign: 'center' }}>{value}</div>
    ),
  },
  {
    Header: 'Parcela',
    accessor: 'NUM_SEQ',
    Cell: ({ cell: { value } }) => (
      <div style={{ textAlign: 'center' }}>{value}</div>
    ),
  },
  {
    Header: 'Vencimento',
    accessor: 'DAT_VENC',
    Cell: ({ cell: { value } }) => (
      <div style={{ textAlign: 'center' }}>
        {new Date(value).toLocaleDateString()}
      </div>
    ),
  },
  {
    Header: 'Valor',
    accessor: 'VAL_PRINCIPAL',
    Cell: ({ cell: { value } }) =>
      value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
  },
]

export const Banco = [
  {
    Header: 'Cód. Conta',
    accessor: 'COD_BANCO',
  },
  {
    Header: 'Banco',
    accessor: 'NOM_BANCO',
  },

  {
    Header: 'Saldo',
    accessor: 'SALDO',
    Cell: ({ cell: { value } }) =>
      value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
  },
]
