import { useState } from "react";
import * as S from "./style";
import ModalPortal from "../../../components/Modal/ModalPortal/ModalPortal";
import FormRegisterAvisos from "../CadastroAvisos/FormRegisterAvisos/FormRegisterAvisos";
import DeletarAviso from "./DeletarAviso/DeletarAviso";

const AtualizarAvisos = ({ actions, children, ...props }) => {
  const [statusModal, setStatusModal] = useState(false);
  const [renderModal, setRenderModal] = useState("");

  // Função que altera o status do modal para True ou  False
  const handleShowModal = () => {
    setStatusModal(!statusModal);
  };

  // Função que fecha o modal ao apertar ESC ou clicando em sobreposição
  const handleRequestCloseFunc = () => {
    setStatusModal(false);
  };

  const handleAtualizaAvisos = async () => {
    const { action } = actions;
    setRenderModal(action);
    handleShowModal();
  };

  return (
    <>
      <ModalPortal
        statusModalActual={statusModal}
        handleRequestCloseFunc={handleRequestCloseFunc}
      >
        {renderModal === "edit" ? (
          <FormRegisterAvisos
            handleRequestCloseFunc={handleRequestCloseFunc}
            title="Atualizar Aviso"
            id={actions.data.N_ID}
            operacao="A"
          />
        ) : (
          <DeletarAviso
            handleRequestCloseFunc={handleRequestCloseFunc}
            id={actions.data.N_ID}
            operacao="D"
          />
        )}
      </ModalPortal>
      <S.Button onClick={handleAtualizaAvisos} {...props}>
        {children}
      </S.Button>
    </>
  );
};

export default AtualizarAvisos;
