import { NavLink } from "react-router-dom";

import dashboard from "../../../assets/Icons Sidebar/icon-dashboard.svg";
import analises from "../../../assets/Icons Sidebar/icon-analises.svg";
import classificacao from "../../../assets/Icons Sidebar/icon-classificacao.svg";
import relatorios from "../../../assets/Icons Sidebar/icon-relatorios.svg";
import tag from "../../../assets/Icons Sidebar/icon-tag.svg";
// import mapa from "../../../assets/Icons Sidebar/icon-map.svg";
import { useState } from "react";
import { sessionStorageDecrypt } from "../../../utils/crypto";

// Opções do Menu usados no Sidebar do Armazém

export default function MenuArmazem({ setShowMenu, selected }) {
  const [portalAntigo, setPortalAntigo] = useState(false);
  const infoCheckPhp = encodeURIComponent(sessionStorage.getItem("php"));
  const urlMapa = sessionStorageDecrypt("urlMapa");

  return (
    <>
      <div>
        <NavLink
          to="/armazem"
          end
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={dashboard} alt="" className="menu_img" />
          Dashboard
        </NavLink>
      </div>

      <div>
        <NavLink
          to="armazem/analises"
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={analises} alt="" className="menu_img" />
          Análises
        </NavLink>
      </div>

      <div>
        <NavLink
          to="armazem/relatorios"
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={relatorios} alt="" className="menu_img" />
          Relatórios
        </NavLink>
      </div>

      <div>
        <NavLink
          to="armazem/tags"
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={tag} alt="" className="menu_img" />
          Histórico de TAG
        </NavLink>
      </div>

      <button className="portalAntigo" onClick={() => setPortalAntigo(!portalAntigo)}>
        <img src={classificacao} alt="" className="menu_img" />
        Outros Recursos
      </button>

      {portalAntigo && (
        <div className="subMenu">
          <a
            href={`${urlMapa}/fis_jud.php?storagephp=${infoCheckPhp}`}
            className="menu_link_antigo"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              setPortalAntigo(!portalAntigo);
              setShowMenu(false);
            }}
          >
            {/* <img src={mapa} alt="" className="menu_img" /> */}
            Embarque
          </a>
          <a
            href={`${urlMapa}/fis_jud_said.php?storagephp=${infoCheckPhp}`}
            className="menu_link_antigo"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              setPortalAntigo(!portalAntigo);
              setShowMenu(false);
            }}
          >
            {/* <img src={mapa} alt="" className="menu_img" /> */}
            Desembarque
          </a>
          <a
            href={`${urlMapa}/classificacao.php?storagephp=${infoCheckPhp}`}
            className="menu_link_antigo"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              setPortalAntigo(!portalAntigo);
              setShowMenu(false);
            }}
          >
            {/* <img src={classificacao} alt="" className="menu_img" /> */}
            Classificação Café
          </a>
          <a
            href={`${urlMapa}/mapa.php?storagephp=${infoCheckPhp}`}
            className="menu_link_antigo"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              setPortalAntigo(!portalAntigo);
              setShowMenu(false);
            }}
          >
            {/* <img src={mapa} alt="" className="menu_img" /> */}
            Mapa do Armazém
          </a>
        </div>
      )}
    </>
  );
}
