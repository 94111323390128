import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 5px;
  border-radius: 4px;
  background: var(--blue-a18);
  color: var(--blue-a1);
  font-size: 0.8rem;
  font-weight: 500;

  .boxLote {
    text-align: center;
    text-justify: center;
    margin: 0px 0px 10px 0px;
  }

  .boxTree p {
    padding: 5px;
  }

  .boxType,
  .boxName,
  .boxAla {
    text-align: center;
    text-justify: center;
    margin-bottom: 7px;
    background: var(--blue-a14);
    border-radius: 4px;
  }

  .boxButton {
    display: flex;
    justify-content: center;
    height: 30px;

    button {
      border: none;
      border-radius: 4px;
      cursor: pointer;
      background-color: var(--blue-18);
      padding: 5px 8px;
      stroke: none;
    }
  }
`
