import ReactModal from "react-modal";
import { Container } from "./style";

export function responsiveModal(valueWidth, valueReturnTrue, valueReturnFalse) {
  if (window.innerWidth < valueWidth) {
    return valueReturnTrue;
  }
  return valueReturnFalse;
}

export default function ModalModulosMenu({
  statusModalActual,
  handleRequestCloseFunc,
}) {
  return (
    <>
      <ReactModal
        isOpen={statusModalActual}
        onRequestClose={handleRequestCloseFunc}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
          content: {
            position: "absolute",
            top: "38%",
            left: responsiveModal(650, "4%", "38%"),
            right: responsiveModal(650, "4%", "40%"),
            bottom: "38%",
            border: "none",
            background: "var(--blue-a1)",
            borderRadius: "8px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "0",
            maxWidth: "100%",
          },
        }}
        ariaHideApp={false}
      >
        <Container>
          <p>Modulos do menu atualizado com sucesso!</p>
          <button onClick={handleRequestCloseFunc}>Ok</button>
        </Container>
      </ReactModal>
    </>
  );
}
