import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table'

import { ProcessosFinalizados } from './data/Columns'

import { GlobalFilter } from './data/GlobalFilter'
import { useReactToPrint } from 'react-to-print'
import { Container } from './style'
import { stylePrint } from '../../../utils/style'

import Loading from '../../Layouts/Loading/Loading'

// Table usado no Analise / Armazém
// => Usado no Processos Finalizados

// Dependencias
// => data/Columns: Estrutura das colunas e cálculos da Table
// => data/GlobalFilter: Função de filtrar os dados da table através da pesquisa e ordenação das colunas

export const TableProcessosFinalizados = ({ dataTable }) => {
  const columns = useMemo(() => ProcessosFinalizados, [])

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
    if (dataTable) {
      setData(dataTable)
    }
  }, [dataTable])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const { globalFilter } = state
  const { pageIndex, pageSize } = state

  // print table
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'ARMAZÉM / ANÁLISE',
    pageStyle: stylePrint,
  })

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <Container>
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            handlePrint={handlePrint}
            data={data}
          />

          <table {...getTableProps()} ref={componentRef} className="pagePrint">
            <thead>
              {headerGroups.map((headerGroups, index) => (
                <tr {...headerGroups.getHeaderGroupProps()} key={index}>
                  {headerGroups.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' ↓'
                            : ' ↑'
                          : ' '}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td {...cell.getCellProps()} key={index}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className="information_page">
            <span className="number_page">
              Página{' '}
              <strong>
                {pageIndex + 1} de {pageOptions.length}
              </strong>{' '}
            </span>
            <span className="go_page">
              {' '}
              <input
                className="go_page_button"
                type="text"
                placeholder="Ir a página"
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0
                  gotoPage(pageNumber)
                }}
              />
            </span>
            <div className="pagination pagination_media">
              <div className="page_direction">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Anterior
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  Próximo
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {'>>'}
                </button>
              </div>
              <select
                className="display_pages"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 25, 50, 100, 500].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Container>
      )}
    </>
  )
}
