import React, { useState, useContext } from 'react'

import { Container } from './styles'
import { TableHistoricoTag } from '../../../components/Tables/TablesHistoricoTag/TableHistoricoTag'

import { AuthContext } from '../../../contexts/Auth/AuthContext'
import { getDataHistoricoTag } from '../../../services/api'
import Loading from '../../../components/Layouts/Loading/Loading'
import Warning from '../../../components/Layouts/Warning/Warning'

const HistoricoTag = () => {
  const [tag, setTag] = useState('')
  const [table, setTable] = useState([])
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(false)
  const [error, setError] = useState(false)
  const { dono, userAuth, password, codEmpresa, codFilial } =
    useContext(AuthContext)

  async function handleSubmit(e) {
    e.preventDefault()
    setLoading(true)
    setTable([])
    setError(false)
    setMessage(false)

    try {
      const data = await getDataHistoricoTag(
        dono,
        userAuth,
        password,
        codEmpresa,
        codFilial,
        tag,
      )

      if (data.length === 0) {
        setMessage(true)
      } else {
        setTable(data)
      }
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
      setTag('')
    }
  }
  return (
    <Container>
      <h1>Histórico de TAG</h1>
      <div className="searchTable">
        <form onSubmit={handleSubmit}>
          <label htmlFor="tag" name="tag">
            TAG
          </label>
          <input
            id="tag"
            placeholder="Digite o número da tag"
            name="tag"
            type="text"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
          />
          <button>Buscar</button>
        </form>
      </div>

      <div className="tableContainer">
        {message && <Warning label="TAG inexistente ou sem movimentações!" />}
        {error && <Warning label="Erro ao conectar com o servidor!" />}
        {loading && <Loading />}
        {table.length > 0 && <TableHistoricoTag dataTable={table} />}
      </div>
    </Container>
  )
}

export default HistoricoTag
