import styled from 'styled-components'

export const Container = styled.div`
  background: var(--blue-a3);
  display: grid;
  grid-area: main;
  margin: 0 auto;

  .main-title {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .main-title h1 {
    font-size: 7rem;
    text-transform: uppercase;
    font-weight: 800;
    color: #555;
  }

  .svg-img {
    display: block;
  }

  svg {
    display: block;
  }

  #clock {
    animation: clockHand 5s infinite linear;

    transform-box: fill-box;
    transform-origin: bottom;
  }

  #leftTree,
  #righTree {
    animation: tree 2s ease-in-out infinite alternate;
    transform-box: fill-box;
    transform-origin: bottom;
  }

  #man {
    animation: manBody 1s ease-in-out infinite alternate;
    transform-box: fill-box;
    transform-origin: bottom;
  }

  #pc-circle {
    fill: #6ace66;
    stroke-width: 4;
    animation: change-light 4s linear infinite alternate;
  }

  @keyframes clockHand {
    from {
      transform: rotateZ(0deg);
    }
    from {
      transform: rotateZ(-360deg);
    }
  }

  @keyframes manBody {
    from {
      transform: rotateX(0deg);
    }
    to {
      transform: rotateX(10deg);
    }
  }

  @keyframes tree {
    from {
      transform: rotateZ(10deg);
    }
    to {
      transform: rotateZ(-20deg);
    }
  }

  @keyframes change-light {
    0% {
      stroke: #cd61f8;
    }
    25% {
      stroke: #6ace66;
    }
    75% {
      stroke: #2995c0;
    }
    100% {
      stroke: #e92949;
    }
  }

  /* Media Queries */

  @media (min-width: 640px) {
    .main-title h1 {
      font-size: 5rem;
      text-transform: uppercase;
      font-weight: 700;
      color: #555;
    }
  }

  @media (min-width: 768px) {
    .main-title h1 {
      font-size: 6rem;
      text-transform: uppercase;
      font-weight: 800;
      color: #555;
    }
  }

  @media (min-width: 1024px) {
    .main-title h1 {
      font-size: 7rem;
      text-transform: uppercase;
      font-weight: 900;
      color: #555;
    }
  }

  @media (min-width: 1200px) {
    .main-title h1 {
      font-size: 8rem;
      text-transform: uppercase;
      font-weight: 900;
      color: #555;
    }

    .main-title {
      text-align: center;
      margin-top: 4rem;
      margin-bottom: 0;
    }
  }
`
