import { useContext, useState } from "react";
import { AuthContext } from "../Auth/AuthContext";
import { sessionStorageDecrypt } from "../../utils/crypto";
import { getAvisos } from "../../services/apiPortalProdutor";
import { AvisosContext } from "./AvisosContext";

export const AvisosProvider = ({ children }) => {
  const { dono, userAuth, password } = useContext(AuthContext);
  const cnpjArmazem = sessionStorageDecrypt("cnpjArmazem");
  const [avisos, setAvisos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  async function listAllAvisos() {
    try {
      setLoading(true);
      setError(false);
      const res = await getAvisos(dono, userAuth, password, cnpjArmazem);
      setAvisos(res);
    } catch (error) {
      console.log(error);
      setError(
        "Não foi possível carregar o quadro de avisos. Por favor tente mais tarde!"
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <AvisosContext.Provider
      value={{
        listAllAvisos,
        avisos,
        loading,
        error,
      }}
    >
      {children}
    </AvisosContext.Provider>
  );
};
