import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0.3rem;
  background-color: ${(props) => props.color};

  h2 {
    color: var(--blue-a1);
    margin: 0 auto;
  }

  button {
    background-color: transparent;
    outline: none;
    border: none;
    appearance: none;
    margin: 0 1rem;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  /* gap: 1rem; */

  .cards {
    grid-column: 1/2;
    margin-left: 1rem;
  }

  .titleChartModal {
    padding: 10px 0;

    span {
      font-size: 1.4rem;
      color: var(--gray-c4);
    }
  }
  .charts {
    grid-column: 2/5;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .chart {
    width: 400px;
    height: 400px;
  }

  .footer {
    width: 170px;
    overflow: hidden;
    grid-column: 4/5;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all ease 0.3s;
    position: relative;

    ::before {
      content: "➡️";
    }

    ::after {
      content: "";
      background-color: var(--gray-c8);
      width: 100%;
      height: 4px;
      position: absolute;
      margin-top: 30px;
      margin-left: 3px;
    }

    :hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 1100px) {
    .chart {
      width: 300px;
      height: 300px;
    }
  }

  @media (max-width: 900px) {
    .card {
      width: 150px;
      height: 70px;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 800px) {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 2fr;

    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: 0.5rem;
    }
    .card {
      width: 150px;
      height: 80px;
    }

    .charts {
      grid-column: 1/-1;
    }
    .chart {
      width: 200px;
      height: 200px;
    }

    .footer {
      grid-column: 1/-1;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin: 0 auto;
    }
  }

  @media (max-width: 650px) {
    .cards {
      padding-top: 1rem;
      margin-left: 0rem;
    }
    .card {
      width: 250px;
      height: 65px;
    }
  }

  @media (max-width: 500px) {
    .card {
      width: 130px;
      height: 65px;
    }
    .titleChartModal span {
      font-size: 1rem;
    }
  }
`;
