import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { columnsAberto } from "./data/ColumnsAberto";
import { columnsPagas } from "./data/ColumnsPagas";
import { columnsRecebidas } from "./data/ColumnsRecebidas";
import { GlobalFilter } from "./data/GlobalFilter";
import { useReactToPrint } from "react-to-print";
import { Container } from "./style";
import { stylePrint } from "../../../utils/style";

// ANCHOR Tabela página principal

// Table usado no Financeiro / Relatórios

// Dependencias
// => data/Columns: Estrutura das colunas e cálculos da Table
// => data/GlobalFilter: Função de filtrar os dados da table através da pesquisa e ordenação das colunas

export const TablesRelatorioFin = ({ dataTable, infoRelatorio }) => {
  const columns = useMemo(() => {
    if (infoRelatorio === "Contas Recebidas") {
      return columnsRecebidas;
    } else if (infoRelatorio === "Contas Pagas") {
      return columnsPagas;
    } else {
      return columnsAberto;
    }
  }, [infoRelatorio]);
  const [data, setData] = useState([]);

  // variável que recebe todos os dados da tabela
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    globalFilteredRows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, sortBy: [{ id: "DAT_VENC", desc: false }] },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter } = state;
  const { pageIndex, pageSize } = state;
  const dataFilter = globalFilteredRows.map((item) => item.original);

  // print table

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "ARMAZÉM / DASHBOARD",
    pageStyle: stylePrint,
  });

  function returnSoma(dataTable) {
    if (
      infoRelatorio === "Contas Recebidas" ||
      infoRelatorio === "Contas Pagas"
    ) {
      if (dataTable.length > 0) {
        const soma = dataTable.reduce((acc, item) => {
          return acc + item.VAL_PAGO;
        }, 0);
        return soma.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      }
    } else {
      if (dataTable.length > 0) {
        const soma = dataTable.reduce((acc, item) => {
          return acc + item.VAL_PRINCIPAL;
        }, 0);
        return soma.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      }
    }
  }

  useEffect(() => {
    if (infoRelatorio === "Contas Recebidas") {
      const newDataTable = dataTable.map((item) => {
        item.TIPO_CLIENTE = `${item.FLG_CADASTRO} ${(
          "000000" + item.COD_CADASTRO
        ).slice(-6)}`;
        item.VAL_ABERTO =
          item.VAL_PRINCIPAL - item.VAL_DESCONTO + item.VAL_JURO;
        return item;
      });
      setData(() => newDataTable);
    } else if (infoRelatorio === "Contas Pagas") {
      const newDataTable = dataTable.map((item) => {
        item.TIPO_CLIENTE = `${item.FLG_CADASTRO} ${(
          "000000" + item.COD_CADASTRO
        ).slice(-6)}`;
        item.VALOR_PAGO = item.VAL_PAGO + item.VAL_JUROS;
        item.VAL_ABERTO =
          item.VAL_PRINCIPAL - item.VAL_PAGO - item.VAL_DESC_BAIXA;
        return item;
      });
      setData(() => newDataTable);
    } else {
      const newDataTable = dataTable.map((item) => {
        item.TIPO_CLIENTE = `${item.FLG_CADASTRO} ${(
          "000000" + item.COD_CADASTRO
        ).slice(-6)}`;
        return item;
      });
      setData(() => newDataTable);
    }
  }, [dataTable, infoRelatorio]);

  const totalSoma = returnSoma(dataFilter);

  return (
    <>
      <Container>
        <GlobalFilter
          filter={globalFilter}
          setFilter={setGlobalFilter}
          handlePrint={handlePrint}
          data={dataFilter}
          totalSoma={totalSoma}
        />
        <table {...getTableProps()} ref={componentRef} className="pagePrint">
          <thead>
            {headerGroups.map((headerGroups, index) => (
              <tr {...headerGroups.getHeaderGroupProps()} key={index}>
                {headerGroups.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " ↓"
                          : " ↑"
                        : " "}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td {...cell.getCellProps()} key={index}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="information_page">
          <span className="number_page">
            Página{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{" "}
          </span>
          <span className="go_page">
            {" "}
            <input
              className="go_page_button"
              type="text"
              placeholder="Ir a página"
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
          </span>
          <div className="pagination pagination_media">
            <div className="page_direction">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Anterior
              </button>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                Próximo
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
            <select
              className="display_pages"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 25, 50, 100, 500].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Mostrar {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </Container>
    </>
  );
};
