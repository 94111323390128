import styled from 'styled-components'

export const Header = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 0.6rem 1.4rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.4);

  button {
    justify-self: end;
    transition: all 0.3s;
    border: none;
    &:hover {
      opacity: 0.6;
    }
  }
  .buttonsTree {
    justify-self: center;
  }

  .buttonsTree button {
    padding: 4px 8px;
    color: white;
    opacity: 0.5;
    background: #000;
    box-shadow:
      -5px -5px 10px rgba(255, 255, 255, 0.1),
      5px 5px 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
  }

  .buttonsTree #checked {
    opacity: 1;
    padding: 6px 8px;
    box-shadow:
      -2px -2px 5px rgba(255, 255, 255, 0.2),
      2px 2px 5px rgba(0, 0, 0, 1);
  }
`

export const Tree = styled.div`
  width: 100%;
  height: calc(100% - 3.27rem);
  max-height: auto;
`
