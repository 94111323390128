import React from "react";
import CadastroAvisos from "../../../../../Modules/AppProdutor/CadastroAvisos/CadastroAvisos";
import { Container } from "./style";
import search from "../../../../../assets/Icons Box/icon-pesquisa.svg";
import { ReactComponent as IconAdd } from "../../../../../assets/Icons Analise/icon-mais.svg";

// ANCHOR Filtro da Tabela

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <Container>
      <span className="movimentacao">Quadro de Avisos</span>

      <span className="search">
        <CadastroAvisos>
          <span>Adicionar</span>
          <IconAdd />
        </CadastroAvisos>
        <input
          value={filter || ""}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Pesquisar"
        />
        <span className="icon_search">
          <img src={search} alt="" />
        </span>
      </span>
    </Container>
  );
};
