import { useState, useContext, useRef } from "react";
import * as S from "./style";
import { sessionStorageDecrypt } from "../../../../../../utils/crypto";
import { createUpdateDeleteVincular } from "../../../../../../services/apiPortalProdutor";
import { AuthContext } from "../../../../../../contexts/Auth/AuthContext";
import Loading from "../../../../../../components/Layouts/Loading/Loading";

const SelectProdutores = ({
  dataProdutor,
  setDataProdutor,
  userId,
  handleRequestCloseFunc,
}) => {
  const { dono, userAuth, password } = useContext(AuthContext);


  const cnpjArmazem = sessionStorageDecrypt("cnpjArmazem");

  const [formValues, setFormValues] = useState({ fazenda: {} });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);

  const checkboxRefs = useRef([]);
  const checkboxSelectAll = useRef(null);
  checkboxRefs.current = [];

  const vincularProutores = async (codProd, codFaz) => {
    try {
      await createUpdateDeleteVincular(
        dono,
        userAuth,
        password,
        "I",
        cnpjArmazem,
        // "04492438000195",
        "0",
        "0",
        userId.id,
        "C",
        codProd,
        codFaz,
        "S"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;

    const data = formValues[name] || {};

    data[value] = checked;

    setFormValues({ ...formValues, [name]: data });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(false);
  
    const chavesComTrue = [];
  
    for (const chave in formValues?.fazenda) {
      if (formValues?.fazenda[chave] === true) {
        chavesComTrue.push(chave);
      }
    }
  
    if (chavesComTrue.length > 0) {
      const arrayDeArrays = chavesComTrue.map((str) => {
        const numeros = str.split(",").map((item) => {
          const partes = item.split(":");
          return partes[1];
        });
        return numeros;
      });
  
      for (const item of arrayDeArrays) {
        try {
          await vincularProutores(item[0], item[1]);
        } catch (error) {
          console.error(`Erro ao vincular produtor ${item[0]} e fazenda ${item[1]}`, error);
          setMessage(`Erro ao vincular produtor código:${item[0]} e fazenda código: ${item[1]}`);
          break; // Opcional: parar a execução se ocorrer um erro
        }
      }
  
      setDataProdutor([]);
      handleRequestCloseFunc(false);
    } else {
      setMessage("Selecione pelo menos uma fazenda");
    }
    setLoading(false);
  };

  function addCheckboxRef(el) {
    if (el && !checkboxRefs.current.includes(el)) {
      checkboxRefs.current.push(el);
    }
    // console.log(checkboxRefs.current);
  }

  function handleSelectAll() {
    console.log(checkboxSelectAll.current.checked);
    if (!checkboxSelectAll.current.checked) {
      checkboxRefs.current.map((checkbox) => {
        formValues.fazenda[checkbox.value] = false;
        return (checkbox.checked = false);
      });
    } else {
      checkboxRefs.current.map((checkbox) => {
        formValues.fazenda[checkbox.value] = true;
        return (checkbox.checked = true);
      });
    }
  }

  return (
    <S.Container>
      {loading ? (
        <Loading />
      ) : (
        <>
          <span className="textFazenda">
            Selecione o cadastro do Produtor e a Fazenda:
          </span>
          {message && <span className="messageFazenda">{message}</span>}
          <div
            style={{
              width: "100%",
            }}
          >
            <label
              id="selectAll"
              className="fazendaLabel"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".5rem",
              }}
            >
              <input
                style={{ width: "14px", height: "14px" }}
                ref={checkboxSelectAll}
                type="checkbox"
                name="selectAll"
                onChange={handleSelectAll}
              />
              selecionar todas
            </label>
          </div>

          <S.Form onSubmit={handleSubmit}>
            <div className="content">
              {dataProdutor &&
                dataProdutor.map((produtor, index) => {
                  return (
                    <div key={index} className="contentInfo">
                      <p className="nomFazenda">
                        {produtor.COD_CLIENTE}- {produtor.NOM_CLIENTE}
                      </p>

                      {produtor.fazendas.map((faz, index) => {
                        return (
                          <div className="fazendaInfo" key={index}>
                            <label id={faz.COD_FAZE} className="fazendaLabel">
                              <input
                                style={{ width: "14px", height: "14px" }}
                                ref={addCheckboxRef}
                                type="checkbox"
                                name="fazenda"
                                value={`cod_produtor:${produtor.COD_CLIENTE},cod_faze:${faz.COD_FAZE}`}
                                onChange={handleInputChange}
                              />
                              {faz.COD_FAZE}- {faz.NOM_FAZE}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
            <div className="actions">
              <S.Button onClick={() => setDataProdutor([])} $cancel>
                Cancelar
              </S.Button>
              <S.Button type="submit">Vincular</S.Button>
            </div>
          </S.Form>
        </>
      )}
    </S.Container>
  );
};

export default SelectProdutores;
