import {
  getDataAnalisePesagem,
  getDataResIS,
  getDataResISPainel,
  getDataTree,
  getDataAnaliseProcessosFin,
} from "../services/api";

import { dayTodayFormated } from "../utils/dates";
import moment from "moment/moment";

export const useFetchAnalise = (
  dono,
  userAuth,
  password,
  codEmpresa,
  codFilial,
  dataIni,
  dataFin = dayTodayFormated
) => {
  const firstDay =
    dataIni === undefined
      ? moment(dataIni).startOf("year").format("DD/MM/YYYY")
      : moment(dataIni, "DD/MM/YYYY").startOf("year").format("DD/MM/YYYY");

  const getDataApiAnalisePesagem = async () => {
    const api = await getDataAnalisePesagem(
      dono,
      userAuth,
      password,
      codEmpresa,
      codFilial,
      "DMAH",
      firstDay
    );
    return api;
  };

  const getDataApiAnaliseProcessosFin = async (tipoMovimento) => {
    const api = await getDataAnaliseProcessosFin(
      dono,
      userAuth,
      password,
      codEmpresa,
      codFilial,
      dataIni,
      tipoMovimento,
      dataFin
    );

    return api;
  };

  const getDataAnaliseIS = async (is) => {
    const api = await getDataResISPainel(
      dono,
      userAuth,
      password,
      codEmpresa,
      codFilial,
      is
    );

    return api;
  };

  const getDataIS = async (is) => {
    const api = await getDataResIS(
      dono,
      userAuth,
      password,
      codEmpresa,
      codFilial
    );
    return api;
  };

  const getDataLoteTree = async (lote) => {
    const api = await getDataTree(
      dono,
      userAuth,
      password,
      codEmpresa,
      codFilial,
      lote
    );

    return api;
  };

  return {
    getDataApiAnalisePesagem,
    getDataApiAnaliseProcessosFin,
    getDataAnaliseIS,
    getDataIS,
    getDataLoteTree,
  };
};
