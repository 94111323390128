import styled from "styled-components";

export const stylePrint = `
  @media print {
    .pagePrint {
      border: 1px solid #000;
      width: 100%;
      }
    .pagePrint th {
      padding: 10px 0;
    }
    .pagePrint td {
      border-top: 0.5px solid #000;
      padding: 10px 0;
      text-align: center
    }
  }`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;
`;

export function limitCaracter() {
  return {
    maxWidth: "40ch",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  };
}
