import styled from 'styled-components'

export const Container = styled.div`
  --carga: ${(props) => 220 - (220 * props.percentArray[0]) / 100};
  --descarga: ${(props) => 220 - (220 * props.percentArray[1]) / 100};
  --terceiro: ${(props) => 220 - (220 * props.percentArray[2]) / 100};

  .CardAnalise {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 2px solid #fff;
    width: 220px;
    height: 120px;
    border-radius: 4px;
    padding: 5px;
    align-items: center;
    margin-right: 5px;
    margin-top: 5px;
  }

  .skill {
    width: 75px;
    height: 75px;
    position: relative;
    margin-bottom: 15px;
    margin-left: 2px;
    display: flex;
    font-weight: 600;
  }
  .outer {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    padding: 20px;
    /* box-shadow: 0px 0px 0px 0.5px #333 inset; */
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    /* box-shadow: 0px 0px 0px 0.5px #333 inset; */
  }

  .dadosCard {
    color: #fff;
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  #number {
    font-size: 0.75rem;
    font-weight: 600px;
    color: #fff;
  }

  .cardCarga circle {
    fill: none;
    stroke: url(#GradientColor);
    stroke-width: 10px;
    stroke-dasharray: 220;
    stroke-dashoffset: 0;
    animation: animCarga 2s linear forwards;
  }

  .cardCarga {
    position: absolute;
    top: 0;
    left: 0;
  }

  @keyframes animCarga {
    100% {
      stroke-dashoffset: var(--carga);
    }
  }

  .cardDescarga circle {
    fill: none;
    stroke: url(#GradientColor);
    stroke-width: 10px;
    stroke-dasharray: 220;
    stroke-dashoffset: 0;
    animation: animDescarga 2s linear forwards;
  }

  .cardDescarga {
    position: absolute;
    top: 0;
    left: 0;
  }

  @keyframes animDescarga {
    100% {
      stroke-dashoffset: var(--descarga);
    }
  }

  .cardTerceiro circle {
    fill: none;
    stroke: url(#GradientColor);
    stroke-width: 10px;
    stroke-dasharray: 220;
    stroke-dashoffset: 0;
    animation: animTerceiro 2s linear forwards;
  }

  .cardTerceiro {
    position: absolute;
    top: 0;
    left: 0;
  }

  @keyframes animTerceiro {
    100% {
      stroke-dashoffset: var(--terceiro);
    }
  }
`
