import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  background-color: var(--blue-a3);
  padding: 1rem;
  display: flex;
  margin: 1rem 0;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 1050px) {
    flex-direction: column;
  }
`;

export const DisplayCadastrar = styled.div`
  display: flex;
  width: 350px;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;

  h1 {
    font-size: 1.5rem;
  }

  form {
    max-width: 24rem;
    min-width: 16rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input {
      padding: 0.5rem;
      border-radius: 0.25rem;
      font-size: 16px;
      background-color: var(--blue-a2);
      border: 1px solid var(--blue-a18);
      outline: none;

      :focus {
        border: 2px solid var(--blue-emsoft);
      }
    }
  }

  .showAction {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;

    h3 {
      margin-bottom: 0.68rem;
    }
  }

  @media (max-width: 500px) {
    width: 320px;
  }
`;

export const Buttons = styled.button`
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin-right: ${(props) => (props.$cancel ? "1rem" : "0")};
  font-size: 16px;
  color: ${(props) => (props.$cancel ? "red" : "#F1F3F4")};
  background-color: ${(props) => (props.$cancel ? "#F1F3F4" : "#030F14")};
  font-weight: bold;
  border: 1px solid ${(props) => (props.$cancel ? "red" : "#030F14")};
  outline: none;
  appearance: none;
  transition: all ease 0.3s;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  :hover {
    background-color: ${(props) => (props.$cancel ? "#DBDBDB" : "#195067")};
  }
`;
