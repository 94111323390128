import React, { useState, useEffect } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle'
import { Container } from './styles'

// DataRanger(Calendário) usado no módulo Armazém
// => Usado no Dashboard para renderizar os Cards, Table e Graphic(Modal)
// => Usado no Análises para renderizar os graphics, Cards e Table

export default function CalendarPicker({ handleDatePicker, type }) {
  const [value, onChange] = useState(null, null)

  // chamada da função para recuperar os dados de handle e type
  useEffect(() => {
    return handleDatePicker(value, type)
  }, [handleDatePicker, value, type])

  return (
    <>
      <Container>
        <DateRangePicker
          onChange={onChange}
          value={value}
          required={true}
          format="dd/MM/yyyy"
          onInvalidChange={() => alert('Invalid date')}
          openCalendarOnFocus={false}
          dayPlaceholder="__"
          monthPlaceholder="__"
          yearPlaceholder="____"
        />
      </Container>
    </>
  )
}
