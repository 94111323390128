import React, { useState, useContext, useEffect } from 'react'
import { onAuthStateChanged } from 'firebase/auth'

import { AuthContext } from '../../../contexts/Auth/AuthContext'
import { FormLogin } from './style'

import Button from '../../../components/Layouts/Button/Button'
import Loading from '../../../components/Layouts/Loading/Loading'
import UseAuthentication from '../../../hooks/useAuthentication'
import { cnpjClear, cnpjMask } from '../../../utils/regex'

const FirebaseForm = () => {
  const { Login, loading, setLoading } = UseAuthentication()
  const { setAuthFirebase, firebaseStore } = useContext(AuthContext)

  const [formValuesLogin, setformValuesLogin] = useState({})
  const [messageError, setMessageError] = useState(false)

  // useEffect(() => {
  //   onAuthStateChanged(auth, (firebaseUser) => {
  //     return firebaseUser
  //   })
  // }, [auth, setAuthFirebase])

  // ANCHOR função que controla o estado dos inputs de login
  const handleInputChangeLogin = (e) => {
    setMessageError(false)
    const { name, value } = e.target
    setformValuesLogin({ ...formValuesLogin, [name]: value })
  }

  // ANCHOR função que que recebe os dados do formulário que estiver sendo enviado no momento
  const handleSubmit = async (e) => {
    e.preventDefault()
    setMessageError(false)

    const formData = new FormData(e.target)
    const { user, password } = Object.fromEntries(formData)

    if (user && password) {
      const cnpj = cnpjClear(user)
      const res = await Login({
        login: `${cnpj}@emsoft.com.br`,
        senha: password,
      })

      if (res) {
        setLoading(true)
        const resFirebase = await firebaseStore(cnpj)
        if (resFirebase) {
          setLoading(false)
          setAuthFirebase(true)
        } else {
          setLoading(false)
          setMessageError(true)
        }
      } else {
        setLoading(false)
        setMessageError(true)
      }
      setformValuesLogin('')
    }
  }

  return (
    <FormLogin onSubmit={handleSubmit}>
      <div className="login_label-float">
        <input
          type="text"
          id="user"
          name="user"
          required
          autoFocus
          value={cnpjMask(formValuesLogin.user || '')}
          onChange={handleInputChangeLogin}
        />
        <label htmlFor="user">CNPJ</label>
      </div>
      <div className="login_label-float">
        <input
          type="password"
          id="password"
          name="password"
          required
          value={formValuesLogin.password || ''}
          onChange={handleInputChangeLogin}
        />
        <label htmlFor="password">Senha</label>
      </div>
      {messageError && <span>Usuário ou senha incorretos</span>}
      <div className="login_justify-center">
        {loading ? (
          <Loading />
        ) : (
          <Button id="button" type="submit">
            Entrar
          </Button>
        )}
      </div>
    </FormLogin>
  )
}

export default FirebaseForm
