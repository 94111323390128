import React from "react";
import * as S from "./style";
import ListarAvisos from "../ListarAvisos/ListarAvisos";

const QuadroAvisos = () => {
  return (
    <S.Container>
      <ListarAvisos />
    </S.Container>
  );
};

export default QuadroAvisos;
