import styled from "styled-components";

export const ContainerSelect = styled.div`
  // control => estiliza a caixa principal
  .react-select__control {
    background-color: var(--blue-a1);
    min-width: 450px;
    min-height: 38px;
    font-size: 16px;
  }

  .react-select__clear-indicator {
    display: none;
  }

  // menu => estiliza a caixa das opções
  .react-select__menu {
    min-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  // Estilização do Selecione
  .react-select__placeholder {
    color: var(--blue-a18);
    font-weight: bold;
  }
  // Estilização do texto quando selecionado
  .react-select__singlevalue {
    color: var(--blue-a18);
  }

  @media (max-width: 500px) {
    .react-select__control {
      min-width: 300px;
      font-size: 14px;
    }
    .react-select__menu {
      min-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
