import Rotas from "./Routes";

import { BrowserRouter as Router } from "react-router-dom";

import { AuthProvider } from "./contexts/Auth/AuthProvider";
import { AmbienteProvider } from "./contexts/Home/AmbienteProvider";
import { AvisosProvider } from "./contexts/Avisos/AvisosProvider";
import { VinculaProvider } from "./contexts/VincularProdutores/VinculaProvider";

import { GlobalStyle } from "./styles/global";
import { AnaliseProvider } from "./contexts/Analise/AnaliseProvider";

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <AmbienteProvider>
            <VinculaProvider>
              <AvisosProvider>
                <AnaliseProvider>
                  <GlobalStyle />
                  <div className="container">
                    <Rotas />
                  </div>
                </AnaliseProvider>
              </AvisosProvider>
            </VinculaProvider>
          </AmbienteProvider>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
