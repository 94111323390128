import { useEffect } from 'react'
import { TableListarProdutores } from '../../../components/Tables/TablesAppProdutor/TableListarProdutores/TableListarProdutores'
import Loading from '../../../components/Layouts/Loading/Loading'
import Warning from '../../../components/Layouts/Warning/Warning'
import { useAtualizarProdutores } from '../../../hooks/useAtualizarProdutores'
import * as S from './style'

const ListarProdutores = () => {
  const { data, error, loading, FirebaseStore } = useAtualizarProdutores()

  useEffect(() => {
    FirebaseStore()
  }, [])

  return (
    <S.Container>
      {data && <TableListarProdutores dataTable={data} />}
      {loading && <Loading />}
      {!data && error && <Warning label="Erro inesperado!" />}
    </S.Container>
  )
}

export default ListarProdutores
