import { Container } from "./styles";

import { NavLink, useNavigate } from "react-router-dom";

import armazem from "../../assets/Icons Home/armazem.svg";
import financeiro from "../../assets/Icons Home/financeiro.svg";
import appProdutor from "../../assets/Icons Home/appProdutor.svg";
import fiscal from "../../assets/Icons Home/fiscal.svg";
// import insumos from "../../assets/Icons Home/estoque.svg";
import graos from "../../assets/Icons Home/graos.svg";
import fabrica from "../../assets/Icons Home/fabrica.svg";
import contabil from "../../assets/Icons Home/contabil.svg";
import contato from "../../assets/Icons Home/contato.svg";
import { useContext } from "react";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import Button from "../../components/Layouts/Button/Button";

export default function Home() {
  const { setIsLogged, signin, isAutomatus, keyAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  async function returnPage() {
    const user = sessionStorage.getItem("user");
    const password = sessionStorage.getItem("pass");
    await signin(user, password);
    navigate("/login");
    setIsLogged(true);
  }

  return (
    <Container>
      <h1>Escolha seu ambiente</h1>
      <Button
        onClick={returnPage}
        className="buttonReturn"
        title="Voltar para seleção de empresa"
      >
        {"<"}
      </Button>
      <div className="cards_home">
        {isAutomatus === "S" && (
          <NavLink to="/armazem" className="menu_link">
            <div className="card_home">
              <div className="icon_container">
                <img src={armazem} alt="" className="menu_img" />
              </div>
            </div>
          </NavLink>
        )}

        {keyAuth >= 8 && (
          <>
            <NavLink to="/financeiro" className="menu_link">
              <div className="card_home">
                <div className="icon_container">
                  <img src={financeiro} alt="" className="menu_img" />
                </div>
              </div>
            </NavLink>

            <NavLink to="/app-produtor" className="menu_link">
              <div className="card_home">
                <div className="icon_container">
                  <img src={appProdutor} alt="" className="menu_img" />
                </div>
              </div>
            </NavLink>
          </>
        )}

        {/* <NavLink to="/fiscal" className="menu_link">
          <div className="card_home">
            <div className="icon_container">
              <img src={fiscal} alt="" className="menu_img" />
            </div>
          </div>
        </NavLink>

        <NavLink to="/insumos" className="menu_link">
          <div className="card_home">
            <div className="icon_container">
              <img src={insumos} alt="" className="menu_img" />
            </div>
          </div>
        </NavLink>

        <NavLink to="/graos" className="menu_link">
          <div className="card_home">
            <div className="icon_container">
              <img src={graos} alt="" className="menu_img" />
            </div>
          </div>
        </NavLink>

        <NavLink to="/fabrica" className="menu_link">
          <div className="card_home">
            <div className="icon_container">
              <img src={fabrica} alt="" className="menu_img" />
            </div>
          </div>
        </NavLink>

        <NavLink to="/contabil" className="menu_link">
          <div className="card_home">
            <div className="icon_container">
              <img src={contabil} alt="" className="menu_img" />
            </div>
          </div>
        </NavLink> */}

        <NavLink to="/contato" className="menu_link">
          <div className="card_home">
            <div className="icon_container">
              <img src={contato} alt="" className="menu_img" />
            </div>
            <span className="suporte">Fale Conosco</span>
          </div>
        </NavLink>
      </div>
    </Container>
  );
}
