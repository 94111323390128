import React from 'react'
import { TableProcessosFinalizados } from '../../../components/Tables/ProcessosFinalizados/TableProcessosFinalizados'
import { TableCargaDescarga } from '../../../components/Tables/TablesCargaDescarga/TableCargaDescarga'

export const tables = (datasetTable, analise) => {
  if (analise === 'Carga e Descarga') {
    return <TableCargaDescarga dataTable={datasetTable} />
  } else if (analise === 'Processos Finalizados') {
    return <TableProcessosFinalizados dataTable={datasetTable} />
  }
}
