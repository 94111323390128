import iconClose from "../../../assets/Icons-Modal/icon-closed-a18.svg";
import * as S from "./style";

const HeaderModalPortal = ({ handleRequestCloseFunc, title, showIcon = true }) => {
  return (
    <S.Header>
      <p className="headerModal">{title}</p>
      {showIcon && <img src={iconClose} alt="" onClick={handleRequestCloseFunc} />}
    </S.Header>
  );
};

export default HeaderModalPortal;
