import React from "react";
import SelectFilter from "../../../../components/Layouts/SelectFilter/SelectFilter";
import CalendarPicker from "../../../../components/DataRangePicker/Date";

const RtEstoqueAnalitico = ({ produtores, handleSelectProdutor, handleDatePicker }) => {
  return (
    <>
      <SelectFilter options={produtores} onChange={handleSelectProdutor} />
      <CalendarPicker handleDatePicker={handleDatePicker} type={"dataAnalise"} />
    </>
  );
};

export default RtEstoqueAnalitico;
