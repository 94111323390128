import React from 'react'
import { Container } from './style'
import search from '../../../../../../assets/Icons Box/icon-pesquisa.svg'
import { CSVLink } from 'react-csv'
import iconCSV from '../../../../../../assets/Icons Analise/icon-csv.svg'
import iconPrint from '../../../../../../assets/Icons Analise/icon-print.svg'

// ANCHOR Filtro da Tabela

export const GlobalFilter = ({ filter, setFilter, handlePrint, data }) => {
  return (
    <Container>
      <span className="movimentacao">Movimentações</span>
      <div className="buttonsPrintCSV">
        <CSVLink data={data}>
          <button className="button" title="Exportar CSV">
            <img src={iconCSV} alt="" />
          </button>
        </CSVLink>
        <button className="button" onClick={handlePrint} title="Imprimir">
          <img src={iconPrint} alt="" />
        </button>
        <span className="search">
          <input
            value={filter || ''}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Pesquisar"
          />
          <span className="icon_search">
            <img src={search} alt="" />
          </span>
        </span>
      </div>
    </Container>
  )
}
