import styled from 'styled-components'

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0.3rem;
  background-color: ${(props) => props.color};
  margin-bottom: 20px;

  h2 {
    color: var(--blue-a1);
    margin: 0 auto;
  }

  button {
    background-color: transparent;
    outline: none;
    border: none;
    appearance: none;
    margin: 0 1rem;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .tableScreen {
    overflow-y: auto;
    max-height: 75vh;
    width: 90%;
  }
`
