import React, { useState, useContext } from 'react'

import { AuthContext } from '../../../contexts/Auth/AuthContext'
import { useFetchAnalise } from '../../../hooks/useFetchAnalise'
import TableModal from '../../Modal/ModalArm/TableModal/TableModal'
import { Button } from './style'

const ButtonTable = ({ children }) => {
  const { dono, userAuth, password, codEmpresa, codFilial } =
    useContext(AuthContext)
  const { getDataAnaliseIS } = useFetchAnalise(
    dono,
    userAuth,
    password,
    codEmpresa,
    codFilial,
  )
  const [renderModal, setRenderModal] = useState(false)
  const [apiModalIS, setApiModalIS] = useState()
  const [loading, setLoading] = useState(true)

  async function fetchApi() {
    const resApiIS = await getDataAnaliseIS(`${children}`)
    if (resApiIS) {
      setApiModalIS(resApiIS)
    }
  }

  function render() {
    setLoading(true)
    fetchApi()
    return setRenderModal(true)
  }

  return (
    <>
      <Button onClick={() => render()}>{children}</Button>
      {renderModal && (
        <TableModal
          statusModalActual={renderModal}
          onRequestClose={() => setRenderModal(false)}
          infoModal={apiModalIS}
          loading={loading}
          setLoading={setLoading}
          setApiModalIS={setApiModalIS}
        />
      )}
    </>
  )
}

export default ButtonTable
