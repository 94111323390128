import React from "react";
import { Container } from "./style";
import search from "../../../../../assets/Icons Box/icon-pesquisa.svg";

// ANCHOR Filtro da Tabela

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <Container>
      <span className="movimentacao">Usuários Ativos</span>

      <span className="search">
        <input
          value={filter || ""}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Pesquisar"
        />
        <span className="icon_search">
          <img src={search} alt="" />
        </span>
      </span>
    </Container>
  );
};
