export function DataTableModalGraphic(infoModal) {
  const labels = []

  // FUNÇÃO PARA GERAR UMA COR ALEATÓRIO NO RGB
  function getRandomInt(max) {
    return Math.floor(Math.random() * max)
  }

  const colors = []
  const data = []
  const graphicTotal = {}

  if (infoModal) {
    graphicTotal.autorizado = infoModal[0].N_SACAS_DESPEJAR
    graphicTotal.realizado = infoModal[0].N_SACAS_DESPEJADO
    graphicTotal.resultado = infoModal[0].N_SACAS_RESULTADO
    infoModal.map((item) => {
      labels.push(item.NOM_PENEIRA)
      colors.push(
        `rgb(${getRandomInt(254)},${getRandomInt(254)},${getRandomInt(254)})`,
      )
      data.push(item.PERC_PENEIRA)
      return null
    })
  }

  const datasets = [
    {
      type: 'bar',
      backgroundColor: colors,
      data,
    },
  ]

  return { labels, datasets, graphicTotal }
}
