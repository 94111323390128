import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 10px 20px;
  font-size: 0.9rem;
  background-color: var(--blue-a18);
  border-radius: 4px;
  max-height: 95%;

  .lista {
    width: 420px;
  }

  .lista li {
    display: flex;
    color: var(--blue-a1);
    padding: 15px 0px;
    justify-content: space-between;
    list-style: none;
    flex-direction: row;
    border-bottom: 1px solid var(--blue-a12);
  }

  .lista li:nth-child(4),
  .lista li:nth-child(6) {
    color: var(--blue-a8);
  }

  .lista li:last-child {
    border-bottom: none;
  }

  .lista_bold {
    font-weight: 600;
  }

  @media only screen and (max-width: 500px) {
    .lista {
      width: 300px;
    }
  }

  @media only screen and (max-width: 350px) {
    .lista {
      width: 250px;
    }
  }
`;
