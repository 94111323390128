import { useState, useContext } from "react";
import * as S from "./style";
import { cpfCnpjMask, cnpjClear } from "../../../../../utils/regex";
import { getProdutores } from "../../../../../services/apiPortalProdutor";
import { AuthContext } from "../../../../../contexts/Auth/AuthContext";
import { VinculaContext } from "../../../../../contexts/VincularProdutores/VinculaContext";
import Warning from "../../../../../components/Layouts/Warning/Warning";
import Loading from "../../../../../components/Layouts/Loading/Loading";
import ModalPortal from "../../../../../components/Modal/ModalPortal/ModalPortal";
import HeaderModalPortal from "../../../../../components/Modal/ModalPortal/HeaderModalPortal";
import SelectProdutores from "./SelectProdutores/SelectProdutores";

const FormVinculaProdutores = () => {
  const { dono, userAuth, password, codEmpresa } = useContext(AuthContext);
  const { statusVinculaModal, setStatusVinculaModal, userId, getData } = useContext(VinculaContext);
  const [valueCpf, setValueCpf] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dataProdutor, setDataProdutor] = useState([]);

  const handleInput = (e) => {
    const { value } = e.target;
    setValueCpf(cpfCnpjMask(value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setValueCpf("");
    if (valueCpf) {
      try {
        const res = await getProdutores(dono, userAuth, password, codEmpresa, cnpjClear(valueCpf));
        if (res.length <= 0) throw new Error(`Produtor não encontrado! `);

        // Objeto para armazenar o resultado final
        const resultado = [];

        // Objeto temporário para mapear COD_CLIENTE para seus objetos
        const tempObj = {};

        // Iterar pelos objetos e agrupá-los por COD_CLIENTE
        res.forEach((objeto) => {
          const codCliente = objeto.COD_CLIENTE;

          if (!tempObj[codCliente]) {
            // Se o COD_CLIENTE ainda não estiver mapeado, crie um novo objeto para ele
            tempObj[codCliente] = {
              COD_CLIENTE: codCliente,
              NOM_CLIENTE: objeto.NOM_CLIENTE,
              fazendas: [],
            };
          }

          // Adicione as informações da fazenda a fazendas[]
          tempObj[codCliente].fazendas.push({
            COD_FAZE: objeto.COD_FAZE,
            NOM_FAZE: objeto.NOM_FAZE,
            NUM_PR_FAZ: objeto.NUM_PR_FAZ,
          });
        });

        // Convertemos o objeto temporário em um array de objetos
        for (const codCliente in tempObj) {
          resultado.push(tempObj[codCliente]);
        }

        setDataProdutor(resultado);
      } catch ({ name, message, code }) {
        if (code) {
          setError("Erro ao conectar com o servidor");
        } else {
          setError(message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  // Função que fecha o modal ao apertar ESC ou clicando em sobreposição
  const handleRequestCloseFunc = () => {
    setStatusVinculaModal(false);
    setDataProdutor([]);
    getData()
  };
  return (
    <ModalPortal
      statusModalActual={statusVinculaModal}
      handleRequestCloseFunc={handleRequestCloseFunc}
    >
      <S.Wrapper>
        <HeaderModalPortal
          handleRequestCloseFunc={handleRequestCloseFunc}
          title="Vincular Produtores"
        />
        <div className="form">
          {dataProdutor.length === 0 && !loading && (
            <>
              <h1>Vincular Produtores ao usuário: </h1>
              <h2>{userId.name}</h2>
              <form onSubmit={handleSubmit}>
                <label htmlFor="cpf_cnpj">CPF ou CNPJ</label>
                <input
                  autoFocus
                  type="text"
                  id="cpf_cnpj"
                  placeholder="CPF/CNPJ do produtor"
                  value={valueCpf}
                  onChange={handleInput}
                />
                <div style={{ alignSelf: "center", marginTop: "1rem" }}>
                  <S.Buttons $cancel onClick={handleRequestCloseFunc}>
                    Cancelar
                  </S.Buttons>
                  <S.Buttons type="submit" disabled={valueCpf.length <= 0}>
                    Buscar
                  </S.Buttons>
                </div>
              </form>
            </>
          )}
          {dataProdutor.length > 0 && !loading && !error && (
            <SelectProdutores
              dataProdutor={dataProdutor}
              setDataProdutor={setDataProdutor}
              userId={userId}
              handleRequestCloseFunc={handleRequestCloseFunc}
            />
          )}
          {loading && <Loading />}
          {error && <Warning label={error} />}
        </div>
      </S.Wrapper>
    </ModalPortal>
  );
};

export default FormVinculaProdutores;
