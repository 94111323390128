import styled from "styled-components";

export const Button = styled.button`
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--blue-a18);
  color: white;
`;
